import * as Yup from "yup";

import values from "./schemaValues";

const { title } = values;

const categoryTitleSchema = Yup.object({
  title,
});

export default categoryTitleSchema;
