import React, { useState, useEffect } from 'react'
import './createPost.scss'
import { Radio, ModalWindow } from '../../components'
import cancelIcon from '../../assets/img/close-modal.svg'
import folderIcon from '../../assets/img/folder.svg'
import confirmIcon from '../../assets/img/confirm-icon.svg'
import deleteIcon from '../../assets/img/delete-icon.svg'
import editIcon from '../../assets/img/edit-icon.svg'
import routes from '../../routes'
import { useHistory } from 'react-router'
import postOperations from '../../redux/post/postOperations'
import { connect } from 'react-redux'
import CreatePost from './CreatePost'
import axios from 'axios'
import { api_url } from '../../config'
import ClipLoader from 'react-spinners/ClipLoader'
import createCategorySchema from '../../validationSchemas/createCategorySchema'
import { setToken } from '../../getToken'
function ChooseCategory({ addCategory }) {
  const history = useHistory()

  const [categories, setCategories] = useState(null)
  const [communityCategories, setCommunityCategories] = useState([])
  const [selected, setSelected] = useState('')
  const [othersInput, setOthersInput] = useState('')
  const [showHint, setShowHint] = useState(false)
  const [hintData, setHintData] = useState([])
  const [inputDisabled, setInputDisabled] = useState(false)
  const [categoryIsChosen, setCategoryIsChosen] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [focusedHint, setFocusedHint] = useState(-1)
  const [categoryError, setCategoryError] = useState(null)
  const [resultsCount, setResultsCount] = useState(0)

  // const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    if (othersInput) {
      createCategorySchema
        .validate({ othersInput })
        .then((valid) => setCategoryError(null))
        .catch((err) => setCategoryError(err.message))
    }
  }, [othersInput])

  useEffect(() => {
    const chooseCatEffect = async () => {
      const token = await setToken()
      if (token) {
        // get admin categories
        axios
          .get(`${api_url}/categories/admin`, {
            headers: { Authorization: token },
          })
          .then((response) => {
            if (response.data.categories.length > 0) {
              setSelected(response.data.categories[0])
              setCategories(response.data.categories)
              setCategoryIsChosen(true)
            } else {
              setSelected({ name: 'Others' })
              setCategoryIsChosen(false)
            }
            setLoading(false)
          })
          .catch((err) => console.log(err))

        // get community categories
        axios
          .get(`${api_url}/categories/user`, {
            headers: { Authorization: token },
          })
          .then((response) => {
            response.data &&
              setCommunityCategories(
                response.data.categories.data.sort(
                  (a, b) => b.albums_count - a.albums_count,
                ),
              )
          })
          .catch((err) => console.log(err))
      }
    }
    chooseCatEffect()
  }, [])

  useEffect(() => {
    !othersInput && setShowHint(false)
  }, [othersInput])

  useEffect(() => {
    return () => {
      setShowHint(false)
      setOthersInput('')
      setInputDisabled(false)
      setModalIsOpen(false)
    }
  }, [selected])

  const handleInputChange = (e) => {
    setOthersInput(e.target.value)
    const searchResults = communityCategories.filter((category) => {
      return category.name.toLowerCase().includes(e.target.value.toLowerCase())
    })
    setHintData(searchResults)
    searchResults.length < 3
      ? setResultsCount(searchResults.length)
      : setResultsCount(3)

    if (searchResults.length >= 1) {
      setShowHint(true)
    } else {
      setShowHint(false)
    }
  }

  const handleSelect = (name) => {
    setOthersInput(name)
    setShowHint(false)
  }

  const handleRadioSelect = (e) => {
    setSelected({ name: e })
    e !== 'Others' ? setCategoryIsChosen(true) : setCategoryIsChosen(false)
  }

  const handleConfirm = () => {
    if (!categoryError) {
      setInputDisabled(true)
      setCategoryIsChosen(true)
      const existingCategory = communityCategories.filter((category) =>
        category.name.includes(othersInput),
      )
      !existingCategory?.length &&
        setCommunityCategories((prevState) => [
          ...prevState,
          { name: othersInput, albums_count: 0 },
        ])
    }
  }

  const handleGoNext = () => {
    if (categoryIsChosen) {
      if (selected.name === 'Others') {
        const chosenCategory = categories
          .concat(communityCategories)
          .filter((category) => category.name === othersInput)
        addCategory(chosenCategory[0])
      } else {
        const chosenCategory = categories.filter(
          (category) => category.name === selected.name,
        )
        addCategory(chosenCategory[0])
      }
      history.push(routes.chooseFile)
    } else {
      setModalIsOpen(true)
    }
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleEdit = () => {
    setInputDisabled(false)
    setCategoryIsChosen(false)
  }

  const clearChanges = () => {
    setCategoryIsChosen(false)
    setInputDisabled(false)
    setOthersInput('')
  }

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' || e.key === 'Enter') {
      !showHint && othersInput && handleConfirm()
    }
    if (
      (e.code === 'Enter' || e.key === 'Enter') &&
      focusedHint > -1 &&
      hintData.length > 0
    ) {
      setShowHint(false)
      setOthersInput(hintData[focusedHint].name)
    }

    if (e.keyCode === 38) {
      focusedHint > -1 && setFocusedHint((prevState) => (prevState -= 1))
    } else if (e.keyCode === 40) {
      focusedHint < resultsCount - 1 &&
        setFocusedHint((prevState) => (prevState += 1))
    }
  }

  return (
    <CreatePost onNextButton={handleGoNext}>
      <h2 className="header--two createPost__header">Category</h2>
      <p className="text text--normal createPost__text">
        Select a category your post relates to from the list below.
      </p>
      <div className="createPost__list">
        {categories && categories.length > 0 ? (
          categories.map((category) => {
            return (
              <Radio
                key={category.id}
                value={category.name}
                text={category.name}
                onChange={handleRadioSelect}
                selected={selected.name}
              />
            )
          })
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '25px',
            }}
          >
            <ClipLoader color={'#050138'} loading={loading} size={25} />
          </div>
        )}
        <Radio
          text="Others"
          value="Others"
          onChange={handleRadioSelect}
          selected={selected.name}
        />
      </div>
      {selected.name === 'Others' && (
        <form onSubmit={(e) => e.preventDefault()} className="createPost__form">
          <div className="form-group">
            <div style={{ position: 'relative' }}>
              <input
                autoComplete="off"
                disabled={inputDisabled}
                type="text"
                className={`createPost__input ${
                  categoryError && !showHint && 'inputError'
                }`}
                value={othersInput}
                onChange={handleInputChange}
                onBlur={handleInputChange}
                placeholder="Enter category name here"
                onKeyDown={handleKeyDown}
                name="othersInput"
              />
              {othersInput && !inputDisabled && (
                <button
                  className="createPost__input-cancel"
                  type="button"
                  onClick={() => {
                    setOthersInput('')
                  }}
                >
                  <img src={cancelIcon} alt="cancel" />
                </button>
              )}
              {othersInput && !showHint && !inputDisabled && (
                <button
                  className="createPost__input-confirm"
                  type="button"
                  onClick={handleConfirm}
                >
                  <img src={confirmIcon} alt="confirm" />
                </button>
              )}
            </div>
            {categoryError && !showHint && (
              <p className="chooseCategory__error formNotification">
                {categoryError}
              </p>
            )}
          </div>

          <div className={`createPost__hint hint ${showHint && 'show'}`}>
            <h4 className="text text--bold hint__header">Categories</h4>
            {hintData.slice(0, 3).map((category, i) => {
              return (
                <div
                  className={`hint__category ${
                    i === focusedHint && 'hint__category--focused'
                  }`}
                  key={category.name}
                  onClick={() => {
                    handleSelect(category.name)
                  }}
                >
                  <div className="hint__category-img">
                    <img src={folderIcon} alt="folder" />
                  </div>
                  <div className="hint__category-info text text--normal">
                    <span
                      className="hint__category-name"
                      dangerouslySetInnerHTML={{
                        __html: category.name
                          .toLowerCase()
                          .replace(
                            othersInput.toLowerCase(),
                            `<span class="text--bold">${othersInput}</span>`,
                          ),
                      }}
                    ></span>
                    <span className="hint__category-posts">
                      {category.albums_count} posts
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
          {inputDisabled && (
            <div className="createPost__editButtons">
              <img
                src={editIcon}
                alt="edit"
                className="createPost__editButtons-edit"
                onClick={handleEdit}
              />
              <img
                src={deleteIcon}
                alt="delete"
                className="createPost__editButtons-delete"
                onClick={clearChanges}
              />
            </div>
          )}
        </form>
      )}

      <ModalWindow
        modalWidth={375}
        modalText={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={handleCloseModal}
        header="Warning"
        text="Please, choose a category first"
      />
    </CreatePost>
  )
}

export default connect(null, {
  addCategory: postOperations.addCategory,
})(ChooseCategory)
