import React, { useEffect, useRef, useState } from 'react'
import { store, view } from '@risingstack/react-easy-state'
import KinesisVideo from 'aws-sdk/clients/kinesisvideo'
import KinesisVideoSignalingChannels from 'aws-sdk/clients/kinesisvideosignalingchannels'
import { SignalingClient } from 'amazon-kinesis-video-streams-webrtc'
import fullScreen from '../../assets/img/fullScreen.png'
import muteSound from '../../assets/img/mute-sound.png'
import unmuteSound from '../../assets/img/unmute-sound.png'
import { browserName, isMobile } from 'react-device-detect'

import ClipLoader from 'react-spinners/ClipLoader'

// Used to determine / validate options in form components:
const OPTIONS = {
  TRAVERSAL: {
    STUN_TURN: 'stunTurn',
    TURN_ONLY: 'turnOnly',
    DISABLED: 'disabled',
  },
  ROLE: {
    MASTER: 'MASTER',
    VIEWER: 'VIEWER',
  },
  RESOLUTION: {
    MOBILE: 'mobilescreen',
    WIDESCREEN: 'widescreen',
    FULLSCREEN: 'fullscreen',
  },
}

// Stores state across components (react-easy-state is super easy to use!)
const state = store({
  // These are config params set by the user:
  accessKey: 'AKIAV4M3Y5ZMYEM74M7Q',
  secretAccessKey: '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',
  sessionToken: '',
  region: 'us-east-1',
  role: OPTIONS.ROLE.VIEWER,
  channelName: '',
  clientId: getRandomClientId(),
  endpoint: null,
  sendVideo: true,
  sendAudio: true,
  openDataChannel: true,
  resolution: OPTIONS.RESOLUTION.WIDESCREEN,
  natTraversal: OPTIONS.TRAVERSAL.STUN_TURN,
  useTrickleICE: true,
  messageToSend: '',
  playerIsStarted: false,

  // These are set when user starts video; a few of them are only used when you start the stream as MASTER:
  signalingClient: null,
  localStream: null,
  localView: null,
  remoteView: null,
  dataChannel: null,
  peerConnectionStatsInterval: null,
  peerConnectionByClientId: {},
  dataChannelByClientId: [],
  receivedMessages: '',
})

//------------------------------------------------------------------------------
const Viewer = view(
  ({
    channelARNViewer,
    fullScreenToggle,
    setFullScreenToggle,
    setFinishModalIsOpen,
    dataChannelByClientId,
    idViewer,
  }) => {
    // In order to modify properties of our <video> components, we need a reference
    // to them in the DOM; first, we declare set them up with the useRef hook.
    // Later, when we render the <VideoPlayers/> component, we include this reference
    // in the component definition. Finally, we can reference the object properties
    // by state.localView.current.<PROPERTY>:
    state.localView = useRef(null)
    state.remoteView = useRef(null)

    useEffect(() => {
      if (channelARNViewer) {
        startPlayer(channelARNViewer, setFinishModalIsOpen, idViewer)
      }
    }, [channelARNViewer])

    return (
      <div>
        {state.playerIsStarted ? (
          <VideoPlayers
            fullScreenToggle={fullScreenToggle}
            setFullScreenToggle={setFullScreenToggle}
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: '50vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '50px',
            }}
          >
            <ClipLoader color={'#050138'} loading={true} size={70} />
          </div>
        )}
      </div>
    )
  },
)

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
const VideoPlayers = view(({ fullScreenToggle, setFullScreenToggle }) => {
  const [isMuted, setIsMuted] = useState(true)
  const handleClick = (e) => {
    const element = document.getElementById('videoMaster')
    e.preventDefault()
    e.stopPropagation()
    setFullScreenToggle(!fullScreenToggle)
    fullScreenToggle
      ? (element.style.width = '1296px')
      : (element.style.width = '541px')
  }
  const handleSound = () => {
    setIsMuted(!isMuted)
    state.remoteView.current.muted = !isMuted
  }
  return (
    <div style={{ position: 'relative', width: 'max-content' }}>
      <video
        ref={state.remoteView}
        className="videoMaster"
        id="videoMaster"
        autoPlay
        playsInline
        muted
      />
      {window.innerWidth < 1280 ? (
        ''
      ) : (
        <div>
          <img
            src={fullScreen}
            alt="full-screen"
            onClick={handleClick}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '25px',
              right: '25px',
            }}
          />
        </div>
      )}
      {/* <button
        onClick={(e) => {
          e.preventDefault()
          console.log('state', state)
          console.log('peerConnect', state.peerConnection)
          state.peerConnection.close()
        }}
      >
        Disconnect
      </button> */}
      <img
        src={isMuted ? muteSound : unmuteSound}
        alt="sound"
        onClick={handleSound}
        style={
          window.innerWidth < 1280
            ? {
                cursor: 'pointer',
                position: 'absolute',
                top: '25px',
                right: '20px',
              }
            : {
                cursor: 'pointer',
                position: 'absolute',
                top: '25px',
                right: '90px',
              }
        }
      />
    </div>
  )
})

//------------------------------------------------------------------------------
function startPlayer(channelARNViewer, setFinishModalIsOpen, idViewer) {
  state.playerIsStarted = false
  startPlayerForViewer(channelARNViewer, setFinishModalIsOpen, idViewer)
}

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
async function startPlayerForViewer(
  channelARNViewer,
  setFinishModalIsOpen,
  idViewer,
) {
  console.log('idViewer', idViewer)
  // Create KVS client
  const kinesisVideoClient = new KinesisVideo({
    region: state.region,
    endpoint: state.endpoint || null,
    correctClockSkew: true,
    accessKeyId: state.accessKey,
    secretAccessKey: state.secretAccessKey,
    sessionToken: state.sessionToken || null,
  })

  // Get signaling channel ARN
  const describeSignalingChannelResponse = await kinesisVideoClient
    .describeSignalingChannel({
      ChannelName: (state.channelName = channelARNViewer),
    })
    .promise()

  const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN

  // Get signaling channel endpoints:
  const getSignalingChannelEndpointResponse = await kinesisVideoClient
    .getSignalingChannelEndpoint({
      ChannelARN: channelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ['WSS', 'HTTPS'],
        Role: state.role, //roleOption.MASTER
      },
    })
    .promise()

  const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
    (endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint
      return endpoints
    },
    {},
  )

  // Get ICE server configuration
  const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels(
    {
      region: state.region,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
      accessKeyId: state.accessKey,
      secretAccessKey: state.secretAccessKey,
      sessionToken: state.sessionToken || null,
    },
  )

  const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    .getIceServerConfig({
      ChannelARN: channelARN,
    })
    .promise()

  const iceServers = []
  if (state.natTraversal === OPTIONS.TRAVERSAL.STUN_TURN) {
    iceServers.push({
      urls: `stun:stun.kinesisvideo.${state.region}.amazonaws.com:443`,
    })
  }

  if (state.natTraversal !== OPTIONS.TRAVERSAL.DISABLED) {
    getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      }),
    )
  }

  // Create Signaling Client
  state.signalingClient = new SignalingClient({
    channelARN,
    channelEndpoint: endpointsByProtocol.WSS,
    role: state.role, //roleOption.MASTER
    region: state.region,
    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    clientId: state.clientId,
    credentials: {
      accessKeyId: state.accessKey,
      secretAccessKey: state.secretAccessKey,
      sessionToken: state.sessionToken || null,
    },
  })

  const configuration = {
    iceServers,
    iceTransportPolicy:
      state.natTraversal === OPTIONS.TRAVERSAL.TURN_ONLY ? 'relay' : 'all',
  }

  const resolution =
    state.resolution === OPTIONS.TRAVERSAL.WIDESCREEN
      ? { width: { ideal: 1280 }, height: { ideal: 720 } }
      : state.resolution === OPTIONS.TRAVERSAL.FULLSCREEN
      ? { width: { ideal: 640 }, height: { ideal: 480 } }
      : { width: { ideal: 480 }, height: { ideal: 270 } }

  const constraints = {
    video: state.sendVideo ? resolution : false,
    audio: state.sendAudio,
  }
  // if (state.sendVideo || state.sendAudio) {
  //   try {
  //     state.localStream = await navigator.mediaDevices.getUserMedia(constraints)

  //     state.localView.current.srcObject = state.localStream
  //   } catch (e) {
  //     console.error('[MASTER] Could not find webcam')
  //   }
  // }
  state.peerConnection = new RTCPeerConnection(configuration)
  if (state.openDataChannel) {
    state.dataChannel = state.peerConnection.createDataChannel('kvsDataChannel')
    state.peerConnection.ondatachannel = (event) => {
      event.channel.onmessage = (message) => {
        const timestamp = new Date().toISOString()
        const loggedMessage = `${timestamp} - from MASTER: ${message.data}\n`
        state.receivedMessages += loggedMessage
      }
    }
  }

  // Poll for connection stats
  state.peerConnectionStatsInterval = setInterval(() => {
    state.peerConnection.getStats()
  }, 1000)

  /// REVIEW BELOW HERE
  state.signalingClient.config.clientId = idViewer
  state.signalingClient.on('open', async () => {
    console.log('[VIEWER] Connected to signaling service')
    // const isSafari = window.navigator.userAgent.indexOf('Safari') === 102
    console.log('browserName', browserName)
    if (browserName === 'Safari' || isMobile && browserName !== 'Chrome') {
      // try {
      state.localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { frameRate: { ideal: 0, max: 5 } },
      })
      state.localStream
        .getTracks()
        .forEach((track) =>
          state.peerConnection.addTrack(track, state.localStream),
        )
      state.srcObject = state.localStream
      //   } catch (e) {
      //     console.error('[VIEWER] Could not find webcam')
      //     return
      //   }
    }
    // Create an SDP offer to send to the master
    console.log('[VIEWER] Creating SDP offer')
    await state.peerConnection.setLocalDescription(
      await state.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      }),
    )

    // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
    if (state.useTrickleICE) {
      console.log('[VIEWER] Sending SDP offer', state.signalingClient)
      state.signalingClient.sendSdpOffer(state.peerConnection.localDescription)
    }
    console.log('[VIEWER] Generating ICE candidates')
  })

  state.signalingClient.on('sdpAnswer', async (answer) => {
    // Add the SDP answer to the peer connection
    console.log('[VIEWER] Received SDP answer', answer)
    await state.peerConnection.setRemoteDescription(answer)
  })

  state.signalingClient.on('iceCandidate', (candidate) => {
    // Add the ICE candidate received from the MASTER to the peer connection
    console.log('[VIEWER] Received ICE candidate')
    state.peerConnection.addIceCandidate(candidate)
  })

  state.signalingClient.on('close', () => {
    console.log('[VIEWER] Disconnected from signaling channel')
  })

  state.signalingClient.on('error', (error) => {
    console.error('[VIEWER] Signaling client error: ', error)
  })
  // Send any ICE candidates to the other peer
  state.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
    if (candidate) {
      console.log('[VIEWER] Generated ICE candidate')
      // When trickle ICE is enabled, send the ICE candidates as they are generated.
      if (state.useTrickleICE) {
        console.log('[VIEWER] Sending ICE candidate')
        state.signalingClient.sendIceCandidate(candidate)
      }
    } else {
      console.log('[VIEWER] All ICE candidates have been generated')
      // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
      if (!state.useTrickleICE) {
        console.log('[VIEWER] Sending SDP offer')
        state.signalingClient.sendSdpOffer(
          state.peerConnection.localDescription,
        )
      }
    }
  })

  state.peerConnection.addEventListener('connectionstatechange', (event) => {
    if (event.target.connectionState === 'disconnected') {
      setFinishModalIsOpen(true)
    }
  })
  if (state.peerConnection === null) {
    state.peerConnection.close()
    state.peerConnection = null
  }

  // As remote tracks are received, add them to the remote view
  state.peerConnection.addEventListener('track', (event) => {
    console.log('[VIEWER] Received remote track')

    state.playerIsStarted = true
    if (state.remoteView.current.srcObject) {
      return
    }
    state.remoteStream = event.streams[0]
    state.remoteView.current.srcObject = state.remoteStream
  })
  console.log('[VIEWER] Starting viewer connection')
  state.signalingClient.open()
}

//------------------------------------------------------------------------------
function stopPlayer() {
  state.playerIsStarted = false
  stopPlayerForViewer()
}

//------------------------------------------------------------------------------
function stopPlayerForViewer() {
  console.log('[VIEWER] Stopping viewer connection')
  if (state.signalingClient) {
    state.signalingClient.close()
    state.signalingClient = null
  }

  if (state.peerConnection) {
    state.peerConnection.close()
    state.peerConnection = null
  }

  if (state.localStream) {
    state.localStream.getTracks().forEach((track) => track.stop())
    state.localStream = null
  }

  if (state.remoteStream) {
    state.remoteStream.getTracks().forEach((track) => track.stop())
    state.remoteStream = null
  }

  if (state.peerConnectionStatsInterval) {
    clearInterval(state.peerConnectionStatsInterval)
    state.peerConnectionStatsInterval = null
  }

  if (state.localView) {
    state.localView.current.srcObject = null
  }

  if (state.remoteView) {
    state.remoteView.current.srcObject = null
  }

  if (state.dataChannel) {
    state.dataChannel = null
  }
}

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
function getRandomClientId() {
  return Math.random().toString(36).substring(2).toUpperCase()
}

export default Viewer
