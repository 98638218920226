import * as Yup from "yup";

import values from "./schemaValues";

const { commentText } = values;

const createCommentSchema = Yup.object({
  commentText,
});

export default createCommentSchema;
