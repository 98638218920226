import React from "react";
import Modal from "react-modal";
import "./Modal.scss";
import "./ModalMobile.scss";
import closeIcon from "../../assets/img/close-modal.svg";
import { Link } from "react-router-dom";
import routes from "../../routes";

Modal.setAppElement("#root");

export default function CreatePostNotification({
  modalIsOpen,
  onCloseModal,
  text,
  textTwo,
  header,
  onCancel,
  onConfirm,
  modalWidth,
  textWidth,
}) {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      paddingLeft: "0",
      paddingRight: "0",
      paddingBottom: "0",
      paddingTop: "16px",
      width: "500px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
      overflow: "hidden",
    },
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
      >
        <div className="modal__top">
          <p className="text text--normal text--bold modal__header">Note</p>
          <button onClick={onCloseModal} className="modal__close">
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="modal__body createPostNotification">
          <p className="createPostNotification__paragraph">
            By creating a post you agree to be polite and respectful to other
            users of the service.
          </p>
          <ul className="createPostNotification__list">
            <span className="createPostNotification__text">
              And not to post content that contains
            </span>
            <li className="createPostNotification__list-item">profanity; </li>
            <li className="createPostNotification__list-item">
              sexual content;
            </li>
            <li className="createPostNotification__list-item">
              overly graphic, disturbing or offensive material;
            </li>
            <li className="createPostNotification__list-item">
              vulgar or abusive language;
            </li>
            <li className="createPostNotification__list-item">
              hate speech, defamatory comments, or offensive language targeting
              any specific demographic;
            </li>
            <li className="createPostNotification__list-item">
              personal attacks of any kind;
            </li>
            <li className="createPostNotification__list-item">spam;</li>
            <li className="createPostNotification__list-item">
              promotions for commercial products or services.
            </li>
          </ul>
          <p className="craetePostNotification__paragpaph">
            Read more details in{" "}
            <Link to={routes.termsOfService} className="link link--underlined">
              Terms of Service
            </Link>
            .
          </p>
        </div>
        <div className="modal__bottom createPostNotification__bottom">
          <button
            className="link link--small createPostNotification__button"
            onClick={onConfirm}
          >
            OK, I UNDERSTAND
          </button>
        </div>
      </Modal>
    </div>
  );
}
