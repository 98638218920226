import React, { useState, useEffect, useRef } from 'react'
import '../components/Post/post.scss'
import {
  CommentsList,
  ModalWithButtons,
  Description,
  SignUpModal,
  HeaderPost,
  TitlePost,
} from '../components'
import { useParams, useHistory } from 'react-router'
import routes from '../routes'
import axios from 'axios'
import { api_url } from '../config'
import ClipLoader from 'react-spinners/ClipLoader'
import { connect } from 'react-redux'
import authSelectors from '../redux/auth/authSelectors'
import postSelectors from '../redux/post/postSelectors'
import MetaTags from 'react-meta-tags'
import logo from '../assets/img/logo.png'
import { getMediaFile, returnFilePost } from '../utils/getMediaFile'
import AddComment from '../components/comments/AddComment'
import { setToken } from '../getToken'
function SinglePost({ currentUser, stopWords, isLoggedIn }) {
  const [post, setPost] = useState(null)
  const [user, setUser] = useState(null)
  const { id } = useParams()
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [isMyProfile, setIsMyProfile] = useState(false)
  const [likesCount, setLikesCount] = useState(0)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteCommentId, setDeleteCommentId] = useState(null)
  const [signUpModalIsOpen, setSignUpModalIsOpen] = useState(false)
  const [mediaFile, setMediaFile] = useState(getMediaFile())
  const [comments, setComments] = useState([]) // ! new
  const [totalComments, setTotalComments] = useState(0)
  const [pageComments, setPageComments] = useState(1)
  const node = useRef()
  const history = useHistory()

  useEffect(() => {
    if (!isLoggedIn) {
      const interval = setInterval(() => {
        setSignUpModalIsOpen(true)
        clearInterval(interval)
      }, 1000 * 45)
    }

    // eslint-disable-next-line
  }, [isLoggedIn])

  useEffect(() => {
    // if(post?.userId){
    setIsMyProfile(post?.userId === currentUser?.id)
    // }
    console.log(post?.userId, currentUser?.id)
  }, [currentUser, post])
  console.log('curr', currentUser)
  useEffect(() => {
    const singleTokenEffect = async () => {
      const token = await setToken()
      if (token && currentUser && !user) {
        axios
          .get(`${api_url}/feed/album?id=${id}`, {
            headers: { Authorization: token },
          })
          .then((response) => {
            response.data.data.userId === currentUser.id && setIsMyProfile(true)
            const {
              description,
              id,
              created_at,
              user,
              userId,
              likes_count,
              category,
              is_like_exists,
              status,
              title,
              pinned,
              subscriptions,
              background_id,
              media,
              first_comment,
              comments_count,
            } = response.data.data

            setMediaFile(getMediaFile(media?.[0]))
            setLikesCount(likes_count)

            setComments(first_comment || [])
            setTotalComments(comments_count)

            setPost({
              description,
              id,
              created_at,
              user,
              userAvatar: user?.media?.[0]?.url || '',
              category,
              status,
              userId,
              title,
              pinned,
              subscriptions,
              background_id,
              is_like_exists,
            })

            setUser({
              id: user ? response.data.data.userId : 0,
              avatar: user?.media?.[0]?.url || '',
              userName: user ? user.userName : response.data.data.fullName,
              fullName: user ? user.fullName : response.data.data.fullName,
            })
          })
          .catch((err) => {
            console.log('err ==>', err)
            // history.push("/notFound");
          })
      }
    }
    if (isLoggedIn) {
      singleTokenEffect()
    } else {
      history.push(routes.logIn)
    }
    // eslint-disable-next-line
  }, [id])

  //* ===============   COMPONENT   =============== *//

  const getComments = async () => {
    const token = await setToken()
    setCommentsLoading(true)
    axios
      .get(`${api_url}/comment?album_id=${post.id}&page=${pageComments}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        if (response.data.comments.data?.length) {
          setComments(response.data.comments.data)
          setTotalComments(response.data.comments.total)
        } else {
          setComments([])
        }
      })
      .finally(() => setCommentsLoading(false))
  }

  useEffect(() => {
    post && getComments()
    // eslint-disable-next-line
  }, [pageComments])

  // ! pin unpin comment
  const togglePinComment = async (id, pinned) => {
    const token = await setToken()
    axios
      .get(`${api_url}/comment/${!pinned ? 'pinned' : 'unpinned'}/${id}`, {
        headers: { Authorization: token },
      })
      .then(() => getComments())
      .catch((err) => console.log(err))
  }
  // ! added comment
  const onAddedComment = (newComment) => {
    const total = totalComments + 1
    setTotalComments(total)
    const page = Math.ceil(total / 10)
    if (pageComments !== page) {
      setPageComments(page ? page : 1)
    } else {
      getComments()
    }
  }

  // ! delete comment
  const handleDeleteComment = async () => {
    const token = await setToken()
    // setComments((prevState) =>
    //   prevState.filter((comment) => comment.id !== deleteCommentId)
    // )
    handleDeleteModalClose()
    setDeleteCommentId(null)

    axios
      .delete(`${api_url}/comment/remove/${deleteCommentId}`, {
        headers: { Authorization: token },
      })
      .then(() => {
        const total = totalComments - 1
        setTotalComments(total)
        const page = Number.isInteger(total / 10)
          ? pageComments - 1
          : pageComments

        if (page !== pageComments) {
          setPageComments(page ? page : 1)
        } else {
          getComments()
        }
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteModalClose = () => setDeleteModalIsOpen(false)

  const handleDeleteModalOpen = (id) => {
    setDeleteCommentId(id)
    setDeleteModalIsOpen(true)
  }

  const handleReadMore = () => setPageComments(pageComments + 1)

  const handleReadLess = () => setPageComments(pageComments - 1)

  const commentsList = () => {
    if (!totalComments) {
      return <p style={{ opacity: '0.7' }}>No comments yet</p>
    } else if (commentsLoading) {
      return <ClipLoader color={'#050138'} loading={true} size={30} />
    } else if (comments?.length) {
      return (
        <>
          <CommentsList
            comments={comments.sort((a, b) =>
              a.pinned !== b.pinned
                ? a - b
                : new Date(a.created_at) - new Date(b.created_at),
            )}
            currentUserId={currentUser.id}
            handleOpenModal={handleDeleteModalOpen}
            isMyProfile={isMyProfile}
            togglePinComment={togglePinComment}
            stopWords={stopWords}
          />
          {pageComments > 1 && (
            <p onClick={handleReadLess} className="link link--underlined">
              Prev
            </p>
          )}
          {Math.ceil(totalComments / 10) !== pageComments && (
            <p onClick={handleReadMore} className="link link--underlined">
              Read more
            </p>
          )}
        </>
      )
    }
  }

  return (
    <>
      <div className="container">
        <div className="wrap">
          {post && user ? (
            <>
              <MetaTags>
                <meta
                  name="description"
                  content={
                    post.description ? post.description : 'No description'
                  }
                />
                <meta property="og:title" content="Testimony" />
                <meta
                  property="og:image"
                  content={mediaFile.type === 'image' ? mediaFile.url : logo}
                />
              </MetaTags>
              <div className="post__wrap" ref={node}>
                <HeaderPost
                  postId={post?.id}
                  userId={post?.user?.id}
                  user={user}
                  isFollow={!!post?.subscriptions}
                  isPinned={
                    !!post?.pinned?.find(
                      (el) => el?.pivot?.album_id === post?.id,
                    )
                  }
                  isMyProfile={isMyProfile}
                  isLoggedIn={isLoggedIn}
                />
                <div style={{ position: 'relative' }}>
                  {post.admin_recommended === 1 && (
                    <p className="text text--bold post__category-admin">
                      Advisor's choice
                    </p>
                  )}
                  {returnFilePost(
                    mediaFile.type,
                    mediaFile.url,
                    mediaFile.isDefaultBackground,
                    'defaultImage',
                    post?.status !== 3 ? post?.title : '',
                    post?.admin_recommended,
                    post?.category.name,
                    `${mediaFile.customClass} ${
                      post?.status === 3 ? 'inReview' : ''
                    }`,
                  )}
                </div>
                <TitlePost
                  postId={post.id}
                  postTitle={post.title}
                  category={post.category.name.replace('/', ' / ')}
                  categoryId={post.category.id}
                  isLike={post.is_like_exists}
                  likesCount={likesCount}
                  changeLikeCount={setLikesCount}
                />
                <div className="comments post__comments">
                  {post.category && (
                    <Description
                      postId={id}
                      categoryId={post.category.id}
                      name={user.userName}
                      text={
                        post.description ? post.description : 'No description'
                      }
                      isMyProfile={isMyProfile}
                      userId={user.id}
                      stopWords={stopWords}
                      status={post.status}
                    />
                  )}
                  <hr />
                  {commentsList()}
                  <AddComment
                    postId={post?.id}
                    onSuccess={onAddedComment}
                    isLoggedIn={isLoggedIn}
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '50px',
              }}
            >
              <ClipLoader color={'#050138'} loading={true} size={70} />
            </div>
          )}
        </div>
        <ModalWithButtons
          modalIsOpen={deleteModalIsOpen}
          onCloseModal={handleDeleteModalClose}
          header="Delete Comment"
          text="Are you sure you want to delete a comment? This action cannot be reverted."
          onConfirm={handleDeleteComment}
        />
        <SignUpModal
          modalIsOpen={signUpModalIsOpen}
          onCloseModal={() => setSignUpModalIsOpen(false)}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentUser: authSelectors.getUser(state),
  stopWords: postSelectors.getStopWords(state),
  isLoggedIn: authSelectors.isLoggedIn(state),
})

export default connect(mapStateToProps)(SinglePost)
