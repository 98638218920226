import React from "react";
import "./additional.scss";
import "./additionalMobile.scss";

export default function AboutUs() {
  return (
    <div className="container">
      <div className="wrap">
        <h1 className="header header--one additional__title">About Us</h1>
        <h2 className="header header--two additional__subtitle">Who we Are</h2>
        <p className="text text--normal additional__text">
          Testigram is a website/app designed to share the amazing testimonies
          of modern-day Christians from all over the world. The idea for this
          app comes from Revelation 12:11 which says: “And they overcame him by
          the blood of the Lamb, and by the word of their testimony; and they
          loved not their lives unto the death.” Based in Windsor, Canada we
          reach 100,000 prayer warriors in 117 countries with our scripture
          readings, daily prayers, words of encouragement that have given birth
          to over 25,000 true-life testimonies and praise reports in such areas
          as salvation, healing, and deliverance.
        </p>
        <h2 className="header header--two additional__subtitle">Our Mission</h2>
        <p className="text text--normal additional__text">
          We have a God-ordained mandate to impact the world with our daily
          testimonies and praise reports. We are called to present proof of the
          Gospel by offering this platform as a megaphone for believers to tell
          the world what the Lord Jesus Christ has done - and continues to do –
          for them and their families. God requires that we demonstrate how to
          pray and receive answers to those prayers in a way that encourages
          other believers to wholly put their trust in the Lord and be ready to
          stand against any spiritual enemy and say, like King Hezekiah: With
          him is an arm of flesh; but with us is the LORD our God to help us,
          and to fight our battles. – 2 Chr. 32:8 With Testigram we present the
          message of Christ and His love for humanity using the most powerful
          real-life testimonies of the day to encourage thousands through text,
          audio and video at no charge.
        </p>
      </div>
    </div>
  );
}
