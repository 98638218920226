import { connect } from 'react-redux'
import React from 'react'
import { Post } from '../../components'
import authSelectors from '../../redux/auth/authSelectors'
import postSelectors from '../../redux/post/postSelectors'
import Advertisement from '../Advertisement'
import { getMediaFile } from '../../utils/getMediaFile'

function PostsList({
  posts = [],
  userId,
  userFullName,
  stopWords,
  userStatus,
  isLoggedIn,
  userName,
  avatar,
}) {
  return (
    <>
      {posts.map((post, index) => {
        const { url, type, isDefaultBackground, customClass } = getMediaFile({
          ...post?.media?.[0],
          style: post?.media?.[0]?.style,
          preview: post?.preview || '',
        })
        return (
          post && (
            <div
              key={post.id}
              className={posts?.length - 1 !== index ? 'post__delimiter' : ''}
            >
              {post && post.url === undefined && (
                <Post
                  // key={post.id}
                  isLoggedIn={isLoggedIn}
                  post={post}
                  isMyProfile={post.userId === userId}
                  fileUrl={url}
                  fileType={type}
                  fileClass={customClass}
                  isDefaultBackground={isDefaultBackground}
                  userAvatar={post?.user?.media?.[0]?.url || ''}
                  defaultImageClass={
                    !post?.media?.length > 0 ? 'defaultImage' : 'none'
                  }
                  subscriptions={post?.subscriptions}
                  lastComment={post.last_comment}
                  likes_count={post.likes_count}
                  is_like_exists={post.is_like_exists}
                  currentUserId={userId}
                  currentUserFullName={userFullName}
                  currentUserName={userName}
                  currentUserAvatar={avatar}
                  postFullName={post.user ? post.user.userName : post.fullName}
                  postUserName={post.user ? post.user.userName : post.fullName}
                  userStatus={userStatus}
                  postCategory={post.category}
                  stopWords={stopWords}
                  pinned={post?.pinned}
                />
              )}
              {post && typeof post.url === 'string' && (
                <Advertisement
                  key={"ad_" + post.id}
                  id={post.id}
                  title={post.title}
                  description={post.description}
                  url={post.url}
                  file={post.media?.[0]?.url}
                />
              )}
            </div>
          )
        )
      })}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userFullName: authSelectors.getFullName(state),
    userName: authSelectors.getUserName(state),
    userStatus: authSelectors.getUserStatus(state),
    stopWords: postSelectors.getStopWords(state),
    isLoggedIn: authSelectors.isLoggedIn(state),
    avatar: authSelectors.getAvatar(state),
  }
}

export default connect(mapStateToProps)(PostsList)
