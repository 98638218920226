const routes = {
  logIn: '/signIn',
  register: '/signUp',
  forgotPassword: '/forgotPassword',
  editProfile: '/editProfile',
  home: '/',
  profile: '/profile',
  confirmEmail: '/confirm-email',
  passwordReset: '/password-reset',
  aboutUs: '/aboutUs',
  faq: '/faq',
  privacyPolicy: '/privacyPolicy',
  termsOfService: '/termsOfService',
  createPost: '/createPost',
  chooseFile: '/chooseFile',
  chooseFilter: '/chooseFilter',
  chooseText: '/chooseText',
  chooseCategory: '/chooseCategory',
  verifyNewEmail: '/email-set',
  singlePost: '/post',
  userProfile: '/userProfile',
  myProfile: '/myProfile/albums',
  myProfilePinned: '/myProfile/pinned',
  feed: '/feed',
  stream: '/stream',
  advertisement: '/advertisement-redirect',
}

export default routes
