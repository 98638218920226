import React, { useState, useEffect } from 'react'
import { Image } from '../components'
import { api_url, commentLimit } from '../config'
import cutText from '../utils/cutText'
import getTextFromNode from '../utils/getTextFromNode'
import Parser from 'html-react-parser'
import axios from 'axios'
import { setToken } from '../getToken'
export default function Advertisement({ id, title, file, description, url }) {
  const [textIsShorten, setTextIsShorten] = useState('')

  description = description.replace(
    'href="',
    `href="${window.location.href}advertisement-redirect/${id}?`,
  )

  const handleClickImage = async () => {
    const token = await setToken()
    axios.get(`${api_url}/advertisements/${id}`, {
      headers: { Authorization: token },
    })
  }
  useEffect(() => {
    if (description) {
      let node = Parser(description)
      let pureText =
        typeof node === 'object' && node.length
          ? node.map((el) => getTextFromNode(el)).join(' ')
          : description

      if (pureText.length > commentLimit) {
        setTextIsShorten(true)
      }
    }
  }, [description])

  return (
    <div className="post__container">
      <div className={`post__wrap`}>
        <div className="post__block">
          <p
            className="post__date text text--small"
            style={{ textAlign: 'right', marginLeft: 'auto' }}
          >
            Advertisement
          </p>
        </div>

        <a
          href={url}
          onClick={handleClickImage}
          target="_blank"
          rel="noreferrer"
        >
          <Image file={file} />
        </a>
        <div className="post__category">
          <p
            className="header header--three post__title"
            style={{ margin: '15px 0 5px 0' }}
          >
            {title}
          </p>
        </div>

        <span
          className="comments__text"
          style={{ marginTop: '15px' }}
          dangerouslySetInnerHTML={{
            __html: textIsShorten
              ? cutText(description, commentLimit)
              : description,
          }}
        ></span>

        {textIsShorten && (
          <button
            onClick={() => setTextIsShorten(false)}
            className="link link--underlined comments__text-button"
          >
            Read more
          </button>
        )}
      </div>
    </div>
  )
}
