import React, { useState, useEffect } from 'react'
import './post.scss'
import './postMobile.scss'
import Avatar from 'react-avatar'
import pinPostImg from '../../assets/img/pinPost.svg'
import unPinPostImg from '../../assets/img/defaultPin.svg'
import mobilePin from '../../assets/img/activePin.svg'
import routes from '../../routes'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../../config'
// import { setToken } from '../../getToken'

const HeaderPost = ({
  postId,
  userId,
  user,
  isFollow,
  isPinned,
  isMyProfile,
  isLoggedIn,
  postFullName,
  userAvatar,
}) => {
  const [following, setFollowing] = useState(false)
  const [pinPost, setPinPost] = useState(false)
  const history = useHistory()
  const token = axios.defaults.headers.common.Authorization
  useEffect(() => {
    setFollowing(isFollow)
    setPinPost(isPinned)
  }, [postId, isFollow, isPinned])
  const handlePinned = () => {
    // const token = await setToken()
    if (isLoggedIn) {
      axios
        .post(
          `${api_url}/feed/pin?album_id=${postId}`,
          {},
          {
            headers: { Authorization: token },
          },
        )
        .then(() => setPinPost(!pinPost))
    } else {
      history.push(routes.logIn)
    }
  }

  const subscribeUser = () => {
    // const token = await setToken()
    if (isLoggedIn) {
      if (following) {
        axios
          .delete(`${api_url}/subscription/remove/${userId}`, {
            headers: { Authorization: token },
          })
          .then((res) => res?.data?.subscription && setFollowing(false))
      } else {
        axios
          .post(
            `${api_url}/subscription/create?id=${userId}`,
            {},
            {
              headers: { Authorization: token },
            },
          )
          .then((res) => res?.data?.subscription && setFollowing(true))
      }
    } else {
      history.push(routes.logIn)
    }
  }

  if (!user?.id) {
    return (
      <div className="post__block">
        <div className="post__user">
          <Avatar
            size={32}
            name={user?.fullName || postFullName || ''}
            round="50%"
            src={user?.avatar || userAvatar}
            maxInitials={2}
            color="#2E8299"
          />
          <p className="text text--small text--bold post__user-fullName">
            {user?.fullName || postFullName}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="post__block">
      <div className="post__user__block">
        <Link
          className="post__user"
          to={
            isMyProfile ? routes.myProfile : routes.userProfile + '/' + user?.id
          }
        >
          <Avatar
            size={32}
            name={user?.fullName}
            round="50%"
            src={user?.avatar || userAvatar}
            maxInitials={2}
            color="#2E8299"
          />
          <p className="text text--small text--bold post__user-fullName">
            {user?.userName || postFullName}
          </p>
        </Link>
        {!isMyProfile && (
          <button
            className={
              'post__button' + (following ? ' post__button-active' : '')
            }
            onClick={() => subscribeUser(userId)}
          >
            {following ? 'Following' : 'Follow'}
          </button>
        )}
      </div>
      {!isMyProfile && (
        <button
          className={
            'post__button mobilePin' +
            (pinPost ? ' post__button-active mobilePin' : '')
          }
          onClick={() => handlePinned(postId)}
        >
          <span>{pinPost ? 'Pinned' : 'Pin'} post</span>
          <img
            src={pinPost ? pinPostImg : unPinPostImg}
            alt=""
            className={'pinedPostTable'}
            style={{ width: 15 }}
          />
          <img
            alt=""
            className={'pinedPost'}
            src={pinPost ? pinPostImg : mobilePin}
            style={{ width: 15 }}
          />
        </button>
      )}
    </div>
  )
}

export default HeaderPost
