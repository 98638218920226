import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import authSelectors from '../../redux/auth/authSelectors'
import Profile from './Profile'
import axios from 'axios'
import { api_url } from '../../config'
import {
  CreatePostNotification,
  ModalWindow,
  ModalWithButtons,
} from '../../components'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../../routes'
import { setToken } from '../../getToken'
function MyProfile({ user, avatar }) {
  const [albums, setAlbums] = useState(null)
  const [notificationIsOpen, setNotificationIsOpen] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [postsPage, setPostsPage] = useState(1)
  const [albumsTotal, setAlbumsTotal] = useState(0)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [warningModal, setWarningModal] = useState({ header: '', text: '' })
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  const { pathname } = useLocation()

  const history = useHistory()

  const activeButton = pathname.replace('/myProfile/', '')

  // const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    setAlbums([])
    return () => {
      setAlbums([])
    }
  }, [activeButton])

  useEffect(() => {
    user.status === 2 &&
      setWarningModal({
        header: 'Blocked',
        text: `Hi! You were block because of repeated violation of the rules of the community <a href="/termsOfService" target="_blank">Terms of Service</a> & <a href="/privacyPolicy" target="_blank">Privacy Policy</a>. You can contact us at <a href="mailto: support@testigram.ai"   target="_blank">support@testigram.ai</a> if you dissagree with the blocking.`,
      })
    user.status === 3 &&
      setWarningModal({
        header: 'Warned',
        text:
          'Hi, we found that you violated the rules of the community <a href="/termsOfService" target="_blank">Terms of Service</a> & <a href="/privacyPolicy" target="_blank">Privacy Policy</a>. Please, read the community rules not to violate the rules again!',
      })

    user.status === 4 &&
      setWarningModal({
        header: 'Muted',
        text: `You were muted (you can't make posts or comments) because you violated the rules of the community. Please, read the rules carefully <a href="/termsOfService" target="_blank">Terms of Service</a> & <a href="/privacyPolicy" target="_blank">Privacy Policy</a>. On subsequent violation of the rules you'll be blocked.`,
      })
  }, [user])

  useEffect(() => {
    const myProfileToken = async () => {
      const token = await setToken()
      if (token) {
        getDataPosts(postsPage)
      }
    }
    myProfileToken()
    // eslint-disable-next-line
  }, [postsPage])

  useEffect(() => {
    const myProfileEffect = async () => {
      const token = await setToken()
      if (token) {
        if (postsPage !== 1) {
          setPostsPage(1)
          getDataPosts(1)
          setNextPageUrl(0)
          // setAlbumsTotal(null)
        } else {
          getDataPosts(postsPage)
        }
      }
    }
    myProfileEffect()
    // eslint-disable-next-line
  }, [user, activeButton])

  const handleClickButtonCategory = (category) =>
    history.push(`/myProfile/${category}`)

  const getDataPosts = async (page) => {
    const token = await setToken()
    setLoading(true)
    if (activeButton !== 'albums' && !albumsTotal && nextPageUrl === null) {
      await axios
        .get(`${api_url}/feed/my/albums?page=1`, {
          headers: { Authorization: token },
        })
        .then((response) => setAlbumsTotal(response?.data?.albums?.total))
    }
    axios
      .get(`${api_url}/feed/my/${activeButton}?page=${page}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        const resData = response?.data?.albums

        if (albums?.length && nextPageUrl) {
          setAlbums((prev) => [...prev, ...resData?.data])
        } else {
          setAlbums(resData?.data)
        }
        setNextPageUrl(resData?.next_page_url)
        activeButton === 'albums' && setAlbumsTotal(resData?.total)
      })
      .catch((err) => console.log('error', err))
      .finally(() => setLoading(false))
  }

  const goToCreatePost = () => {
    if (albums.length === 0) {
      setNotificationIsOpen(true)
    } else {
      history.push(routes.chooseCategory)
    }
  }

  const loadMorePosts = () => {
    if (nextPageUrl && !loading) {
      setPostsPage((prevState) => prevState + 1)
    }
  }

  const handleCloseNotification = () => setNotificationIsOpen(false)

  const handleConfirmNotification = () => {
    setNotificationIsOpen(false)
    history.push(routes.chooseCategory)
  }

  const handleDeleteModalOpen = (id) => {
    setDeleteId(id)
    setDeleteModalIsOpen(true)
  }

  const handleDeleteModalClose = () => setDeleteModalIsOpen(false)

  const handleDeletePost = async () => {
    const token = await setToken()
    setDeleteModalIsOpen(false)
    axios
      .delete(`${api_url}/feed/remove-album/${deleteId}`, {
        headers: { Authorization: token },
      })
      .then((response) => console.log(response))
      .catch((err) => console.log(err))
    setAlbums((prevState) => prevState.filter((album) => album.id !== deleteId))
    setAlbumsTotal(albums.length)
  }

  return (
    <>
      <Profile
        user={user}
        avatar={avatar}
        isMyProfile={true}
        albums={albums}
        goToCreatePost={goToCreatePost}
        handleDeleteModalOpen={handleDeleteModalOpen}
        loadMorePosts={loadMorePosts}
        loading={loading}
        nextPageUrl={nextPageUrl}
        albumsTotal={albumsTotal}
        handleOpenWarningModal={() => setWarningModalIsOpen(true)}
        activeButton={activeButton}
        setActiveButton={handleClickButtonCategory}
      />
      <CreatePostNotification
        modalIsOpen={notificationIsOpen}
        onCloseModal={handleCloseNotification}
        onConfirm={handleConfirmNotification}
      />
      <ModalWithButtons
        modalIsOpen={deleteModalIsOpen}
        onCloseModal={handleDeleteModalClose}
        header="Delete Post"
        text="Are you sure you want to delete a post? This action cannot be reverted."
        onConfirm={handleDeletePost}
      />
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        modalIsOpen={warningModalIsOpen}
        onCloseModal={() => setWarningModalIsOpen(false)}
        header={warningModal.header}
        text={warningModal.text}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    avatar: authSelectors.getAvatar(state),
    // followers: authSelectors.getFollowers(state),
  }
}

export default connect(mapStateToProps)(MyProfile)
