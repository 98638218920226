import React, { useState } from 'react'
import axios from 'axios'
import { api_url } from '../../config'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import ModalsStream from './Modal'
import { connect } from 'react-redux'
import authSelectors from '../../redux/auth/authSelectors'
import postSelectors from '../../redux/post/postSelectors'
import Card from './Card'
import { setToken } from '../../getToken'
import './streamSlider.scss'
import { useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ModalWindow, ModalWithButtons } from '../../components'
import postOperations from '../../redux/post/postOperations'

const StreamSlider = ({
  streamState,
  setStream,
  isFinished,
  currentUser,
  timer,
}) => {
  const [streams, setStreams] = useState([])
  const [isStreamAvailable, setIsStreamAvailable] = useState(false)
  const [isOpenModalStream, setIsOpenModalStream] = useState(false)
  const [isOpenModalError, setIsOpenModalError] = useState(false)
  const [streamTomorrowMessage, setStreamTomorrowMessage] = useState('')
  const [openTomorrowModal, setOpenTomorrowModal] = useState(false)
  const [showError, setShowError] = useState('')
  const [isActiveStream, setIsActiveStream] = useState(false)
  const [activeError, setActiveError] = useState('')
  const [goToLiveStream, setGoToLiveStream] = useState(false)
  const [getStream, setGetStream] = useState(null)
  const { pathname, state } = useLocation()
  const { params } = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    if (isOpenModalError) {
      getAvailableStreams()
    }
    // eslint-disable-next-line
  }, [isOpenModalError])

  useEffect(() => {
    getAvailableStreams()
  }, [])

  useEffect(() => {
    if (streamState.isMyStream === true && timer > 0) {
      axios.get(`${api_url}/live/active/${streamState?.id}`).catch((err) => {
        setActiveError(err.response.data.message)
      })
    }
  }, [timer])

  useEffect(() => {
    if (activeError && streamState.isMyStream === true) {
      setIsActiveStream(true)
    }
  }, [activeError])

  // useEffect(() => {
  //   if (isActiveStream) {
  //     setTimeout(function () {
  //       setIsActiveStream(false)
  //       history.push('/')
  //       history.go(0)
  //     }, 10000)
  //   }
  // }, [isActiveStream])

  useEffect(() => {
    if (params.id && streams.length && !streamState.id) {
      const activeStream = streams.find((s) => s.id == params.id)
      if (activeStream) {
        goLiveStream(activeStream)
      }
    } else if (params.id && !streamState.id) {
      axios.get(`${api_url}/live/active/${params?.id}`).then((res) => {
        if (res.data.stream.user.id !== currentUser.id) {
          goLiveStream(res.data.stream)
        }
      })
    }
  }, [params?.id, streams])

  const goLiveStream = (stream) => {
    setStream({ ...stream, isMyStream: false })
    console.log('stream', stream)
    axios
      .get(`${api_url}/live/active/${stream?.id}`)
      .then((res) => {
        history.push(`/stream/${stream?.id}`, { ...stream, isMyStream: false })
      })
      .catch((err) => {
        setIsOpenModalError(true)
        setShowError(err.response.data.message)
      })
  }

  const getAvailableStreams = async () => {
    const token = await setToken()
    if (token) {
      axios.get(`${api_url}/live/active`).then((res) => {
        setIsStreamAvailable(res.data?.is_stream_available)
        const streamSubscriptions = res.data?.stream?.subscriptions || []
        const streamSubscriptionsOfSubscriptions =
          res.data?.stream?.subscriptions_of_subscriptions || []
        const resault = streamSubscriptions.concat(
          streamSubscriptionsOfSubscriptions,
        )
        setStreams(resault)
      })
    }
  }
  const handleCloseModal = () => {
    setIsOpenModalError(false)
  }
  const handleCloseActive = () => {
    setIsActiveStream(false)
    history.push('/')
    history.go(0)
  }
  const handleCloseTomorrowModal = () => {
    setOpenTomorrowModal(false)
  }
  const streamStart = (status) => {
    if (status == 1) {
      return () => {
        setIsOpenModalStream(true)
      //   axios
      //     .post(`${api_url}/live/create`, {
      //       title: '123',
      //       description: '123',
      //     })
      //     .catch((err) => {
      //       setStreamTomorrowMessage(err.response.data.message)
      //       setOpenTomorrowModal(true)
      //     })
      }
    }
    return () => setIsOpenModalStream(true)
  }
  const openGoToLiveStreamModal = () => {
    setGoToLiveStream(true)
  }
  const closeGoToLiveStreamModal = () => {
    setGoToLiveStream(false)
  }
  const handleGoToLiveStream = () => {
    if (streamState.isMyStream) {
      axios.delete(`${api_url}/live/end/${streamState.id}`).then(() => {
        setGoToLiveStream(false)
        goLiveStream(getStream)
      })
    }
  }
  return (
    <div className="StreamsSliders-wrap">
      <Swiper
        className="StreamsSliders"
        slidesPerView={'auto'}
        spaceBetween={22}
      >
        <SwiperSlide className="card-Slider">
          <Card
            status={
              pathname.includes('/stream') && state?.isMyStream
                ? 2
                : Number(isStreamAvailable)
            }
            onClick={
              pathname.includes('/stream')
                ? null
                : streamStart(isStreamAvailable)
            }
            isFinished={isFinished}
            isMyStream={state?.isMyStream}
          />
        </SwiperSlide>
        {streams.map((stream) => {
          return (
            <SwiperSlide key={stream.id} className="card-Slider">
              <Card
                name={stream?.user?.userName || ''}
                image={stream.user?.media?.[0]?.url}
                onClick={() => {
                  if (streamState.isMyStream === true) {
                    openGoToLiveStreamModal()
                    setGetStream(stream)
                  } else {
                    goLiveStream(stream)
                  }
                }}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <ModalsStream
        isOpenModalStream={isOpenModalStream}
        setIsOpenModalStream={setIsOpenModalStream}
      />
      <ModalWindow
        modalIsOpen={isOpenModalError}
        onCloseModal={handleCloseModal}
        modalWidth={380}
        textWidth={295}
        text={showError}
      />
      <ModalWithButtons
        modalIsOpen={goToLiveStream}
        onCloseModal={closeGoToLiveStreamModal}
        header="Finish stream"
        text="Are you sure you want to finish this stream?"
        onConfirm={handleGoToLiveStream}
      />
      <ModalWindow
        modalIsOpen={isActiveStream}
        onCloseModal={handleCloseActive}
        modalWidth={380}
        textWidth={295}
        text="The admin has been finished your stream due to a violation of community rules"
      />
      <ModalWindow
        modalIsOpen={openTomorrowModal}
        onCloseModal={handleCloseTomorrowModal}
        modalWidth={380}
        textWidth={295}
        text={streamTomorrowMessage}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUser: authSelectors.getUser(state),
  isLoggedIn: authSelectors.isLoggedIn(state),
  streamState: postSelectors.getStream(state),
})

export default connect(mapStateToProps, {
  setStream: postOperations.setStream,
})(StreamSlider)
