import React, { useState } from 'react'
import { ShareModal } from '../../components'
import ShareIcon from '../../assets/img/share.svg'
import '../../components/Post/post.scss'

const TitlePost = ({ postId, postTitle }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleOpenModal = (e) => {
    setModalIsOpen(true)
    e.stopPropagation()
  }

  const handleCloseModal = (e) => {
    if (
      !e.target.tagName === 'circle' ||
      !e.target.tagName === 'path' ||
      (typeof e.target.className === 'string' &&
        !e.target.className.includes('shareModal'))
    ) {
      setModalIsOpen(false)
    }
  }

  return (
    <div className="post__category" style={{ flexDirection: 'column' }}>
      <p className="post__category__title streamTitleText">
        {/*{postTitle}*/}
        <div className={'streamTitleBlock'}>
          <span className="liveText">LIVE</span> {postTitle}
        </div>
      </p>
      <div className="post__buttons">
        <div className="post__buttons-like"></div>
        <button className="post__buttons-share" onClick={handleOpenModal}>
          <img src={ShareIcon} alt="share" className="post__buttons-copy" />
          <span className="post__buttons-share-text">Share</span>
        </button>
        <ShareModal
          link={`${window.location.origin}/stream/${postId}`}
          onCloseModal={handleCloseModal}
          modalIsOpen={modalIsOpen}
        />
      </div>
    </div>
  )
}

export default TitlePost
