import React, { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import './editProfile.scss'
import './editProfileMobile.scss'
import { Button, AvatarChange, ModalWindow } from '../../components/'
import { connect } from 'react-redux'
import authOperations from '../../redux/auth/authOperations'
import { useFormik } from 'formik'
import editProfileSchema from '../../validationSchemas/editProfileSchema'
import { api_url } from '../../config'
import axios from 'axios'
import authSelectors from '../../redux/auth/authSelectors'
import { useHistory } from 'react-router'
import { setToken } from '../../getToken'

function EditProfile({ user, getUser, avatar, getAvatar }) {
  const [changeEmail, setChangeEmail] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [userName, setUserName] = useState('')
  const [error, setError] = useState({
    userName: '',
    newEmail: '',
  })
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modal, setModal] = useState({
    text: '',
    header: '',
  })

  const history = useHistory()

  const formik = useFormik({
    initialValues: user,

    enableReinitialize: true,
    validationSchema: editProfileSchema,
    onSubmit: async (values) => {
      const { fullName, userName, bio } = values
      const token = await setToken()
      if (
        JSON.stringify({
          fullName: user.fullName,
          userName: user.userName,
          bio: user.bio,
        }) !== JSON.stringify({ fullName, userName, bio })
      ) {
        axios
          .post(
            `${api_url}/profile/edit`,
            {
              fullName,
              userName,
              bio,
            },
            {
              headers: { Authorization: token },
            },
          )
          .then((response) => {
            setModalIsOpen(true)
            !formik.values.newEmail && setChangeEmail(false)
            setModal({
              text: 'Saved your changes!',
              header: 'Success',
            })
            getUser()
          })
          .catch((err) => {
            if (err.response.data.message) {
              setModal({
                text: err.response.data.message,
                header: 'Denied',
              })
              setModalIsOpen(true)
            } else if (err.response.data.userName) {
              setError((prevState) => {
                return {
                  ...prevState,
                  userName: err.response.data.userName[0],
                }
              })
            }
          })
      } else {
        return
      }
    },
  })

  useEffect(() => {
    setUserName(user.userName)
    return
  }, [user])

  const handleImageLoaded = (elem) => {
    setImageLoaded(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setImageLoaded(false)
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const cancelChanges = () => {
    const { fullName, userName } = formik.values
    if (
      JSON.stringify({
        fullName: user.fullName,
        userName: user.userName,
      }) !== JSON.stringify({ fullName, userName })
    ) {
      setChangeEmail(false)
      formik.handleReset()
    } else {
      history.goBack()
    }
  }

  const onCloseModal = () => {
    setModalIsOpen(false)
    cancelChanges()
  }

  const sendVerification = async () => {
    const token = await setToken()
    if (!formik.errors.newEmail) {
      const formdata = new FormData()
      formdata.append('email', formik.values.newEmail)
      axios
        .post(`${api_url}/auth/email-change`, formdata, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setModalIsOpen(true)
          setModal({
            header: 'Verification sent',
            text: `A verification link is sent to ${formik.values.newEmail}. Follow the instructions in the email to verify new email and set your password.`,
          })
        })
        .catch((err) => {
          console.log('errRes', err.response.data)
          setError((prevState) => {
            return {
              ...prevState,
            }
          })
        })
    }
  }

  const handleSaveAvatar = async (img) => {
    let formData = new FormData()
    const token = await setToken()
    formData.append('avatar', img)
    axios
      .post(`${api_url}/profile/avatar`, formData, {
        headers: { Authorization: token },
      })
      .then((response) => {
        getAvatar()
        handleCloseModal()
      })
      .catch((err) => console.log(err.response.data))
  }

  return (
    <div className="container">
      <div className="wrap  editProfile__wrap">
        <h1 className="header header--one editProfile__header">Edit Profile</h1>
        <div className="user editProfile__user">
          <div className="user__avatar">
            <Avatar
              // size={150}
              className="editProfileAvatar"
              name={formik.values.fullName}
              round="50%"
              src={avatar}
              maxInitials={2}
              color="#050138"
              style={{ fontSize: '48px' }}
            />
          </div>
          <div className="editProfile__info">
            <h2 className="header header--two editProfile__userName">
              {userName}
            </h2>
            <button
              type="button"
              onClick={handleShowModal}
              className="link link--normal link--underlined"
            >
              Edit profile photo
            </button>
          </div>
        </div>
        <form className="form editProfile__form" onSubmit={formik.handleSubmit}>
          <div
            className={`form-group editProfile__form-group ${
              formik.errors.fullName && 'group-error'
            }`}
          >
            <label className="label editProfile__label">Full Name</label>
            <div className="editProfile__group">
              <input
                type="text"
                name="fullName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullName}
                className={`formInput editProfile__formInput ${
                  formik.errors.fullName && 'inputError'
                }`}
              />
              <p className="text text--tiny editProfile__text">
                {formik.errors.fullName ? (
                  formik.errors.fullName
                ) : (
                  <>
                    "Help people easily find you by indicating your real Name
                    and Surname. It can be with or without gaps. Full Name
                    length:"
                    <span className="text--bold"> 6-25 characters</span>.
                  </>
                )}
              </p>

              <p className="text text--tiny editProfile__text">
                You can change your name once within 14 days.
              </p>
            </div>
          </div>
          <div
            className={`form-group editProfile__form-group ${
              (formik.errors.userName || error.userName) && 'group-error'
            }`}
          >
            <label className="label editProfile__label">Username</label>
            <div className="editProfile__group">
              <input
                type="text"
                className={`formInput editProfile__formInput ${
                  (formik.errors.userName || error.userName) && 'inputError'
                }`}
                onChange={(e) => {
                  setError((prevState) => {
                    return { ...prevState, userName: '' }
                  })
                  formik.handleChange(e)
                }}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                name="userName"
              />
              <p className="text text--tiny editProfile__text">
                {formik.errors.userName || error.userName
                  ? formik.errors.userName || error.userName
                  : 'The username is used as your account name, it can include text, signs and numbers. Make sure it is not taken yet.'}
              </p>
              <p className="text text--tiny editProfile__text">
                Username length:
                <span className="text--bold"> 6-15 characters</span>.
              </p>
            </div>
          </div>
          <div
            className={`form-group editProfile__form-group ${
              formik.errors.bio && formik.values.bio && 'group-error'
            }`}
          >
            <label className="label editProfile__label">Bio</label>
            <div className="editProfile__group">
              <textarea
                type="text"
                className={`formInput editProfile__formInput editProfile__textarea ${
                  formik.errors.bio && formik.values.bio && 'inputError'
                }`}
                name="bio"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bio}
              />
              <p className="text text--tiny editProfile__text">
                {formik.errors.bio && formik.values.bio
                  ? formik.errors.bio
                  : 'A short description about yourself helps people get to know a little bit better. It can include text, signs and numbers.'}
              </p>
              <p className="text text--tiny editProfile__text">
                Bio length:{' '}
                <span className="text--bold"> 200 characters. </span>
              </p>
            </div>
          </div>
          <div
            className={`form-group editProfile__form-group ${
              formik.errors.email && formik.values.email && 'group-error'
            }`}
          >
            <label className="label editProfile__label">
              {changeEmail ? 'Current email' : 'Email'}
            </label>
            <div className="editProfile__group">
              <input
                type="email"
                className={`formInput editProfile__formInput ${
                  formik.errors.email && formik.values.email && 'inputError'
                }`}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                disabled
              />
              <p className="text text--tiny editProfile__text">
                {formik.errors.email && formik.values.email
                  ? formik.errors.email
                  : 'Your email is used to log in and requires verification. Click on'}
                <span className="text--bold"> Change email</span> to edit the
                information.
              </p>
              {!changeEmail && (
                <button
                  onClick={() => {
                    setChangeEmail(true)
                  }}
                  type="button"
                  className="link link--small link--underlined editProfile__link"
                >
                  Change email
                </button>
              )}
            </div>
          </div>
          {changeEmail && (
            <div
              className={`form-group editProfile__form-group ${
                ((formik.errors.newEmail && formik.values.newEmail) ||
                  error.newEmail) &&
                'group-error'
              }`}
            >
              <label className="label editProfile__label">
                New Email <span className="text--small">(not verified)</span>
              </label>
              <div className="editProfile__group">
                <input
                  type="email"
                  className={`formInput editProfile__formInput ${
                    formik.errors.newEmail &&
                    formik.values.newEmail &&
                    'inputError'
                  }`}
                  name="newEmail"
                  onChange={(e) => {
                    setError((prevState) => {
                      return {
                        ...prevState,
                        newEmail: '',
                      }
                    })
                    formik.handleChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.newEmail}
                />
                <p className="text text--tiny editProfile__text">
                  {(formik.errors.newEmail && formik.values.newEmail) ||
                  error.newEmail ? (
                    formik.errors.newEmail || error.newEmail
                  ) : (
                    <>
                      Your email is used to log in and requires verification.
                      Click on Your email is used to log in. Make sure you
                      indicate an email you have access to and Click on{' '}
                      <span className="text--bold">Verify email</span> to
                      continue.
                    </>
                  )}
                </p>
                <button
                  type="button"
                  onClick={sendVerification}
                  className="link link--small link--underlined editProfile__link"
                >
                  Verify email
                </button>
              </div>
            </div>
          )}
          <div className="editProfile__buttons">
            <Button
              text="Cancel"
              contained={false}
              className="button button--outlined"
              onClick={() => cancelChanges()}
            />
            <Button
              text="Save changes"
              className="button button--primary editProfile__save"
              type="submit"
            />
          </div>
        </form>
      </div>
      <AvatarChange
        isOpen={showModal}
        onClose={handleCloseModal}
        imageLoaded={imageLoaded}
        setImageLoaded={handleImageLoaded}
        onSave={handleSaveAvatar}
      />
      <ModalWindow
        modalWidth={378}
        textWidth={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={onCloseModal}
        header={modal.header}
        text={modal.text}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return { user: state.auth.user, avatar: authSelectors.getAvatar(state) }
}

export default connect(mapStateToProps, {
  getAvatar: authOperations.getUserAvatar,
  getUser: authOperations.getCurrentUser,
})(EditProfile)
