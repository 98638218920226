import React, { useState } from "react";
import ReactPlayer from "react-player";
import playIcon from "../../assets/img/play-circle.svg";
import pauseIcon from "../../assets/img/pause-circle.svg";
import audioImage from "../../assets/img/audio.png";

export default function Audio({ file, autoPlay = false, recommended }) {
  const [clicked, setClicked] = useState(autoPlay);
  const handleClick = () => {
    clicked ? setClicked(false) : setClicked(true);
  };
  return (
    <div
      className={`preview preview__audio ${
        recommended && "preview--recommended"
      }`}
      onClick={handleClick}
    >
      <ReactPlayer
        url={file}
        playing={clicked}
        onEnded={() => setClicked(false)}
        playsinline
        loop={true}
      />

      <img className="chooseText__audioImage" src={audioImage} alt="audio" />
      <button className="chooseText__audioButton">
        <img src={clicked ? pauseIcon : playIcon} alt="play" />
      </button>
    </div>
  );
}
