import React from "react";
import "./additional.scss";
import "./additionalMobile.scss";

export default function TermsOfService() {
  return (
    <div className="container">
      <div className="wrap">
        <h1 className="header header--one additional__title">TERMS OF USE</h1>

        <h2 className="header header--two additional__subtitle">
          PLEASE READ THESE TERMS OF USE CAREFULLY.
        </h2>
        <p className="text text--normal additional__text">
          By accessing or otherwise using this site, you agree to be bound
          contractually by these Terms of Use.
        </p>

        <h2 className="header header--two additional__subtitle">
          TERMS OF USE AND DMCA NOTICE
        </h2>
        <p className="text text--normal additional__text">
          Effective Date: January 1, 2016
        </p>

        <p className="text text--normal additional__text">
          To review material modifications and their effective dates scroll to
          the bottom of the page.
        </p>

        <p className="text text--normal additional__text">
          1. Parties. The parties to these Terms of Use are you, and the owner
          of this firesprings.com website business, Firesprings INC
          ("Firesprings"). All references to "we", "us", "our", this "website"
          or this "site" shall be construed to mean this website business and
          Firesprings.
        </p>
        <p className="text text--normal additional__text">
          2. Use And Restrictions. Subject to these Terms of Use and our Privacy
          Policy, you may use the public areas of this site, but only for your
          own internal purposes. You agree not to access (or attempt to access)
          this site by any means other than through the interface we provide,
          unless you have been specifically allowed to do so in a separate
          agreement. You agree not to access (or attempt to access) this site
          through any automated means (including use of scripts or web
          crawlers), and you agree to comply with the instructions set out in
          any robots.txt file present on this site. You are not authorized to
          (i) resell, sublicense, transfer, assign, or distribute the site, its
          services or content; (ii) modify or make derivative works based on the
          site, its services or content; or (iii) "frame" or "mirror" the site,
          its services or content on any other server or Internet-enabled
          device. All rights not expressly granted in this Agreement are
          reserved by us and our licensors.
        </p>
        <p className="text text--normal additional__text">
          3. Modification. We reserve the right to modify these Terms of Use at
          any time, and without prior notice, by posting an amended Terms of Use
          that is always accessible through the Terms of Use link on this site's
          home page. You should scroll to the bottom of this page periodically
          to review material modifications and their effective dates. YOUR
          CONTINUED USE OF THIS SITE FOLLOWING OUR POSTING OF A MODIFICATION
          NOTICE OR NEW TERMS OF USE ON THIS SITE WILL CONSTITUTE BINDING
          ACCEPTANCE OF THE MODIFICATION OR NEW TERMS OF USE.
        </p>

        <p className="text text--normal additional__text">
          4. How We Treat Postings To This Site (Blog, Forum, or Chat Room).
        </p>
        <p className="text text--normal additional__text">
          4.1 We will not treat information that you post to areas of this site
          that are viewable by others (for example, to a blog, forum or
          chat-room) as proprietary, private, or confidential. We have no
          obligation to monitor posts to this site or to exercise any editorial
          control over such posts; however, we reserve the right to review such
          posts and to remove any material that, in our judgment, is not
          appropriate. Posting, transmitting, promoting, using, distributing or
          storing content that could subject us to any legal liability, whether
          in tort or otherwise, or that is in violation of any applicable law or
          regulation, or otherwise contrary to commonly accepted community
          standards, is prohibited, including without limitation information and
          material protected by copyright, trademark, trade secret,
          nondisclosure or confidentiality agreements, or other intellectual
          property rights, and material that violates export control laws.
        </p>

        <p className="text text--normal additional__text">
          4.2 We, in our sole discretion and without notice, reserve the right,
          but undertake no duty, to review, edit, remove or delete any material
          submitted as a comment to blog, forum or chat-room provided for
          display or placed on this site. Specifically, we reserve the right to
          delete or decline to post content that contains profanity; sexual
          content; overly graphic, disturbing or offensive material; vulgar or
          abusive language; hate speech, defamatory comments, or offensive
          language targeting any specific demographic; personal attacks of any
          kind; spam; promotions for commercial products or services.
        </p>
        <p className="text text--normal additional__text">
          4.3 By submitting a comment for posting, you agree that we are not
          responsible, and shall have no liability to you, with respect to any
          information or materials posted by others, including defamatory,
          offensive or illicit material, even material that violates this
          Agreement.
        </p>
        <p className="text text--normal additional__text">
          5. Defamation; Communications Decency Act Notice. This site is a
          provider of "interactive computer services" under the Communications
          Decency Act, 47 U.S.C. Section 230, and as such, our liability for
          defamation and other claims arising out of any postings to this site
          by third parties is limited as described therein. We are not
          responsible for content or any other information posted to this site
          by third parties. We neither warrant the accuracy of such postings or
          exercise any editorial control over such posts, nor do we assume any
          legal obligation for editorial control of content posted by third
          parties or liability in connection with such postings, including any
          responsibility or liability for investigating or verifying the
          accuracy of any content or any other information contained in such
          postings.
        </p>
        <p className="text text--normal additional__text">
          6. Monitoring. We reserve the right, but not the obligation, to
          monitor your access and use of this site without notification to you.
          We may record or log your use in a manner as set out in our Privacy
          Policy that is accessible though the Privacy Policy link on this
          site's home page.
        </p>
        <p className="text text--normal additional__text">
          7. Separate Agreements. You may acquire products, services and/or
          content from this site. We reserve the right to require that you agree
          to separate agreements as a condition of your use and/or purchase of
          such products, services and/or content.
        </p>
        <p className="text text--normal additional__text">
          8. Ownership. The material provided on this site is protected by law,
          including, but not limited to, United States copyright law and
          international treaties. The copyrights and other intellectual property
          in the content of this site is owned by us and/or others. Except for
          the limited rights granted herein, all other rights are reserved.
        </p>
        <p className="text text--normal additional__text">
          9. DMCA Notice. This site is an Internet "service provider" under the
          Digital Millennium Copyright Act, 17 U.S.C. Section 512 ("DMCA"). As
          Required by the DMCA, this site maintains specific contact information
          provided below, including an e-mail address, for notifications of
          claimed infringement regarding materials posted to this site. All
          notices should be addressed to the contact person specified below (our
          agent for notice of claimed infringement):
        </p>
        <p className="text text--normal additional__text">
          Notification of Claimed Infringement:
        </p>
        <p className="text text--normal additional__text">Firesprings INC</p>
        <p className="text text--normal additional__text">
          2679 Howard Ave Suite 138
        </p>
        <p className="text text--normal additional__text">
          Windsor, ON, N8X 3X2
        </p>
        <p className="text text--normal additional__text">Canada</p>
        <p className="text text--normal additional__text">
          Agent's Name/Email Address: Elisha Goodman/
          <a
            target="_blank"
            rel="norefferer"
            href="elisha@firesprings.com"
            className="link link--underlined"
          >
            elisha@firesprings.com
          </a>
        </p>
        <p className="text text--normal additional__text">
          You may contact our agent for notice of claimed infringement specified
          above with complaints regarding allegedly infringing posted material
          and we will investigate those complaints. If the posted material is
          believed in good faith by us to violate any applicable law, we will
          remove or disable access to any such material, and we will notify the
          posting party that the material has been blocked or removed.
        </p>
        <p className="text text--normal additional__text">
          In notifying us of alleged copyright infringement, the DMCA requires
          that you include the following information: (i) description of the
          copyrighted work that is the subject of claimed infringement; (ii)
          description of the infringing material and information sufficient to
          permit us to locate the alleged material; (iii) contact information
          for you, including your address, telephone number and/or e-mail
          address; (iv) a statement by you that you have a good faith belief
          that the material in the manner complained of is not authorized by the
          copyright owner, or its agent, or by the operation of any law; (v) a
          statement by you, signed under penalty of perjury, that the
          information in the notification is accurate and that you have the
          authority to enforce the copyrights that are claimed to be infringed;
          and (vi) a physical or electronic signature of the copyright owner or
          a person authorized to act on the copyright owner's behalf. Failure to
          include all of the above-listed information may result in the delay of
          the processing of your complaint.
        </p>
        <p className="text text--normal additional__text">
          10. Warranty Disclaimers. EXCEPT AS MAY BE PROVIDED IN ANY SEPARATE
          WRITTEN AGREEMENTS SIGNED BY THE PARTIES, THE SERVICES, CONTENT,
          AND/OR PRODUCTS ON THIS SITE ARE PROVIDED "AS-IS", AND NEITHER WE NOR
          ANY OF OUR LICENSORS MAKE ANY REPRESENTATION OR WARRANTY WITH RESPECT
          TO SUCH PRODUCTS, SERVICES, AND/OR CONTENT. EXCEPT AS MAY BE PROVIDED
          IN ANY SEPARATE WRITTEN AGREEMENT SIGNED BY THE PARTIES OR SEPARATE
          AGREEMENT ORIGINATING FROM THIS SITE, THIS SITE AND ITS LICENSORS
          SPECIFICALLY DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY LAW, ANY AND
          ALL WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THIS SITE OR PRODUCTS,
          SERVICES AND/OR CONTENT ACQUIRED FROM THIS SITE, INCLUDING BUT NOT
          LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, COMPLETENESS,
          TIMELINESS, CORRECTNESS, NON-INFRINGEMENT, OR FITNESS FOR ANY
          PARTICULAR PURPOSE. THIS SITE AND ITS LICENSORS DO NOT REPRESENT OR
          WARRANT THAT THIS SITE, ITS PRODUCTS, SERVICES, AND/OR CONTENT: (A)
          WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN
          COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B)
          WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR (C) WILL BE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS.
        </p>

        <h2 className="header header--two additional__subtitle">
          {"===>"}SPECIAL DISCLAIMERS {"<==="}
        </h2>
        <p className="text text--normal additional__text">
          1. Subjective Subject Matter Disclaimer For Medical Advice
        </p>
        <p className="text text--normal additional__text">
          WE DO NOT PROVIDE MEDICAL ADVICE FOR ANY PURPOSE, INCLUDING ANY
          MEDICAL ADVICE REGARDING ANY ASPECT OF YOUR PARTICIPATION IN A FITNESS
          OR WELLNESS PROGRAM. ALL INFORMATION PROVIDED ON THIS SITE IS FOR
          INFORMATIONAL PURPOSES ONLY.
        </p>
        <p className="text text--normal additional__text">
          2. Subjective Subject Matter Disclaimer For Single Author; No
          Obligation to Update Information.
        </p>
        <p className="text text--normal additional__text">
          The information presented herein represents the view of the author as
          of the date of publication. Because of the rate with which conditions
          change, the author reserves the right to alter and/or update his
          opinion based on the new conditions, and the author is not obligated
          to update this information.
        </p>
        <p className="text text--normal additional__text">
          Everything posted on this site are views of others and have either
          been submitted to us, or found on the Internet and published as a fair
          use. The views expressed on this site are solely those of 3rd party
          authors and do not in any way reflect our views. This site is solely a
          provider of useful articles and hereby disclaims all liability for any
          damages or injury or other harm arising from this website. We are not
          obligated to update any information attributable to 3rd party authors.
        </p>
        <p className="text text--normal additional__text">
          4. Disclaimer for Blog Posts by Others.
        </p>
        <p className="text text--normal additional__text">
          We do not independently verify, nor do we seek independent
          verification of comments and statements that may be posted by others
          in blog posts on this site regarding our website, its products or
          services. For this reason, if others post "success story" or
          "best-case" scenario testimonials (as distinguished from subjective
          opinions), you should assume that their results are NOT typical.
        </p>
        <p className="text text--normal additional__text">
          5. Disclaimer for Fasting and Other Spiritual Exercises That Might
          Affect Your Health
        </p>
        <p className="text text--normal additional__text">
          We do not guarantee that any recommendation will meet your
          requirements. Not all fasting is suitable for everyone. To reduce the
          risk of injury, consult your doctor before beginning this or any
          fasting program. . THESE DISCLAIMERS CONSTITUTE AN ESSENTIAL PART OF
          THIS AGREEMENT. NO PURCHASE OR USE OF THE ITEMS OFFERED BY THIS SITE
          IS AUTHORIZED HEREUNDER EXCEPT UNDER THESE DISCLAIMERS. IF IMPLIED
          WARRANTIES MAY NOT BE DISCLAIMED UNDER APPLICABLE LAW, THEN ANY
          IMPLIED WARRANTIES ARE LIMITED IN DURATION TO THE PERIOD REQUIRED BY
          APPLICABLE LAW. SOME STATES OR JURISDICTIONS DO NOT ALLOW LIMITATIONS
          ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY
          NOT APPLY TO YOU.
        </p>
        <p className="text text--normal additional__text">
          11. Limitation of Liability. IN NO EVENT SHALL THIS SITE AND/OR ITS
          LICENSORS BE LIABLE TO ANYONE FOR ANY DIRECT, INDIRECT, PUNITIVE,
          SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY
          TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER
          ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS
          SITE, ITS PRODUCTS, SERVICES, AND/OR CONTENT, ANY INTERRUPTION,
          INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE, EVEN IF THIS SITE
          OR OUR LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES.
        </p>
        <p className="text text--normal additional__text">
          12. Links to This Site. We grant to you a limited, revocable, and
          nonexclusive right to create a hyperlink to this site provided that
          the link does not portray us or our products or services in a false,
          misleading, derogatory, or offensive matter. You may not use any logo,
          trademark, or tradename that may be displayed on this site or other
          proprietary graphic image in the link without our prior written
          consent.
        </p>
        <p className="text text--normal additional__text">
          13. Links to Third Party Websites. We do not review or control third
          party websites that link to or from this site, and we are not
          responsible for their content, and do not represent that their content
          is accurate or appropriate. Your use of any third party site is on
          your own initiative and at your own risk, and may be subject to the
          other sites' terms of use and privacy policy.
        </p>
        <p className="text text--normal additional__text">
          14. Participation In Promotions of Advertisers. You may enter into
          correspondence with or participate in promotions of advertisers
          promoting their products, services or content on this site. Any such
          correspondence or participation, including the delivery of and the
          payment for products, services or content, are solely between you and
          each such advertiser.
        </p>
        <p className="text text--normal additional__text">
          15. Consumer Rights Information; California Civil Code Section 1789.3.
          If this site charges for services, products, content, or information,
          pricing information will be posted as part of the ordering process for
          this site. We maintain specific contact information including an
          e-mail address for notifications of complaints and for inquiries
          regarding pricing policies in accordance with California Civil Code
          Section 1789.3. All correspondence should be addressed to our agent
          for notice at the following address:
        </p>

        <p className="text text--normal additional__text">
          Notification of Consumer Rights Complaint or Pricing Inquiry:
        </p>
        <p className="text text--normal additional__text">Firesprings INC</p>
        <p className="text text--normal additional__text">
          2679 Howard Ave Suite 138
        </p>
        <p className="text text--normal additional__text">
          Windsor, ON, N8X 3X2
        </p>
        <p className="text text--normal additional__text">
          Contact:{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:elisha@firesprings.com"
            className="link link--underlined"
          >
            elisha@firesprings.com
          </a>
        </p>
        <p className="text text--normal additional__text">
          You may contact us with complaints and inquiries regarding pricing and
          we will investigate those matters and respond to the inquiries.
        </p>
        <p className="text text--normal additional__text">
          The Complaint Assistance Unit of the Division of Consumer Services of
          the Department of Consumer Affairs may be contacted in writing at 1020
          N. Street, #501, Sacramento, CA 95814, or by telephone at
          1-916-445-1254.
        </p>
        <p className="text text--normal additional__text">
          16. Arbitration. Except for actions to protect intellectual property
          rights and to enforce an arbitrator's decision hereunder, all
          disputes, controversies, or claims arising out of or relating to this
          Agreement or a breach thereof shall be submitted to and finally
          resolved by arbitration under the rules of the American Arbitration
          Association ("AAA") then in effect. There shall be one arbitrator, and
          such arbitrator shall be chosen by mutual agreement of the parties in
          accordance with AAA rules. The arbitration shall take place in
          Toronto, ON, Canada, and may be conducted by telephone or online. The
          arbitrator shall apply the laws of the Province of Ontario, Canada to
          all issues in dispute. The controversy or claim shall be arbitrated on
          an individual basis, and shall not be consolidated in any arbitration
          with any claim or controversy of any other party. The findings of the
          arbitrator shall be final and binding on the parties, and may be
          entered in any court of competent jurisdiction for enforcement.
          Enforcements of any award or judgment shall be governed by the United
          Nations Convention on the Recognition and Enforcement of Foreign
          Arbitral Awards. Should either party file an action contrary to this
          provision, the other party may recover attorney's fees and costs up to
          $1000.00.
        </p>
        <p className="text text--normal additional__text">
          17. Jurisdiction And Venue. The courts of Nearest Court in the
          Province of Ontario shall be the exclusive jurisdiction and venue for
          all legal proceedings that are not arbitrated under these Terms of
          Use.
        </p>

        <p className="text text--normal additional__text">
          18. Controlling Law. This Agreement shall be construed under the laws
          of the Province of Ontario, Canada, excluding rules regarding
          conflicts of law. The application the United Nations Convention of
          Contracts for the International Sale of Goods is expressly excluded.
        </p>
        <p className="text text--normal additional__text">
          19. Onward Transfer of Personal Information Outside Your Country of
          Residence. Any personal information which we may collect on this site
          may be stored and processed in our servers located in the United
          States or in any other country in which we, or our affiliates,
          subsidiaries, or agents maintain facilities. You consent to any such
          transfer of personal information outside your country of residence to
          any such location.
        </p>

        <p className="text text--normal additional__text">
          20. Severability. If any provision of these terms is declared invalid
          or unenforceable, such provision shall be deemed modified to the
          extent necessary and possible to render it valid and enforceable. In
          any event, the unenforceability or invalidity of any provision shall
          not affect any other provision of these terms, and these terms shall
          continue in full force and effect, and be construed and enforced, as
          if such provision had not been included, or had been modified as above
          provided, as the case may be.
        </p>
        <p className="text text--normal additional__text">
          21. Force Majeure. We shall not be liable for damages for any delay or
          failure of delivery arising out of causes beyond our reasonable
          control and without our fault or negligence, including, but not
          limited to, Acts of God, acts of civil or military authority, fires,
          riots, wars, embargoes, Internet disruptions, hacker attacks, or
          communications failures.
        </p>

        <p className="text text--normal additional__text">
          22. Privacy. Please review this site's Privacy Policy which also
          governs your visit to this site. Our Privacy Policy is always
          accessible on our site's home page.
        </p>
        <p className="text text--normal additional__text">--</p>
        <p className="text text--normal additional__text">
          {">>"}Material Modifications{"<<"} Since January 1, 2016: none.
        </p>
        <p className="text text--normal additional__text">
          Notice: This document is Copyright © Chip Cooper of the law firm of
          Jones & Haley, P.C., and licensed for use by the owner of this website
          under distribution rights granted to FTCGuardian.com. All rights
          reserved worldwide. No part of this document may be copied, reprinted,
          reproduced, or transmitted in any form or by any means without the
          prior written permission of the copyright owner.
        </p>
      </div>
    </div>
  );
}
