import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import useInfiniteScroll from "../utils/useInfiniteScroll";
import { api_url } from "../config";
import { connect } from "react-redux";
import authSelectors from "../redux/auth/authSelectors";
import { Button, PostsList } from "../components";
import routes from "../routes";
import postSelectors from "../redux/post/postSelectors";
import folderIcon from "../assets/img/folder.svg";

function Feed({ userId }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPagePosts, setPrevPagePosts] = useState(null);
  const [postsPage, setPostsPage] = useState(1);
  const [category, setCategory] = useState({ total: "", name: "" });

  const { id } = useParams();
  const history = useHistory();

  // const { categoryName, categoryCount } = useLocation().state;

  useEffect(() => {
    setPosts([]);
    setNextPageUrl(null);
    setPostsPage(1);
    setPrevPagePosts(null)
  }, [id]);

  const loadMorePosts = () => {
    if (nextPageUrl && !loading) {
      setPostsPage((prevState) => prevState + 1);
    }
  };

  const [lastElementRef] = useInfiniteScroll(
    nextPageUrl ? loadMorePosts : () => {},
    loading
  );

  const token = axios.defaults.headers.common.Authorization;

  useEffect(() => {
    if (token && postsPage !== prevPagePosts) {
      setLoading(true);
      axios
        .get(`${api_url}/feed/album/by-category/${id}?page=${postsPage}`)
        .then((response) => {
          setLoading(true);
          if (posts && nextPageUrl) {
            setPosts((prevState) => [
              ...prevState,
              ...response.data.albums.data,
            ]);
          } else {
            setPosts(response.data.albums.data);
            setCategory({
              total: response.data.albums.total,
              name: response.data.category.name,
            });
          }
          setLoading(false);
          setPrevPagePosts(postsPage);
          setNextPageUrl(response.data.albums.next_page_url);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [token, nextPageUrl, id, postsPage]);
  return (
    <div className="container home__container">
      <div className="wrap home__wrap" style={{ justifyContent: "flex-start" }}>
        {category.name && (
          <div className="hint__category feed__category">
            <div className="hint__category-img feed__category-img">
              <img src={folderIcon} alt="folder" />
            </div>
            <div className="hint__category-info">
              <span className="hint__category-name header header--two ">
                {category.name}
              </span>
              <span className="hint__category-posts text text--small">
                {category.total} posts
              </span>
            </div>
          </div>
        )}
        <h3 className="header header--four feed__header">Recent Posts</h3>
        {posts.length > 0 && <PostsList posts={posts} userId={userId} />}
        {posts.length === 0 && !loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3 style={{ marginTop: "40px" }} className="header header--three">
              No posts in this category
            </h3>
            <Button
              text="Create"
              className="button button--primary auth__button"
              onClick={() => {
                history.push({ pathname: routes.chooseCategory });
              }}
            />
          </div>
        )}
        <ClipLoader color={"#050138"} loading={loading} size={75} />
        <span ref={lastElementRef}></span>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: authSelectors.getId(state),
  };
};

export default connect(mapStateToProps, {
  addCategory: postSelectors.addCategory,
})(Feed);
