import React from 'react'
// import defaultImage from '../../assets/img/default-testimony.png'

export default function Image({
  file,
  style = 'normal',
  defaultImageClass,
  recommended,
  titlePreview,
}) {
  return (
    <div
      className={`preview preview__image ${
        recommended && 'preview--recommended'
      } ${style}`}
      style={{
        backgroundImage: `url(${file})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        borderRadius: 20,
        wordBreak: 'break-word',
      }}
    >
      {titlePreview && <p style={{ padding: '0 10px' }}>{titlePreview}</p>}
      {/* {titlePreview ? (
        <p style={{padding: '0 10px'}}>{titlePreview}</p>
      ) : (
        <img
          src={file || defaultImage}
          alt='chosen'
          className='chooseFilter__image'
        />
      )} */}
    </div>
  )
}
