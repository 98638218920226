import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { Button, ModalWindow } from '../../components'
import routes from '../../routes'
import forgotPasswrodSchema from '../../validationSchemas/forgotPasswordSchema'
import axios from 'axios'
import { api_url } from '../../config'

export default function ForgotPassword() {
  const [submited, setSubmited] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [emailError, setEmailError] = useState('')

  const onCloseModal = () => {
    setModalIsOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswrodSchema,
    onSubmit: (values) => {
      setSubmited(true)
      const formdata = new FormData()
      formdata.append('email', values.email)
      axios
        .post(`${api_url}/auth/password-reset`, formdata)
        .then((response) => {
          setModalIsOpen(true)
        })
        .catch((err) => {
          setEmailError('Unexisting email')
        })
    },
  })

  const formError = (error, value) => {
    if ((error && value) || (error && submited) || emailError) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='container auth'>
      <div className='wrap'>
        <h1 className='header header--one auth__header--one'>
          Trouble logging in?
        </h1>
        <p className='subtitle forgotPassword__subtitle'>
          Enter your email and we’ll send you a link to get back into your
          account.
        </p>
        <form
          className='form auth__form'
          onSubmit={(values) => {
            formik.handleSubmit(values)
            setSubmited(true)
          }}
        >
          <div className='form__group'>
            <input
              className={`formInput ${
                formError(formik.errors.email, formik.values.email) &&
                'inputError'
              }`}
              type='email'
              placeholder='Email'
              value={formik.values.email}
              name='email'
              onChange={(e) => {
                formik.handleChange(e)
                setEmailError('')
              }}
              onBlur={formik.handleBlur}
            />
            <p className='formNotification'>
              {formError(formik.errors.email, formik.values.email)
                ? formik.errors.email || emailError
                : 'Enter the email you used while creating an account.'}
            </p>
          </div>

          <Button
            type='submit'
            className='button button--primary forgotPassword__button'
            text='Send login link'
          />

          <Link to={routes.logIn} className='link link--underlined logIn__link'>
            Go back to login screen
          </Link>
        </form>
        <h2 className='header header--two auth__header--two'>
          Don’t have an account yet?
        </h2>

        <Link to={routes.register} className='button button--outlined'>
          Create an Account
        </Link>
      </div>
      <ModalWindow
        textWidth={295}
        modalWidth={378}
        modalIsOpen={modalIsOpen}
        onCloseModal={onCloseModal}
        header='Link Sent'
        text={`A password reset link is sent to ${formik.values.email}. Follow the instructions in the email to reset your password.`}
      />
    </div>
  )
}
