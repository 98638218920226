import axios from 'axios'
import { api_url } from '../../config'
import postActions from './postActions'
import { Auth } from 'aws-amplify'

export const setToken = async () => {
  const session = await Auth.currentSession()
  // token.set(session.getIdToken().getJwtToken())
  return `Bearer ${session.getIdToken().getJwtToken()}`
}

const addText = (text) => (dispatch) => {
  dispatch(postActions.addText(text))
}

const addTitle = (title) => (dispatch) => {
  dispatch(postActions.addTitle(title))
}

const addFile = (file) => (dispatch) => {
  dispatch(postActions.addFile(file))
}

const addCategory = (category) => (dispatch) => {
  dispatch(postActions.addCategory(category))
}

const addStyle = (style) => (dispatch) => {
  dispatch(postActions.addStyle(style))
}

const addDefaultBackground = (background_id) => (dispatch) => {
  dispatch(postActions.addDefaultBackground(background_id))
}

const clearState = () => (dispatch) => {
  dispatch(postActions.clearState())
}

const clearTitle = () => (dispatch) => {
  dispatch(postActions.clearTitle())
}
const clearBackground = () => (dispatch) => {
  dispatch(postActions.clearBackground())
}

const getStopWords = () => (dispatch) => {
  axios
    .get(`${api_url}/stop-words`)
    .then((response) => dispatch(postActions.getStopWords(response.data)))
    .catch((err) => console.log(err))
}

const getBackgroundsList = () => async (dispatch) => {
  const token = await setToken()
  axios
    .get(`${api_url}/background`, {
      headers: { Authorization: token },
    })
    .then((response) =>
      dispatch(postActions.backgroundsList(response?.data?.data)),
    )
    .catch((err) => console.log(err))
}

const setStream = (stream) => (dispatch) => {
  dispatch(postActions.stream(stream))
}

const saveStream = (streamUrl) => (dispatch) => {
  dispatch(postActions.saveStream(streamUrl))
}

const postOperations = {
  addText,
  addFile,
  addCategory,
  addStyle,
  clearState,
  getStopWords,
  addTitle,
  clearTitle,
  getBackgroundsList,
  addDefaultBackground,
  clearBackground,
  setStream,
  saveStream,
}

export default postOperations
