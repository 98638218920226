import { combineReducers } from 'redux'
import { createReducer } from '@reduxjs/toolkit'
import authActions from './authActions'

const initialUserState = {
  email: null,
  userName: null,
  fullName: null,
  error: null,
  token: null,
  confirmed: null,
  rememberMe: null,
  bio: null,
  id: null,
  prevUrl: null,
  status: null,
}

const user = createReducer(initialUserState, {
  [authActions.rememberMeLogInRequest]: () => null,
  [authActions.registerSuccess]: (_, { payload }) => ({
    email: payload.user.email,
    userName: payload.user.userName,
    fullName: payload.user.fullName,
  }),

  [authActions.getCurrentUserSuccess]: (_, { payload }) => ({
    email: payload.user.email,
    userName: payload.user.userName,
    fullName: payload.user.fullName,
    bio: payload.user.bio,
    id: payload.user.id,
    status: payload.user.status,
    created_at: payload.user.created_at,
  }),
  [authActions.rememberMeLogInSuccess]: (_, { payload }) => {
    return {
      confirmed: payload.confirmed,
      token: payload.token,
      // rememberMe: payload.rememberMe,
    }
  },

  [authActions.logOutSuccess]: () => initialUserState,
})

const token = createReducer(null, {
  // [authActions.loginSuccess]: (_, { payload }) => payload,
  [authActions.rememberMeLogInRequest]: () => null,
  [authActions.rememberMeLogInSuccess]: (_, { payload }) => payload,
  [authActions.logOutSuccess]: () => null,
})

const error = createReducer(null, {
  [authActions.rememberMeLogInRequest]: () => null,
  [authActions.registerSuccess]: () => null,
  [authActions.rememberMeLogInSuccess]: () => null,
  [authActions.registerError]: (_, { payload }) => payload,
  [authActions.getAvatarError]: (_, { payload }) => payload,
  [authActions.rememberMeLogInError]: (_, { payload }) => payload,
  [authActions.getCurrentUserError]: (_, { payload }) => payload,
  [authActions.logOutError]: (_, { payload }) => payload,
  [authActions.logOutSuccess]: () => null,
  [authActions.clearErrors]: () => null,
})

const prevUrl = createReducer(null, {
  [authActions.getPrevUrl]: (_, { payload }) => payload,
  [authActions.clearPrevUrl]: () => null,
})

const avatar = createReducer(null, {
  [authActions.getAvatarSuccess]: (_, { payload }) => payload,
})

export default combineReducers({
  user,
  token,
  error,
  avatar,
  prevUrl,
})
