import React, { useEffect, useRef, useState } from 'react'
import { store, view } from '@risingstack/react-easy-state'
import KinesisVideo from 'aws-sdk/clients/kinesisvideo'
// import KinesisVideoArchivedMedia from 'aws-sdk/clients/kinesisvideoarchivedmedia'
import KinesisVideoSignalingChannels from 'aws-sdk/clients/kinesisvideosignalingchannels'
// import AWS from 'aws-sdk'
import { SignalingClient } from 'amazon-kinesis-video-streams-webrtc'
import micOn from '../../assets/img/micStream.png'
import micOff from '../../assets/img/micOff.png'
import endStreamButton from '../../assets/img/endStream.png'
import lifeSymbol from '../../assets/img/lifeSymbol.png'
import routes from '../../routes'
import axios from 'axios'
import { ModalWindow } from '../../components'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ModalWithButtons } from '../../components'
import { setToken } from '../../getToken'
import { api_url } from '../../config'
const OPTIONS = {
  TRAVERSAL: {
    STUN_TURN: 'stunTurn',
    TURN_ONLY: 'turnOnly',
    DISABLED: 'disabled',
  },
  ROLE: {
    MASTER: 'MASTER',
    VIEWER: 'VIEWER',
  },
  RESOLUTION: {
    MOBILE: 'mobilescreen',
    WIDESCREEN: 'widescreen',
    FULLSCREEN: 'fullscreen',
  },
}

const state = store({
  accessKey: 'AKIAV4M3Y5ZMYEM74M7Q',
  secretAccessKey: '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',
  sessionToken: '',
  region: 'us-east-1',
  role: OPTIONS.ROLE.MASTER,
  channelName: '',
  clientId: getRandomClientId(),
  endpoint: null,
  sendVideo: true,
  sendAudio: true,
  openDataChannel: true,
  resolution: OPTIONS.RESOLUTION.WIDESCREEN,
  natTraversal: OPTIONS.TRAVERSAL.STUN_TURN,
  useTrickleICE: true,
  messageToSend: '',
  playerIsStarted: false,

  signalingClient: null,
  localStream: null,
  localView: null,
  remoteView: null,
  dataChannel: null,
  peerConnectionStatsInterval: null,
  // peerConnectionByClientId: {},
  dataChannelByClientId: [],
  receivedMessages: '',
  peerClose: null,
  iceCandidateByClientId: {},
})

//------------------------------------------------------------------------------
const Master = view(
  ({
    streamId,
    channelARNMaster,
    peerConnectionByClientId,
    setSaveStream,
    endStream,
    setIslocalStream,
    setIsPeerClose,
    setTimer,
    timer,
  }) => {
    // const [timer, setTimer] = React.useState(0)
    state.localView = useRef(null)
    state.remoteView = useRef(null)
    useEffect(() => {
      startPlayer(
        channelARNMaster,
        peerConnectionByClientId,
        streamId,
        setSaveStream,
        setIslocalStream,
        setIsPeerClose,
      )
    }, [])

    useEffect(() => {
      let timerId
      setTimer(60 * 5)
      timerId = setInterval(() => {
        if (state.localStream) {
          setTimer((countDown) => countDown - 1)
        }
      }, 1000)
      return () => clearInterval(timerId)
    }, [])
    useEffect(() => {
      if (timer < 0) {
        setTimer(0)
      }
    }, [timer])
    return (
      <div>
        {state.playerIsStarted ? (
          <VideoPlayers
            streamId={streamId}
            timer={timer}
            endStream={endStream}
            peerConnectionByClientId={peerConnectionByClientId}
            setIslocalStream={setIslocalStream}
            setIsPeerClose={setIsPeerClose}
          />
        ) : null}
      </div>
    )
  },
)

const VideoPlayers = view(
  ({ streamId, timer, endStream, setIslocalStream, setIsPeerClose, peerConnectionByClientId }) => {
    const history = useHistory()
    const { params } = useRouteMatch()
    const [audio, setAudio] = useState(true)
    const [finishModalIsOpen, setFinishModalIsOpen] = useState(false)
    const [isModalWindow, setIsModalWindow] = useState(false)
    const [refreshPageModal, setRefreshPageModal] = useState(false)
    const seconds = String(timer % 60).padStart(2, 0)
    const minutes = String(Math.floor(timer / 60)).padStart(2, 0)
    const handleDeleteModalClose = () => setFinishModalIsOpen(false)
    const handleEndStream = (e) => {
      window.event.preventDefault()
      setFinishModalIsOpen(true)
    }

    const handleFinishStream = () => {
      setFinishModalIsOpen(false)
      setIsModalWindow(false)
    
   
      axios.delete(`${api_url}/live/end/${streamId}`).then(() => {
       
Object.keys(peerConnectionByClientId).forEach((clientId) => {
    peerConnectionByClientId[clientId].close()
  })
  peerConnectionByClientId = []
        const tracks = state.localStream.getTracks()
        tracks.forEach(function (track) {
          track.stop()
        })
        state.peerClose.close()
        endStream({})
        history.push(routes.stream + `/${streamId}/finish`)
      })
    }
    const handleRefheshModal = () => {
      history.push('/')
      history.go(0)
    }

    const handleAudio = () => {
      setAudio(!audio)
      state.localStream.getAudioTracks()[0].enabled = !audio
    }
    useEffect(() => {
      if (timer === 0) {
        if (state.localStream) {
          state.localStream.getAudioTracks()[0].enabled = false
          state.localStream.getVideoTracks()[0].enabled = false
          setIsModalWindow(true)
          
          if (state.peerClose) {
            const tracks = state.localStream.getTracks()
            tracks.forEach(function (track) {
              track.stop()
            })
            state.peerClose.close()
            Object.keys(peerConnectionByClientId).forEach((clientId) => {
    peerConnectionByClientId[clientId].close()
  })
  peerConnectionByClientId = []
          }
        }
      }
    }, [timer])
    useEffect(() => {
      if (isModalWindow) {
        if (state.peerClose) {
          const tracks = state.localStream.getTracks()
          tracks.forEach(function (track) {
            track.stop()
            state.peerClose.close()
          })
          Object.keys(peerConnectionByClientId).forEach((clientId) => {
    peerConnectionByClientId[clientId].close()
  })
  peerConnectionByClientId = []
        }
        let streamOut = setTimeout(() => {
          setIsModalWindow(false)
          axios
            .delete(`${api_url}/live/end/${streamId}`)
            .then(() => {
              endStream({})
              history.push(routes.stream + `/${streamId}/finish`)
            })
            .catch(() => {
              endStream({})
              history.push(routes.stream + `/${streamId}/finish`)
            })
        }, 20000)
        return () => {
          clearTimeout(streamOut)
        }
      }
    }, [isModalWindow])
    useEffect(() => {
      const testFect = async () => {
        const token = await setToken()
        if (!state.channelName) {
          axios
            .delete(`${api_url}/live/end/${params.id}`, {
              headers: { Authorization: token },
            })
            .then(() => {
              setRefreshPageModal(true)
            })
        }
      }
      testFect()
    }, [])
    useEffect(() => {
      window.addEventListener(
        'keydown',
        function (event) {
          if (event.key == 'F5') {
            event.preventDefault()
            setFinishModalIsOpen(true)
          }
        },
        true,
      )
    }, [])

    return (
      <div style={{ position: 'relative', width: 'max-content' }}>
        <video className="videoMaster" ref={state.localView} autoPlay muted />
        {state.localStream ? (
          <>
            <div className="videoControlsTopWrap">
              {timer && (
                <div className="videoControlsTop">
                  <img src={lifeSymbol} alt="attention-symbol" />
                  Live ends in:
                  <div style={{ color: '#FF6262' }}>
                    {minutes}:{seconds}
                  </div>
                </div>
              )}
            </div>
            <div className="videoControlsBottom">
              <img
                onClick={handleAudio}
                style={{ cursor: 'pointer' }}
                src={audio ? micOn : micOff}
                alt="microphone-control"
              />
              <img
                onClick={handleEndStream}
                id="closeButton"
                style={{ cursor: 'pointer' }}
                src={endStreamButton}
                alt="end-stream"
              />
            </div>
          </>
        ) : (
          ''
        )}
        {finishModalIsOpen && (
          <ModalWithButtons
            modalIsOpen={finishModalIsOpen}
            onCloseModal={handleDeleteModalClose}
            header="Finish stream"
            text="Are you sure you want to finish this stream?"
            onConfirm={handleFinishStream}
          />
        )}

        {isModalWindow && (
          <ModalWindow
            modalIsOpen={isModalWindow}
            onCloseModal={handleFinishStream}
            modalWidth={380}
            textWidth={295}
            buttonText="Go to finish stream page"
            text="You reached the time limit and the stream was finished automatically"
          />
        )}

        {refreshPageModal && (
          <ModalWindow
            modalIsOpen={refreshPageModal}
            onCloseModal={handleRefheshModal}
            modalWidth={380}
            textWidth={295}
            buttonText="Go to finish stream page"
            text="It looks like you reloaded the page and ended your stream."
          />
        )}
      </div>
    )
  },
)

function startPlayer(
  channelARNMaster,
  peerConnectionByClientId,
  streamId,
  setSaveStream,
  setIslocalStream,
  setIsPeerClose,
) {
  state.playerIsStarted = true
  startPlayerForMaster(
    channelARNMaster,
    peerConnectionByClientId,
    streamId,
    setSaveStream,
    setIslocalStream,
    setIsPeerClose,
  )
}

async function startPlayerForMaster(
  channelARNMaster,
  peerConnectionByClientId,
  streamId,
  setSaveStream,
  setIslocalStream,
  setIsPeerClose,
) {
  const kinesisVideoClient = new KinesisVideo({
    region: state.region,
    endpoint: state.endpoint || null,
    correctClockSkew: true,
    accessKeyId: state.accessKey,
    secretAccessKey: state.secretAccessKey,
    sessionToken: state.sessionToken || null,
  })

  const describeSignalingChannelResponse = await kinesisVideoClient
    .describeSignalingChannel({
      ChannelName: (state.channelName = channelARNMaster),
    })
    .promise()

  const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN

  const getSignalingChannelEndpointResponse = await kinesisVideoClient
    .getSignalingChannelEndpoint({
      ChannelARN: channelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ['WSS', 'HTTPS'],
        Role: state.role,
      },
    })
    .promise()

  const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
    (endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint
      return endpoints
    },
    {},
  )

  state.signalingClient = new SignalingClient({
    channelARN,
    channelEndpoint: endpointsByProtocol.WSS,
    role: state.role,
    region: state.region,
    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    credentials: {
      accessKeyId: state.accessKey,
      secretAccessKey: state.secretAccessKey,
      sessionToken: state.sessionToken || null,
    },
  })

  const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels(
    {
      region: state.region,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
      accessKeyId: state.accessKey,
      secretAccessKey: state.secretAccessKey,
      sessionToken: state.sessionToken || null,
    },
  )

  const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    .getIceServerConfig({
      ChannelARN: channelARN,
    })
    .promise()

  const iceServers = []
  if (state.natTraversal === OPTIONS.TRAVERSAL.STUN_TURN) {
    iceServers.push({
      urls: `stun:stun.kinesisvideo.${state.region}.amazonaws.com:443`,
    })
  }

  if (state.natTraversal !== OPTIONS.TRAVERSAL.DISABLED) {
    getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      }),
    )
  }

  const configuration = {
    iceServers,
    iceTransportPolicy:
      state.natTraversal === OPTIONS.TRAVERSAL.TURN_ONLY ? 'relay' : 'all',
  }

  const resolution =
    window.innerWidth > 1280
      ? { width: { ideal: 1024 }, height: { ideal: 768 } }
      : window.innerWidth <= 1280
      ? { width: { ideal: 1024 }, height: { ideal: 768 } }
      : window.innerWidth <= 800
      ? { width: { ideal: 640 }, height: { ideal: 360 } }
      : window.innerWidth <= 480
      ? { width: { ideal: 320 }, height: { ideal: 240 } }
      : null

  const constraints = {
    video: state.sendVideo ? resolution : false,
    audio: state.sendAudio,
  }

  if (state.sendVideo || state.sendAudio) {
    try {
      state.localStream = await navigator.mediaDevices.getUserMedia(constraints)

      state.localView.current.srcObject = state.localStream
    } catch (e) {
      console.error('[MASTER] Could not find webcam')
    }
  }

  state.signalingClient.on('open', async () => {
    console.log('[MASTER] Connected to signaling service')
    axios
      .post(`${api_url}/live/record/${streamId}`, {
        channelName: state.channelName,
      })
      .then((res) => {
        setSaveStream(res.data.record_url)
      })
  })

  state.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
    console.log(
      '[MASTER] Received SDP offer remoteClientId from client: ' +
        remoteClientId,
    )
    console.log('[MASTER] Received SDP offer from client: ', offer)
    const peerConnection = new RTCPeerConnection(configuration)

    peerConnection.id = remoteClientId

    peerConnectionByClientId[remoteClientId] = peerConnection

    if (state.openDataChannel) {
      state.dataChannelByClientId[
        remoteClientId
      ] = peerConnection.createDataChannel('kvsDataChannel')
      peerConnection.ondatachannel = (event) => {
        event.channel.onmessage = (message) => {
          const timestamp = new Date().toISOString()
          const loggedMessage = `${timestamp} - from ${remoteClientId}: ${message.data}\n`
          console.log(loggedMessage)
          state.receivedMessages += loggedMessage
        }
      }
    }

    if (!state.peerConnectionStatsInterval) {
      state.peerConnectionStatsInterval = setInterval(
        () => peerConnection.getStats(),
        1000,
      )
    }

    peerConnection.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        console.log(
          '[MASTER] Generated ICE candidate for client: ' + remoteClientId,
        )
        if (state.useTrickleICE) {
          console.log(
            '[MASTER] Sending ICE candidate to client: ' + remoteClientId,
          )
          state.signalingClient.sendIceCandidate(candidate, remoteClientId)
        }
      } else {
        console.log(
          '[MASTER] All ICE candidates have been generated for client: ' +
            remoteClientId,
        )
        if (!state.useTrickleICE) {
          state.signalingClient.sendSdpAnswer(
            peerConnection.localDescription,
            remoteClientId,
          )
        }
      }
    })
    console.log('Adding peerConnection listener for "track"...')
    peerConnection.addEventListener('track', (event) => {
      console.log(
        '[MASTER] Received remote track from client: ' + remoteClientId,
      )
      if (state.remoteView.current.srcObject) {
        return
      }

      state.remoteView.current.srcObject = event.streams[0]
    })
    peerConnection.addEventListener('iceconnectionstatechange', (event) => {
      Object.keys(peerConnectionByClientId).forEach((clientId) => {
        console.log('clientId', clientId)
        if (
          peerConnectionByClientId[clientId].iceConnectionState ==
          'disconnected'
        ) {
          axios.delete(`${api_url}/live/viewers/${streamId}`, {
            data: {
              user_id: peerConnectionByClientId[clientId].id,
            },
          })
          delete peerConnectionByClientId[clientId]
        }
      })
    })
    if (state.localStream) {
      state.localStream
        .getTracks()
        .forEach((track) => peerConnection.addTrack(track, state.localStream))
    }
    if (offer.sdp == 'REQUEST_OFFER') {
      console.log('[VIEWER] Creating SDP offer')
      state.iceCandidateByClientId[remoteClientId] = {
        isSet: false,
        iceCandidates: [],
      }
      await peerConnection.setLocalDescription(
        await peerConnection.createOffer(),
      )
      console.log('[MASTER] Sending SdpOffer to client: ' + remoteClientId)
      state.signalingClient.sendSdpOffer(
        peerConnection.localDescription,
        remoteClientId,
      )
    } else {
      state.iceCandidateByClientId[remoteClientId] = {
        isSet: true,
        iceCandidates: [],
      }
    }
    state.peerClose = peerConnection
    setIsPeerClose(peerConnection)
    setIslocalStream(state.localStream)
    //     document.getElementById("closeButton").addEventListener("click", function(event) {
    //   // peerConnection.removeTrack(sender);
    //   peerConnection.close();
    // }, false);
    if (offer.sdp !== 'REQUEST_OFFER') {
      await peerConnection.setRemoteDescription(offer)
    }
    // console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId)
    if (offer.sdp !== 'REQUEST_OFFER') {
      await peerConnection.setLocalDescription(
        await peerConnection.createAnswer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }),
      )
    }

    if (state.useTrickleICE) {
      // console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId)
      if (offer.sdp !== 'REQUEST_OFFER') {
        state.signalingClient.sendSdpAnswer(
          peerConnection.localDescription,
          remoteClientId,
        )
      }
    }
    console.log(
      '[MASTER] Generating ICE candidates for client: ' + remoteClientId,
    )
  })

  state.signalingClient.on('sdpAnswer', async (answer, remoteClientId) => {
    // Add the SDP answer to the peer connection
    console.log('Received SDP answer', answer)
    console.log('Received SDP answer remoteClientId', remoteClientId)
    const peerConnection = peerConnectionByClientId[remoteClientId]
    await peerConnection.setRemoteDescription(answer)
    state.iceCandidateByClientId[remoteClientId].isSet = true
    state.iceCandidateByClientId[remoteClientId].iceCandidates.forEach(
      (candidate) => {
        peerConnection.addIceCandidate(candidate)
      },
    )
  })

  state.signalingClient.on(
    'iceCandidate',
    async (candidate, remoteClientId) => {
      // const peerConnection = peerConnectionByClientId[remoteClientId]
      // peerConnection.addIceCandidate(candidate)
      if (state.iceCandidateByClientId[remoteClientId].isSet == false) {
        state.iceCandidateByClientId[remoteClientId].iceCandidates.push(
          candidate,
        )
      } else {
        const peerConnection = peerConnectionByClientId[remoteClientId]
        peerConnection.addIceCandidate(candidate)
      }
    },
  )

  state.signalingClient.on('close', () => {
    console.log('[MASTER] Disconnected from signaling channel')
  })

  state.signalingClient.on('error', () => {
    console.error('[MASTER] Signaling client error')
  })

  state.signalingClient.open()
}

function stopPlayer() {
  state.playerIsStarted = false
  stopPlayerForMaster()
}

//------------------------------------------------------------------------------
function stopPlayerForMaster() {
  if (state.signalingClient) {
    state.signalingClient.close()
    state.signalingClient = null
  }

  Object.keys(state.peerConnectionByClientId).forEach((clientId) => {
    state.peerConnectionByClientId[clientId].close()
  })
  state.peerConnectionByClientId = []

  if (state.localStream) {
    state.localStream.getTracks().forEach((track) => track.stop())
    state.localStream = null
  }

  if (state.peerConnectionStatsInterval) {
    clearInterval(state.peerConnectionStatsInterval)
    state.peerConnectionStatsInterval = null
  }

  if (state.localView) {
    state.localView.current.srcObject = null
  }

  if (state.remoteView) {
    state.remoteView.current.srcObject = null
  }

  if (state.dataChannelByClientId) {
    state.dataChannelByClientId = {}
  }
}

function getRandomClientId() {
  return Math.random().toString(36).substring(2).toUpperCase()
}

export default Master
