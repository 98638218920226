import React, { useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import './index.scss'
import './mobile.scss'
import { Switch, Route } from 'react-router-dom'
import {
  EditProfile,
  ForgotPassword,
  LogIn,
  Register,
  Home,
  ConfirmEmail,
  PasswordReset,
  AboutUs,
  TermsOfService,
  PrivacyPolicy,
  Faq,
  ChooseCategory,
  ChooseFile,
  ChooseFilter,
  ChooseText,
  VerifyNewEmail,
  SinglePost,
  NotFound,
  MyProfile,
  UserProfile,
  Feed,
  Stream,
  FinishStream,
} from './views'
import {
  Header,
  Footer,
  PublicRoute,
  PrivateRoute,
  ActiveUserRoute,
  AdvertisementRedirect,
} from './components'
// import { Link } from "react-router-dom";
import routes from './routes'
import authOperations from './redux/auth/authOperations'
import postOperations from './redux/post/postOperations'
import { connect } from 'react-redux'
import authSelectors from './redux/auth/authSelectors'
import logo from './assets/img/logo.png'
import logoMedium from './assets/img/logo-768.png'
import GoogleAnalytics from './GoogleAnalytics'
import ScrollToTop from './components/ScrollTop'
import MaintenanceScreen from './components/MaintenanceScreen/MaintenanceScreen'

function App({ getUser, getAvatar, id, getStopWords, getBackgroundsList }) {
  useEffect(() => {
    document.getElementById('root').style.minHeight =
      window.screen.height + 'px'
  })

  useEffect(() => {
    getUser()
    getBackgroundsList()
    // eslint-disable-next-line
  }, [getUser, id])

  useEffect(() => {
    if (id) {
      getAvatar()
      getStopWords()
    }
  }, [getAvatar, getStopWords, id, getBackgroundsList])

  return (
    <>
      {/* <div className={`navbar ${'navbar--dark'}`}>
        <div className="container navbar__container">
          <picture>
            <source media="(max-width:576px)" srcSet={logo} />
            <source media="(max-width:768px)" srcSet={logoMedium} />
            <img src={logo} alt="logo" />
          </picture>
        </div>
      </div>
      <MaintenanceScreen /> */}
      <Header />
      <MetaTags>
        <meta name="description" content="Share your testimonies" />
        <meta property="og:title" content="Testigram" />
        <meta property="og:image" content={logo} />
      </MetaTags>
      <ScrollToTop />
      <Switch>
        <Route exact path={routes.home} component={Home} />
        <Route exact path={routes.faq} component={Faq} />
        <Route exact path={routes.aboutUs} component={AboutUs} />
        <Route exact path={routes.termsOfService} component={TermsOfService} />
        <Route exact path={routes.privacyPolicy} component={PrivacyPolicy} />
        <PublicRoute
          exact
          path={routes.logIn}
          component={LogIn}
          redirectRoute={routes.home}
        />
        <PublicRoute
          exact
          path={routes.register}
          component={Register}
          redirectRoute={routes.logIn}
        />
        <PublicRoute
          exact
          path={routes.forgotPassword}
          component={ForgotPassword}
          redirectRoute={routes.home}
        />
        <PrivateRoute
          exact
          path={routes.editProfile}
          component={EditProfile}
          redirectRoute={routes.logIn}
        />

        <PrivateRoute
          exact
          path={routes.userProfile + '/:id'}
          component={UserProfile}
          redirectRoute={routes.home}
        />
        <PrivateRoute
          exact
          path={routes.myProfile}
          component={MyProfile}
          redirectRoute={routes.home}
        />
        <PrivateRoute
          exact
          path={routes.myProfilePinned}
          component={MyProfile}
          redirectRoute={routes.logIn}
        />
        <PublicRoute
          exact
          path={routes.confirmEmail + '/:token'}
          component={ConfirmEmail}
          redirectRoute={routes.home}
        />
        <PublicRoute
          path={routes.passwordReset + '/:token'}
          component={PasswordReset}
          redirectRoute={routes.home}
        />
        <Route
          exact
          path={routes.verifyNewEmail + '/:token'}
          component={VerifyNewEmail}
        />
        <ActiveUserRoute
          exact
          path={routes.chooseCategory}
          component={ChooseCategory}
          redirectRoute={routes.logIn}
        />
        <ActiveUserRoute
          exact
          path={routes.chooseFile}
          component={ChooseFile}
          redirectRoute={routes.logIn}
        />
        <ActiveUserRoute
          exact
          path={routes.chooseFilter}
          component={ChooseFilter}
          redirectRoute={routes.logIn}
        />
        <ActiveUserRoute
          exact
          path={routes.chooseText}
          component={ChooseText}
          redirectRoute={routes.logIn}
        />
        <Route exact path={routes.singlePost + '/:id'} component={SinglePost} />
        <PrivateRoute
          exact
          path={routes.feed + '/:id'}
          component={Feed}
          redirectRoute={routes.logIn}
        />
        <PrivateRoute
          exact
          path={routes.stream + '/:id'}
          component={Stream}
          redirectRoute={routes.logIn}
        />
        <PrivateRoute
          exact
          path={routes.stream + '/:id/finish'}
          component={FinishStream}
          redirectRoute={routes.logIn}
        />
        <Route
          exact
          path={routes.advertisement + '/:id'}
          component={AdvertisementRedirect}
        />
        <Route component={NotFound} />
      </Switch>
      <GoogleAnalytics />
      <Footer />
      <div className="test"></div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    rememberMe: authSelectors.rememberMe(state),
    id: authSelectors.getId(state),
  }
}

export default connect(mapStateToProps, {
  getUser: authOperations.getCurrentUser,
  getAvatar: authOperations.getUserAvatar,
  logOut: authOperations.logOut,
  getStopWords: postOperations.getStopWords,
  getBackgroundsList: postOperations.getBackgroundsList,
})(App)
