import {useEffect} from "react";
import {withRouter} from "react-router-dom";

function ScrollToTop({ history }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        if (window.onunload){
            window.scrollTo(0, 0);
        }
        return () => {
            unlisten();
        }
        // eslint-disable-next-line
    }, []);
    window.scrollTo(0, 0);

    return null;
}

export default withRouter(ScrollToTop);