export default function getTextFromNode(elem) {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }

  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(getTextFromNode).join("");
  }
  return getTextFromNode(children);
}
