import React, { useRef, useState, useEffect } from 'react'
import ModalWithButtons from '../Modal/ModalWithButtons'
import ClipLoader from 'react-spinners/ClipLoader'
import axios from 'axios'

import Description from './Description'
import HeaderPost from './HeaderPost'
import TitlePost from './TitlePost'
import { returnFilePost } from '../../utils/getMediaFile'
import { CommentsList } from '../../components'
import { api_url } from '../../config'
import AddComment from '../comments/AddComment'
import { setToken } from '../../getToken'
import './post.scss'
import './postMobile.scss'

export default function Post({
  post,
  isMyProfile,
  fileUrl,
  fileClass,
  isDefaultBackground,
  defaultImageClass,
  isLoggedIn,
  likes_count,
  currentUserId,
  postUserName,
  fileType,
  stopWords,
  subscriptions,
  pinned,
  userAvatar,
}) {
  //eslint-disable-next-line
  const [likesCount, setLikesCount] = useState(likes_count)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteCommentId, setDeleteCommentId] = useState(null)
  const [postCategory, setPostCategory] = useState(null)
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [comments, setComments] = useState([]) // ! new
  const [totalComments, setTotalComments] = useState(0)
  const [pageComments, setPageComments] = useState(1)
  const [firstRenderComments, setFirstRenderComments] = useState(true)

  useEffect(() => {
    setPostCategory(post.category)
    if (firstRenderComments && post) {
      setComments(post?.first_comment || [])
      setTotalComments(post?.comments_count)
    }
    // eslint-disable-next-line
  }, [post])

  useEffect(() => {
    !firstRenderComments && post && getComments()
    // eslint-disable-next-line
  }, [pageComments])

  //* ===============   COMPONENT   =============== *//

  const getComments = async () => {
    setFirstRenderComments(false)
    setCommentsLoading(true)
    const token = await setToken()
    axios
      .get(`${api_url}/comment?album_id=${post.id}&page=${pageComments}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        if (response.data?.comments?.data?.length) {
          setComments(response.data.comments.data)
          setTotalComments(response.data.comments.total)
        } else {
          setComments([])
        }
      })
      .finally(() => setCommentsLoading(false))
  }

  // ! pin unpin comment
  const togglePinComment = async (id, pinned) => {
    const token = await setToken()
    axios
      .get(`${api_url}/comment/${!pinned ? 'pinned' : 'unpinned'}/${id}`, {
        headers: { Authorization: token },
      })
      .then(() => getComments())
      .catch((err) => console.log(err))
  }
  // ! added comment
  const onAddedComment = (newComment) => {
    setFirstRenderComments(false)
    const total = totalComments + 1
    setTotalComments(total)
    const page = Math.ceil(total / 10)
    if (pageComments !== page) {
      setPageComments(page ? page : 1)
    } else {
      getComments()
    }
  }

  // ! delete comment
  const handleDeleteComment = async () => {
    handleDeleteModalClose()
    setDeleteCommentId(null)
    const token = await setToken()
    axios
      .delete(`${api_url}/comment/remove/${deleteCommentId}`, {
        headers: { Authorization: token },
      })
      .then(() => {
        const total = totalComments - 1
        setTotalComments(total)
        const page = Number.isInteger(total / 10)
          ? pageComments - 1
          : pageComments

        if (page !== pageComments) {
          setPageComments(page ? page : 1)
        } else {
          getComments()
        }
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteModalClose = () => setDeleteModalIsOpen(false)

  const handleDeleteModalOpen = (id) => {
    setDeleteCommentId(id)
    setDeleteModalIsOpen(true)
  }

  const handleReadMore = () => setPageComments(pageComments + 1)

  const handleReadLess = () => setPageComments(pageComments - 1)

  const commentsList = () => {
    if (!totalComments) {
      return <p style={{ opacity: '0.7' }}>No comments yet</p>
    } else if (commentsLoading) {
      return <ClipLoader color={'#050138'} loading={true} size={30} />
    } else if (comments?.length) {
      const pinnedComments = comments?.filter((comment) => comment.pinned)
      const commentsList = firstRenderComments
        ? [pinnedComments?.length ? pinnedComments?.[0] : post?.last_comment]
        : comments.sort((a, b) =>
            a.pinned !== b.pinned
              ? a - b
              : new Date(a.created_at) - new Date(b.created_at),
          )
      return (
        <>
          <CommentsList
            comments={commentsList}
            currentUserId={currentUserId}
            handleOpenModal={handleDeleteModalOpen}
            isMyProfile={isMyProfile}
            togglePinComment={togglePinComment}
            stopWords={stopWords}
          />
          {!firstRenderComments && pageComments > 1 && (
            <p onClick={handleReadLess} className="link link--underlined">
              Prev
            </p>
          )}
          {!firstRenderComments &&
            Math.ceil(totalComments / 10) !== pageComments && (
              <p onClick={handleReadMore} className="link link--underlined">
                Read more
              </p>
            )}
        </>
      )
    }
  }

  const node = useRef()
  return (
    <div className="post__container">
      {postCategory ? (
        <div className={`post__wrap`} ref={node}>
          <div>
            <HeaderPost
              postId={post.id}
              userId={post.userId}
              user={post.user}
              isFollow={!!subscriptions}
              isPinned={
                !!pinned?.find((el) => el?.pivot?.album_id === post?.id)
              }
              isMyProfile={isMyProfile}
              isLoggedIn={isLoggedIn}
              postFullName={post?.fullName}
              userAvatar={userAvatar}
            />
          </div>

          <div style={{ position: 'relative' }}>
            {post.admin_recommended === 1 && (
              <p className="text text--bold post__category-admin">
                Advisor's choice
              </p>
            )}
            {returnFilePost(
              fileType,
              fileUrl,
              isDefaultBackground,
              defaultImageClass,
              post?.title,
              post.admin_recommended,
              post?.category.name,
              fileClass,
            )}
          </div>
          <TitlePost
            postId={post.id}
            postTitle={post.title}
            category={post.category.name.replace('/', ' / ')}
            categoryId={post.category.id}
            isLike={post.is_like_exists}
            likesCount={likesCount}
            changeLikeCount={setLikesCount}
          />
          <div className="comments post__comments">
            <Description
              postId={post.id}
              categoryId={postCategory.id}
              name={postUserName}
              text={post.description ? post.description : 'No description'}
              isMyProfile={isMyProfile}
              userId={post.user ? post.user.id : 0}
              stopWords={stopWords}
            />
            <hr />
            {commentsList()}
            {totalComments > 1 && firstRenderComments ? (
              <>
                <button
                  className="link text--small"
                  onClick={() => setFirstRenderComments(false)}
                >
                  See all comments ({totalComments})
                </button>
              </>
            ) : null}
            <AddComment
              postId={post?.id}
              onSuccess={onAddedComment}
              isLoggedIn={isLoggedIn}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '50px',
          }}
        >
          <ClipLoader color={'#050138'} loading={true} size={70} />
        </div>
      )}

      <ModalWithButtons
        modalIsOpen={deleteModalIsOpen}
        onCloseModal={handleDeleteModalClose}
        header="Delete Comment"
        text="Are you sure you want to delete a comment? This action cannot be reverted."
        onConfirm={handleDeleteComment}
      />
    </div>
  )
}
