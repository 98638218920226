import React from 'react'
import Avatar from 'react-avatar'

import goStream from '../../assets/img/goStream.svg'
import tomorrowStream from '../../assets/img/tomorrowStream.svg'
import closeStream from '../../assets/img/closeStream.svg'

import './streamSlider.scss'

/**
 * * status 0: GO LIVE TOMORROW
 * * status 1: GO LIVE
 * * status 2: FINISH STREAM
 * * status 3: LIVE
 */

const getStatus = (status, name) => {
  // if (status == 1)
  //   return (
  //     <p
  //       className="card-Slider-status"
  //       style={{
  //         color: window.location.href.includes('/stream')
  //           ? '#FFFFFF'
  //           : '#d3d3d3',
  //       }}
  //     >
  //       GO LIVE TOMORROW
  //     </p>
  //   )
  if (!status || status === 1)
    return (
      <p className="card-Slider-status">
        GO
        <br />
        LIVE
      </p>
    )
  if (status === 2)
    return (
      <p className="card-Slider-status" style={{ color: '#FF6262' }}>
        FINISH STREAM
      </p>
    )
  return (
    <>
      <p className="card-Slider-name">{name}</p>
      <p className="card-Slider-status card-Slider-status-live">• LIVE</p>
    </>
  )
}

const getIcon = ({ status = 99, image, name }) => {
  // if (status === 1) return <img src={tomorrowStream} alt="" />
  if (!status || status === 1) return <img src={goStream} alt="" />
  if (status === 2) return <img src={closeStream} alt="" />
  return (
    <div className="card-Slider-image">
      <Avatar
        className="card-Slider-image-avatar"
        name={name}
        round="50%"
        src={image}
        maxInitials={2}
        color="#2E8299"
      />
    </div>
  )
}

const Card = ({
  name,
  image,
  status = 99,
  onClick,
  isMyStream,
  isFinished,
}) => {
  return (
    <div
      className={
        (status === 99 || !isMyStream) &&
        window.location.href.includes('/stream') &&
        'card-Slider-activeStreamCard'
      }
      onClick={status === 2 ? isFinished : onClick}
    >
      {getIcon({ status, image, name })}
      {getStatus(status, name, isMyStream)}
    </div>
  )
}

export default Card
