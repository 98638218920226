import React from 'react'
import Modal from 'react-modal'
import './Modal.scss'
import './ModalMobile.scss'
import closeIcon from '../../assets/img/close-modal.svg'

Modal.setAppElement('#root')

export default function ModalWindow({
  modalIsOpen,
  onCloseModal,
  buttonText,
  text,
  header,
  textWidth,
  modalWidth,
}) {
  let customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingLeft: '0',
      paddingRight: '0',
      paddingBottom: '16px',
      paddingTop: '16px',
      width: modalWidth + 'px',
      maxWidth: '90%',
      border: 'none',
      borderRadius: '8px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)',
      overflow: 'hidden',
    },
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
      >
        <p className="text text--normal text--bold modal__header">{header}</p>
        <button onClick={onCloseModal} className="modal__close">
          <img src={closeIcon} alt="close" />
        </button>
        <p
          style={{ maxWidth: textWidth + 'px' }}
          className="modal__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <button className="modal__button" onClick={onCloseModal}>
          {buttonText || 'OK'}
        </button>
      </Modal>
    </div>
  )
}
