import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Button, ModalWindow, PasswordInput } from "../../components";
import routes from "../../routes";
import resetPasswordSchema from "../../validationSchemas/resetPasswordSchema";
import axios from "axios";
import { api_url } from "../../config";

export default function ResetPassword() {
  const [submited, setSubmited] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseHeader, setResponseHeader] = useState("");

  const { token } = useParams();
  const history = useHistory();

  const onCloseModal = () => {
    setModalIsOpen(false);
    history.push(routes.logIn);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      setSubmited(true);

      const formData = new FormData();
      formData.append("password", values.password);
      axios
        .post(`${api_url}/auth/password-set/${token}`, formData)
        .then((response) => {
          setResponseMessage("Password has been changed!");
          setResponseHeader("Success");
          setModalIsOpen(true);
        })
        .catch((err) => {
          setModalIsOpen(true);
          setResponseMessage("Invalid password reset link.");
          setResponseHeader("Error");
        });
    },
  });

  const formError = (error, value) => {
    if ((error && value) || (error && submited)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="container auth">
      <div className="wrap">
        <h1 className="header header--one auth__header--one">Password Reset</h1>
        <p className="subtitle forgotPassword__subtitle">
          Create a new password and confirm it using the fields below.
        </p>
        <form
          className="form auth__form"
          onSubmit={(values) => {
            formik.handleSubmit(values);
            setSubmited(true);
          }}
        >
          <div className="form__group register__form-group">
            <PasswordInput
              className={`formInput password-input ${
                formError(formik.errors.password, formik.values.password) &&
                "inputError"
              }`}
              type="password"
              placeholder="Password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <p className="formNotification">
              {formik.errors.password && formik.values.password
                ? formik.errors.password
                : "Enter the password associated with your account."}
            </p>
          </div>
          <div className="form__group register__form-group">
            <PasswordInput
              className={`formInput password-input ${
                formError(
                  formik.errors.confirmPassword,
                  formik.values.confirmPassword
                ) && "inputError"
              }`}
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            <p className="formNotification">
              {formik.errors.confirmPassword && formik.values.confirmPassword
                ? formik.errors.confirmPassword
                : "Confirm the password you entered above."}
            </p>
          </div>

          <Button
            type="submit"
            className="button button--primary forgotPassword__button"
            text="Submit"
          />

          <div className="passwordReset__group">
            <span className="text text--normal">Remember password?</span>
            <Link
              to={routes.logIn}
              className="link link--underlined passwordReset__link"
            >
              Log in
            </Link>
          </div>
        </form>
        <h2 className="header header--two auth__header--two">
          Don’t have an account yet?
        </h2>

        <Link to={routes.register} className="button button--outlined">
          Create an Account
        </Link>
      </div>
      <ModalWindow
        modalWidth={390}
        textWidth={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={onCloseModal}
        header={responseHeader}
        text={responseMessage}
      />
    </div>
  );
}
