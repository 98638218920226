import * as Yup from "yup";

import values from "./schemaValues";

const { email } = values;

const forgotPasswordSchema = Yup.object({
  email,
});

export default forgotPasswordSchema;
