import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../routes';
import './auth.scss';
import './authMobile.scss';
import { ModalWindow } from '../../components';
import authOperations from '../../redux/auth/authOperations';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import authSelectors from '../../redux/auth/authSelectors';
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator, TextField, PasswordField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_kKxGRcTBt',
    userPoolWebClientId: '7idjmdd2n3uhfep26kcror20h2',
  },
});

function Register({ clearState, onRememberLogin, getUser }) {
  const history = useHistory();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [switchFormDescription, setSwitchFormDescription] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    // setSubmited(false);
    return () => {
      clearState();
    };
  }, [clearState]);

  const onCloseModal = () => {
    setModalIsOpen(false);
    localStorage.clear();

    //!! Add login
    Auth.signIn({
      username,
      password,
    }).then((user) => {
      console.log(user);
      onRememberLogin({
        token: user.signInUserSession.idToken.jwtToken,
        confirmed: true,
      });
      getUser();
      return user;
    });
    history.push(routes.home);
  };

  const formFields = {
    signUp: {
      email: {
        type: 'email',
        order: 1,
      },
      name: {
        type: 'name',
        order: 2,
        placeholder: 'Your name (at least 8 chars)',
      },
      preferred_username: {
        type: 'preferred_username',
        order: 3,
        placeholder: 'UserName',
      },

      password: {
        type: 'password',
        order: 4,
      },
      confirm_password: {
        type: 'confirm_password',
        order: 5,
      },
    },
  };
  const services = {
    async validateCustomSignUp(formData) {
      const emailReg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const fullNameReg = /^[a-zA-Z]{2,40}( [a-zA-Z]{1,40})+$/;

      //! new regEX for full name. Optional spaces. Min 8 sybmols. Only letters allowed
      const fullNameRegNew = /^(?=.{8,40}$)[a-zA-Z]+(?: [a-zA-Z]+)*$/;

      const userNameReg = /^[A-Za-z][A-Za-z0-9_]{7,29}$/;
      const passwordReg = /(?=.{8,})/;
      if (!formData.email.match(emailReg)) {
        return {
          email: 'Invalid email address',
        };
      }
      if (!formData.name.match(fullNameRegNew)) {
        return {
          name: 'Must be a minimum of 8 characters long and may contain any standard alphabetic character',
        };
      }
      if (!formData.preferred_username.match(userNameReg)) {
        return {
          preferred_username:
            'Must be a minimum of 8 characters long and may contain any standard alphabetic character and numbers',
        };
      }
      if (!formData.password.match(passwordReg)) {
        return {
          password: 'Paswword cannot be less than 8 characters',
        };
      }
      if (formData.confirm_password !== formData.password) {
        return {
          confirm_password: 'password must match',
        };
      }
    },
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      username = username.toLowerCase();

      //=====================
      setUsername(username);
      setPassword(password);
      //=====================

      attributes.email = attributes.email.toLowerCase();
      attributes.preferred_username = attributes.preferred_username.toLowerCase();
      const authSignUp = await Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
          name: formData.attributes.name,
          preferred_username: formData.attributes.preferred_username,
        },
      });
      setSwitchFormDescription(true);
      return authSignUp;
    },

    async handleConfirmSignUp(formData) {
      let { code, username } = formData;
      username = username.toLowerCase();
      return Auth.confirmSignUp(username, code, {}).then((data) => {
        setModalIsOpen(true);
      });
    },
  };
  return (
    <div className="auth">
      <div className="container">
        <div className="wrap">
          <h1 className="header header--one register__header--one">
            {switchFormDescription ? 'Join our community...' : 'Welcome to Testigram'}
          </h1>
          <p className="subtitle register__subtitle">
            {switchFormDescription
              ? 'and get ready to share your testimonies too'
              : 'Create your free account - and start sharing your testimonies too!'}
          </p>
          <div className="auth-aws">
            <Authenticator
              formFields={formFields}
              loginMechanisms={['email']}
              initialState="signUp"
              components={{
                SignUp: {
                  FormFields() {
                    const { validationErrors } = useAuthenticator();

                    return (
                      <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        {/* <Authenticator.SignUp.FormFields /> */}
                        <TextField
                          errorMessage={validationErrors.email}
                          hasError={validationErrors.email}
                          style={
                            validationErrors.email
                              ? { borderColor: '#da100b' }
                              : { borderColor: '' }
                          }
                          name="email"
                          type="email"
                          placeholder="Email"
                          defaultValue={
                            location?.state?.email !== undefined ? location.state.email : ''
                          }
                        />
                        {/* <div> */}
                        <TextField
                          errorMessage={validationErrors.name}
                          hasError={validationErrors.name}
                          style={
                            validationErrors.name ? { borderColor: '#da100b' } : { borderColor: '' }
                          }
                          name="name"
                          type="text"
                          placeholder="Your name (at least 8 characters)"
                        />
                        {/* <p className="formNotification">
                            {!validationErrors.name && 'Enter your '}
                          </p> */}
                        {/* </div> */}
                        <TextField
                          errorMessage={validationErrors.preferred_username}
                          hasError={validationErrors.preferred_username}
                          style={
                            validationErrors.preferred_username
                              ? { borderColor: '#da100b' }
                              : { borderColor: '' }
                          }
                          name="preferred_username"
                          type="text"
                          placeholder="Username (at least 8 characters)"
                        />
                        <PasswordField
                          placeholder="Password"
                          name="password"
                          hasError={validationErrors.password}
                          errorMessage={validationErrors.password}
                          style={
                            validationErrors.password
                              ? { borderColor: '#da100b' }
                              : { borderColor: '' }
                          }
                        />
                        <PasswordField
                          placeholder="Confirm password"
                          name="confirm_password"
                          hasError={validationErrors.confirm_password}
                          errorMessage={validationErrors.confirm_password}
                          style={
                            validationErrors.confirm_password
                              ? { borderColor: '#da100b' }
                              : { borderColor: '' }
                          }
                        />

                        {/* Append & require Terms & Conditions field to sign up  */}
                        <p className="text text--normal policy__text register__policy">
                          By creating an account you agree to our
                          <Link to={routes.termsOfService} className="link link--normal">
                            {' '}
                            Terms of Service
                          </Link>{' '}
                          and
                          <Link to={routes.privacyPolicy} className="link link--normal">
                            {' '}
                            Privacy Policy
                          </Link>
                          .
                        </p>
                      </>
                    );
                  },
                },
                ConfirmSignUp: {
                  Header() {
                    return (
                      <h2 padding={`10px`} level={1}>
                        One more step...
                      </h2>
                    );
                  },
                },
              }}
              services={services}
            >
              {/* {({ signOut, user }) => (
     
    )} */}
            </Authenticator>
          </div>
          <h2 className="header header--two register__header--two">Already a member?</h2>
          <Link to={routes.logIn} className="button button--outlined register__form-button">
            Log in
          </Link>
        </div>
      </div>
      <ModalWindow
        modalWidth={378}
        textWidth={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={onCloseModal}
        header="Registration success"
        text="Now you can enjoy all the features of Testigram.ai."
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: authSelectors.getError(state),
    email: authSelectors.getEmail(state),
  };
};

export default connect(mapStateToProps, {
  onRegister: authOperations.register,
  clearState: authOperations.logOut,
  onRememberLogin: authOperations.rememberMeLogIn,
})(Register);
