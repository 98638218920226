import * as Yup from 'yup'

import values from './schemaValues'

const { fullName, userName, email, newEmail, bio } = values

const editProfileSchema = Yup.object({
  fullName,
  userName,
  email,
  newEmail,
  bio,
})

export default editProfileSchema
