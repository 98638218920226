import React, { useEffect, useState, useRef } from 'react'
import postSelectors from '../../redux/post/postSelectors'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import postOperations from '../../redux/post/postOperations'
import routes from '../../routes'
import TrackVisibility from 'react-on-screen'
import { useFormik } from 'formik'
import chooseTextSchema from '../../validationSchemas/chooseTextSchema'
import {
  Audio,
  Video,
  Image,
  EmptyModal,
  ModalWithButtons,
} from '../../components'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import 'react-h5-audio-player/lib/styles.css'
import CreatePost from './CreatePost'
import axios from 'axios'
import { api_url } from '../../config'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
function ChooseText({
  category,
  style,
  file,
  stopWords,
  title,
  clearTitle,
  background,
  getBackgroundList,
  addBackground,
  clearBackground,
}) {
  const history = useHistory()

  const [clicked, setClicked] = useState(true)
  const [categoryId, setCategoryId] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [matchedWords, setMatchedWords] = useState([])
  const [stopWordsList, setStopWordsList] = useState([])
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)

  const inputRef = useRef()

  useEffect(() => {
    setStopWordsList(stopWords)
  }, [stopWords])

  const toogleClicked = (e) => {
    clicked ? setClicked(false) : setClicked(true)
  }

  useEffect(() => {
    !category && history.push(routes.chooseCategory)
    category && category.id && setCategoryId(category.id)
    if (!background) {
      addBackground(Math.floor(Math.random() * 8) + 1)
    }
    // eslint-disable-next-line
  }, [category, history])

  const createCategory = (status) => {
    const categoryData = new FormData()
    categoryData.append('name', category.name)

    axios
      .post(`${api_url}/categories/create`, categoryData)
      .then((response) => {
        setCategoryId(response.data.category.id)
        createPost(response.data.category.id, status)
      })
      .catch((err) => console.log(err.response.data))
  }

  const createPost = (categoryId, status) => {
    setWarningModalIsOpen(false)
    setModalIsOpen(true)
    const config = {
      onUploadProgress: function (progressEvent) {
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        )
      },
    }
    const formdata = new FormData()
    file && formdata.append('file', file)
    background && !file && formdata.append('background_id', background)
    formdata.append('style', style)
    formdata.append(
      'description',
      formik.values.postText.replace(/<p>​<br><\/p>/gi, ''),
    )
    formdata.append('categoryId', categoryId)
    formdata.append('status', status)
    formdata.append('title', title)

    axios
      .post(`${api_url}/feed/upload`, formdata, config)
      .then((response) => {
        clearTitle()
        clearBackground()
        history.push({
          pathname:
            response?.data?.data?.album.status === '3'
              ? routes.myProfile
              : routes.singlePost +
                `/${
                  response?.data?.data?.album?.id || response?.data?.album?.id
                }`,
        })
      })
      .catch((err) => console.log(err.response.data))
      .finally(() => setModalIsOpen(false))
  }

  const formik = useFormik({
    initialValues: {
      postText: '',
    },
    enableReinitialize: true,
    validationSchema: chooseTextSchema,
    onSubmit: (values) => {
      let temp = 0
      stopWordsList?.forEach((element) => {
        if (values.postText.toLowerCase().match(element.name.toLowerCase())) {
          setMatchedWords((prevState) => [...prevState, element.name])
          temp++
        }
      })
      if (temp > 0 && !warningModalIsOpen) {
        setWarningModalIsOpen(true)
      } else {
        categoryId ? createPost(categoryId, 1) : createCategory(1)
      }
    },
  })

  useEffect(() => {
    setMatchedWords([])
  }, [formik.values.postText])

  return (
    <CreatePost
      confirmButtonProps={{
        text: 'Share',
        formId: 'share-form',
        type: 'submit',
      }}
    >
      {category && (
        <form className="createPost__form">
          <h2 className="header--two createPost__header">Category</h2>
          <input
            disabled
            type="text"
            className="createPost__input"
            value={category.name}
            style={{ marginBottom: '20px' }}
          />
          <h2 className="header--two createPost__header">Title</h2>
          <input
            disabled
            type="text"
            className="createPost__input"
            value={title}
          />
        </form>
      )}
      <p className="header--three chooseFilter__subtitle">
        Select a filter to apply or skip the step and proceed to the desciption.
      </p>
      {/* {!file && <Image defaultImageClass='defaultImage' />} */}
      {background && !file && (
        <Image
          file={
            getBackgroundList?.find(
              (item) => item?.background_id === background,
            )?.url
          }
          titlePreview={title}
        />
      )}
      {file && file.type.includes('image') && (
        <Image file={file.preview} style={style} />
      )}
      {file && file.type.includes('audio') && (
        <Audio
          file={file.preview}
          toogleClicked={toogleClicked}
          clicked={clicked}
        />
      )}
      {file && file.type.includes('video') && (
        <TrackVisibility offset={100}>
          <Video file={file.preview} />
        </TrackVisibility>
      )}

      <form
        enctype="multipart/form-data"
        onSubmit={formik.handleSubmit}
        id="share-form"
      >
        {warningModalIsOpen ? (
          <>
            <div
              className="chooseText__input"
              style={{
                height: inputRef.current?.scrollHeight + 'px',
                whiteSpace: 'break-spaces',
                padding: '12px 15px',
              }}
              dangerouslySetInnerHTML={{
                __html: formik.values.postText
                  .split(/(\w+)/g)
                  .map((element) => {
                    let temp = true
                    for (const elem of matchedWords) {
                      temp =
                        elem.trim().toLowerCase() ===
                        element.trim().toLowerCase()

                      if (temp) break
                    }
                    if (temp)
                      return `<span class="chooseText__input--warning">${element}</span>`
                    else return element
                  })
                  .join(''),
              }}
            >
              {/* {formik.values.postText} */}
            </div>
          </>
        ) : (
          <ReactQuill
            ref={inputRef}
            theme={'snow'}
            className={`chooseText__input ${
              formik.errors.postText && 'inputError'
            }`}
            style={{ fontSize: '16px' }}
            id="postText"
            value={formik.values.postText}
            onChange={(content, delta, source, editor) => {
              // console.log(editor.getText());
              formik.setFieldValue(
                'postText',
                content
                  .replace(/<img .*?>/g, '')
                  .replace(/<a\b[^>]*>(.*?)<\/a>/i, '')
                  .replace('<p><br></p><p><br></p><p><br></p>', '<p><br></p>'),

                true,
              )
            }}
            preserveWhitespace={true}
          />
        )}

        <div className="formNotification" style={{ marginTop: '10px' }}>
          {formik.errors.postText}
        </div>
      </form>
      <EmptyModal modalIsOpen={modalIsOpen}>
        <CircularProgressbar
          className="progress-bar"
          styles={buildStyles({
            textSize: '16px',
            pathColor: `#050138`,

            textColor: '#050138',
          })}
          strokeWidth={5}
          text={uploadProgress + ' %'}
          value={uploadProgress}
        />
      </EmptyModal>
      <ModalWithButtons
        text="Your description probably contains inappropriate language. Please edit the text, otherwise it won’t be published until Admin reviews it and makes sure the text does not violate Terms of Service."
        textWidth="295"
        modalWidth="378"
        header="Inappropriate language"
        modalIsOpen={warningModalIsOpen}
        onCloseModal={() => setWarningModalIsOpen(false)}
        onConfirm={() => {
          categoryId ? createPost(categoryId, 3) : createCategory(3)
        }}
        confirmButtonText="Send to moderation"
        cancelButtonText="Edit"
      />
    </CreatePost>
  )
}

const mapStateToProps = (state) => {
  return {
    file: postSelectors.getFile(state),
    category: postSelectors.getCategory(state),
    style: postSelectors.getStyle(state),
    stopWords: postSelectors.getStopWords(state),
    title: postSelectors.getTitle(state),
    background: postSelectors.getBackground(state),
    getBackgroundList: postSelectors.getBackgroundList(state),
  }
}

export default connect(mapStateToProps, {
  addText: postOperations.addText,
  clearTitle: postOperations.clearTitle,
  clearBackground: postOperations.clearBackground,
  addBackground: postOperations.addDefaultBackground,
})(ChooseText)
