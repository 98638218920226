import TrackVisibility from 'react-on-screen'
import { Image, Video, Audio } from '../components'
import audioImage from '../assets/img/profile-audio.png'
import videoImage from '../assets/img/profile-video.png'
import textImage from '../assets/img/default-testimony.png'

export const getMediaFile = (object) => {
  const url = !object?.url?.includes('https://')
    ? `https://${object?.url}`
    : object?.url
  const defaultRes = {
    url: textImage,
    type: 'image',
    isDefaultBackground: false,
    // customClass: '',
  }

  if (object?.type === 'image') {
    return {
      url: url || textImage,
      type: 'image',
      isDefaultBackground: object?.url?.includes('background'),
      customClass: object?.style || '',
    }
  } else if (object?.type === 'video') {
    return {
      url: url || videoImage,
      type: 'video',
      isDefaultBackground: false,
      customClass: object?.style || '',
      previewImage: object?.preview ? object?.preview : videoImage,
    }
  } else if (object?.type === 'audio') {
    return {
      url: url || audioImage,
      type: 'audio',
      isDefaultBackground: false,
      customClass: object?.style || '',
      previewImage: object?.preview ? object?.preview : audioImage,
    }
  }
  return defaultRes
}

export const returnFilePost = (
  fileType,
  fileUrl,
  isDefaultBackground = false,
  defaultClass = '',
  title = '',
  admin_recommended = false,
  name,
  style,
) => {
  if (fileType === 'image')
    return (
      <Image
        file={fileUrl}
        defaultImageClass={defaultClass}
        titlePreview={isDefaultBackground ? title || '' : ''}
        recommended={admin_recommended}
        style={style}
      />
    )
  else if (fileType === 'audio')
    return <Audio file={fileUrl} recommended={admin_recommended} />
  else if (fileType === 'video')
    return (
      <TrackVisibility offset={75}>
        <Video
          categoryName={name}
          file={fileUrl}
          recommended={admin_recommended}
        />
      </TrackVisibility>
    )
  else return null
}
