import React, { Component } from "react";
import Modal from "react-modal";
import "./Modal.scss";
import "./ModalMobile.scss";

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "30px",
    paddingTop: "16px",
    width: "400px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
    overflow: "hidden",
  },
};

export default class EmptyModal extends Component {
  render() {
    const { modalIsOpen, onCloseModal } = this.props;
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
      >
        <div className="modal__top">
          <h3 className="modal__header">Uploading file</h3>
        </div>
        <div className="modal__body emptyModal__body">
          {this.props.children}
        </div>
      </Modal>
    );
  }
}
