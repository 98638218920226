import React, { useState } from 'react'
import '../Post/post.scss'
import '../Post/postMobile.scss'
import Avatar from 'react-avatar'
import routes from '../../routes'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { view } from '@risingstack/react-easy-state'
import postSelectors from '../../redux/post/postSelectors'
import cross from '../../assets/img/close-modal.svg'
import { connect } from 'react-redux'
import axios from 'axios'
import { api_url } from '../../config'
import { Link } from 'react-router-dom'

const HeaderPost = view(
  ({
    streamId,
    stream,
    userId,
    numberOfViewers = [],
    userFullName,
    userName,
    isMyProfile,
    userAvatar,
  }) => {
    const [filterViewers, setFilterViewers] = useState([])
    const [showDropDown, setShowDropDown] = useState(false)

    const handleShowPopup = () => {
      setShowDropDown(!showDropDown)
      !showDropDown &&
        axios
          .get(`${api_url}/live/viewers/${streamId}?page=1`)
          .then((response) => {
            setFilterViewers(response.data.data)
            console.log('resViewers', response.data.data)

            console.log('numberOfViewers', numberOfViewers)
          })
    }
    // useEffect(() => {
    //   setChecked(numberOfViewers.length)
    // }, [numberOfViewers])
    // console.log('check', checked)
    //  Object.values(numberOfViewers).filter(
    //               (num) => num.canTrickleIceCandidates == true,
    //             ).length
    return (
      <div className="post__block">
        <div className="post__user__block">
          <Link
            className="post__user"
            to={
              isMyProfile ? routes.myProfile : routes.userProfile + '/' + userId
            }
          >
            <Avatar
              size={32}
              name={userFullName}
              round="50%"
              src={userAvatar}
              maxInitials={2}
              color="#2E8299"
            />
            <p className="text text--small text--bold post__user-fullName stream__typography">
              {userName}
            </p>
          </Link>
        </div>
        <div className="streamHeaderLeft">
          {stream?.isMyStream === true && (
            <div className="headerTitle" onClick={handleShowPopup}>
              {Object.values(numberOfViewers).length <= 0
                ? 0
                : Object.values(numberOfViewers).length - 1}
              <span>VIEWERS</span>
            </div>
          )}
          {showDropDown > 0 && stream?.isMyStream === true && (
            <div className="dropDownWrapper">
              <img
                className="crossDropDown"
                onClick={() => setShowDropDown(false)}
                src={cross}
                alt="close"
              />
              <div className="titleDropDown">Viewers</div>
              <div className="scrollWrap">
                <Scrollbars style={{ width: 'auto', height: 318 }}>
                  {filterViewers.length > 0 &&
                    filterViewers.map((viewers) => (
                      <div key={viewers.id} className="viewersInfo">
                        <Avatar
                          size={50}
                          name={viewers.fullName}
                          round="50%"
                          src={viewers?.media?.[0]?.url}
                          maxInitials={2}
                          color="#2E8299"
                        />
                        <div className="viewersCred">
                          <div className="usernameViewers">
                            {viewers.userName}
                          </div>
                          <div className="fullNameViewers">
                            {viewers.fullName}
                          </div>
                        </div>
                      </div>
                    ))}
                </Scrollbars>
              </div>
            </div>
          )}
          {stream?.isMyStream === true ? (
            <p>
              {' '}
              <div className="cirlceLife" /> YOU ARE NOW LIVE
            </p>
          ) : (
            <p style={{ marginLeft: 'auto' }}>
              {' '}
              <div className="cirlceLife" /> LIVE STREAM
            </p>
          )}
        </div>
      </div>
    )
  },
)
const mapStateToProps = (state) => ({
  stream: postSelectors.getStream(state),
})

export default connect(mapStateToProps)(HeaderPost)
