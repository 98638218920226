import React from "react";
import "./footer.scss";
import "./footerMobile.scss";
import { Link } from "react-router-dom";
import routes from "../../routes";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__wrap">
        <Link to={routes.aboutUs} className="text text--small">
          About Us
        </Link>
        <Link to={routes.termsOfService} className="text text--small">
          Terms of Service
        </Link>
        <Link to={routes.privacyPolicy} className="text text--small">
          Privacy Policy
        </Link>
      </div>
      <span className="text text--tiny">
        © 2021 Firesprings. All rights reserved
      </span>
    </div>
  );
}
