import React, { useState, useEffect, useRef } from 'react'
import { api_url, commentLimit } from '../../config'
import cutText from '../../utils/cutText'
import PinIcon from '../../assets/img/pin-icon.svg'
import { Link } from 'react-router-dom'
import routes from '../../routes'
// import deleteIcon from '../../assets/img/delete-icon.svg'
import editIcon from '../../assets/img/edit-icon.svg'
import TextareaAutosize from 'react-textarea-autosize'
import axios from 'axios'
import { useFormik } from 'formik'
import { ModalWithButtons } from '../../components'
import createCommentSchema from '../../validationSchemas/createCommentSchema'
import Avatar from 'react-avatar'

export default function Comment({
  name,
  userAvatar,
  text,
  pinned = 0,
  isMyProfile,
  isMyComment,
  userId,
  id,
  handleOpenModal,
  togglePinComment,
  stopWords,
  admin_pinned,
}) {
  const [textIsShorten, setTextIsShorten] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [comment, setComment] = useState(text)
  const [matchedWords, setMatchedWords] = useState([])
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)

  const inputRef = useRef()

  useEffect(() => {
    text.length > commentLimit && setTextIsShorten(true)
  }, [text])

  const formik = useFormik({
    initialValues: {
      commentText: comment,
    },
    validationSchema: createCommentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.commentText !== text && values.commentText.trimStart()) {
        let temp = 0
        stopWords?.forEach((element) => {
          if (
            values.commentText.toLowerCase().match(element.name.toLowerCase())
          ) {
            setMatchedWords((prevState) => [...prevState, element.name])
            temp++
          }
        })
        if (temp > 0 && !warningModalIsOpen) {
          setWarningModalIsOpen(true)
        } else {
          temp === 0 && setComment(values.commentText)
          setIsEditing(false)
          setWarningModalIsOpen(false)
          axios
            .post(`${api_url}/comment/edit`, {
              id,
              text: values.commentText,
              status: temp > 0 ? 3 : 1,
            })
            .then((response) => {})

            .catch((err) => console.log(err.response.data))
        }
      }
    },
  })

  const toggleEditing = () => {
    if (isEditing) {
      formik.resetForm()
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  return (
    <div
      className={`comments__block comments__block__other-user ${
        pinned && 'pinned'
      } ${admin_pinned && 'comments__block--recommended'}`}
    >
      <div>
        <Avatar
          size={32}
          name={name}
          round="50%"
          src={userAvatar}
          maxInitials={2}
          color="#2E8299"
        />
      </div>
      <div className={'comments__block__other-user-wrap'}>
        <div className="comments__top">
          <Link
            to={
              !isMyComment
                ? routes.userProfile + '/' + userId
                : routes.myProfile
            }
            className="comments__fullName-comment text text--bold text--small"
          >
            {name}
          </Link>

          <div>
            {isMyProfile && !admin_pinned && (
              <button
                className="link link--underlined comments__pinButton"
                type="button"
                onClick={() => togglePinComment(id, pinned)}
              >
                {pinned ? 'Unpin' : 'Pin'}
              </button>
            )}

            {isMyComment && !admin_pinned && (
              <button className="comments__edit" onClick={toggleEditing}>
                {isEditing ? 'Cancel' : <img src={editIcon} alt="edit" />}
              </button>
            )}
            {/* {(isMyProfile || isMyComment) && !admin_pinned && ( // ! DELETE BUTTON
              <button
                className='comments__delete'
                onClick={() => handleOpenModal(id)}
              >
                <img src={deleteIcon} alt='delete' />
              </button>
            )} */}
          </div>

          {admin_pinned ? (
            <span
              className="text text--bold text--small"
              style={{ zIndex: '50', color: '#EE4C6E' }}
            >
              Advisor's choice
            </span>
          ) : (
            ''
          )}
        </div>
        {isEditing ? (
          <form className="post__form" onSubmit={formik.handleSubmit}>
            {warningModalIsOpen ? (
              <div
                className="post__form-input"
                style={{
                  height: inputRef.current?.scrollHeight + 'px',
                  whiteSpace: 'break-spaces',
                }}
                dangerouslySetInnerHTML={{
                  __html: formik.values.commentText
                    .split(/(\w+)/g)
                    .map((element) => {
                      let temp = true
                      for (const elem of matchedWords) {
                        temp =
                          elem.trim().toLowerCase() ===
                          element.trim().toLowerCase()

                        if (temp) break
                      }
                      if (temp)
                        return `<span class="post__form-input--warning">${element}</span>`
                      else return element
                    })
                    .join(''),
                }}
              >
                {/* {formik.values.postText} */}
              </div>
            ) : (
              <TextareaAutosize
                ref={inputRef}
                className={`post__form-input ${
                  formik.errors.commentText && 'inputError'
                }`}
                name="commentText"
                value={formik.values.commentText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}

            <button
              type="submit"
              className={`post__form-button ${
                formik.values.commentText.trimStart() &&
                formik.values.commentText !== text &&
                'post__form-button--active'
              }`}
            >
              Edit
            </button>
            <div className="formNotification">{formik.errors.commentText}</div>
          </form>
        ) : (
          <div className={'comments__text-wrap'}>
            <span className="comments__text">
              {textIsShorten
                ? cutText(formik.values.commentText, commentLimit)
                : formik.values.commentText}
            </span>
          </div>
        )}
      </div>

      {textIsShorten && !isEditing && (
        <button
          onClick={() => setTextIsShorten(false)}
          className="link link--underlined comments__text-button"
        >
          Read more
        </button>
      )}
      {pinned && (
        <div className="comments__pinIcon">
          <img src={PinIcon} alt="pin" />
        </div>
      )}
      <ModalWithButtons
        text="Your comment probably contains inappropriate language. Please edit the text, otherwise it won’t be published until Admin reviews it and makes sure the text does not violate Terms of Service."
        textWidth="295"
        modalWidth="378"
        header="Inappropriate language"
        modalIsOpen={warningModalIsOpen}
        onCloseModal={() => setWarningModalIsOpen(false)}
        onConfirm={() => {
          formik.handleSubmit(formik.values)
        }}
        confirmButtonText="Send to moderation"
        cancelButtonText="Edit"
      />
    </div>
  )
}
