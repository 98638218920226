import React, { useState, useEffect, useRef } from 'react'
import '../components/Post/post.scss'
import { useHistory, useRouteMatch } from 'react-router-dom'
import arrow from '../assets/img/arrow-modal.png'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import routes from '../routes'
import ReactQuill from 'react-quill'
import { api_url } from '../config'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import postSelectors from '../redux/post/postSelectors'
import postOperations from '../redux/post/postOperations'
import { EmptyModal } from '../components'
import axios from 'axios'
import ClipLoader from 'react-spinners/ClipLoader'

function FinishStream(saveStreamUrl) {
  const { params } = useRouteMatch()
  const [streamTitleInput, setStreamTitleInput] = useState('')
  const [streamDescriptionInput, setStreamDescriptionInput] = useState('')
  const [error, setError] = useState({
    title: '',
    description: '',
  })
  const [frame, setFrame] = useState(null)
  const [playerLoading, setPlayerLoading] = useState(true)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const history = useHistory()
  const player = useRef()
  const returnToHome = () => {
    document.body.style.backgroundColor = '#FFFFFF'
    history.push('/')
  }
  const handleInputTitle = (e) => {
    setStreamTitleInput(e.target.value.trimStart())
    setError({
      ...error,
      title: e.target.value?.length > 70 ? 'error' : '',
    })
  }

  console.log('streamDescriptionInput', streamDescriptionInput)
  console.log('streamTitleInput', streamTitleInput)
  useEffect(() => {
    const interval = setInterval(() => {
      axios.get(`${api_url}/live/record/exist/${params.id}`).then((res) => {
        setPlayerLoading(false)
        clearInterval(interval)
      })
    }, 8000)
    return () => clearInterval(interval)
  }, [])
  const handleInputDescription = (e) => {
    const text = e
      .replace(/<img .*?>/g, '')
      .replace(/<a\b[^>]*>(.*?)<\/a>/i, '')
      .replace('<p><br></p><p><br></p><p><br></p>', '<p><br></p>')
    setStreamDescriptionInput(e.replace(/<br\s*\/?>/gi, ''))
    setError({
      ...error,
      description: text.length > 1500 ? 'error' : '',
    })
  }
  const handlePublishStream = () => {
    if (
      !streamTitleInput ||
      !streamDescriptionInput ||
      streamDescriptionInput == '<p></p>'
    ) {
      setError({
        title: !streamTitleInput ? 'the field is required' : '',
        description:
          !streamDescriptionInput || streamDescriptionInput == '<p></p>'
            ? 'the field is required'
            : '',
      })
      return
    }

    if (streamTitleInput.length > 70 || streamDescriptionInput.length > 1500) {
      return
    }
    const config = {
      onUploadProgress: function (progressEvent) {
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        )
      },
    }
    setModalIsOpen(true)
    const formdata = new FormData()
    frame && formdata.append('preview_image', frame)
    formdata.append('id', params.id)
    formdata.append('style', '')
    formdata.append('description', streamDescriptionInput || '')
    formdata.append('title', streamTitleInput || '')

    axios
      .post(`${api_url}/live/post`, formdata, config)
      .then((response) => {
        history.push(routes.myProfile)
      })
      .catch((err) => console.log(err.response.data))
      .finally(() => setModalIsOpen(false))
  }

  const handleDeleteStream = () => {
    history.push('/')
    history.go(0)
  }

  const captureVideoFrame = (video, format, quality) => {
    if (typeof video === 'string') {
      video = document.getElementById(video)
    }

    format = format || 'jpeg'
    quality = quality || 0.92

    if (!video || (format !== 'png' && format !== 'jpeg')) {
      return false
    }

    var canvas = document.createElement('CANVAS')

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    canvas.getContext('2d').drawImage(video, 0, 0)

    var dataUri = canvas.toDataURL('image/' + format, quality)
    var data = dataUri.split(',')[1]
    var mimeType = dataUri.split(';')[0].slice(5)
    var bytes = window.atob(data)
    var buf = new ArrayBuffer(bytes.length)
    var arr = new Uint8Array(buf)

    for (var i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i)
    }

    var blob = new Blob([arr], { type: mimeType })
    return { blob: blob, dataUri: dataUri, format: format }
  }
  return (
    <div className="finishWrap">
      <div>
        <button className="stream__returnButton" onClick={returnToHome}>
          <img src={arrow} alt="" />
          <p>Feed</p>
        </button>
        <div className="stream__titleStream">Your stream has finished</div>
      </div>
      <div style={{ marginTop: 40 }} className={'streamTitleInputWrap'}>
        <p className="StreamModal-content-label titleInputPubl">
          Stream recording
        </p>
        {playerLoading ? (
          <ClipLoader color={'#050138'} loading={true} size={70} />
        ) : (
           <ReactPlayer
            playing={false}
            url={saveStreamUrl.saveStreamUrl}
            width="360px"
            id="vid"
            className="reactPlayer"
            height="240px"
            // muted={muted}
            loop={true}
            playsinline
            config={{
              file: {
                attributes: {
                  crossOrigin: 'anonymous',
                },
              },
            }}
            ref={player}
            onReady={() => {
              const frame = captureVideoFrame(
                player.current.getInternalPlayer(),
              )
              setFrame(frame.dataUri)
            }}
            onPause={() => {
              const frame = captureVideoFrame(
                player.current.getInternalPlayer(),
              )
              setFrame(frame.dataUri)
            }}
            controls={true}
          />
        )}
        <p className="StreamModal-content-label titleInputPubl">
          Publish stream
        </p>
        <input
          type="text"
          placeholder="Enter stream title here"
          required
          className={`createPost__inputStream ${
            error.title ? 'createPost__inputError' : ''
          }`}
          value={streamTitleInput}
          onChange={handleInputTitle}
        />

        <p
          className={`StreamModal-content-span ${
            error.title ? 'helperError' : ''
          }`}
        >
          {error.title === 'the field is required'
            ? error.title
            : 'Maximum title length is 70 symbols.'}
        </p>
        <br />
        <p className="StreamModal-content-label descriptionPubl">
          Publication description
        </p>
        <ReactQuill
          theme={'snow'}
          className={`StreamModal-content-textFieldFinish ${
            error.description ? 'createPost__inputError' : ''
          }`}
          style={{ fontSize: '16px' }}
          id="postText"
          placeholder="Enter description here"
          ref={null}
          value={streamDescriptionInput}
          onChange={handleInputDescription}
          preserveWhitespace={true}
        />
        <p
          className={`StreamModal-content-span ${
            error.description.length ? 'helperError' : ''
          }`}
        >
          {error.description === 'the field is required'
            ? error.description
            : 'Maximum description text length is 150 symbols.'}
        </p>
        <div className={'publishBtnWrap'}>
          <button onClick={handlePublishStream} className={'publishStreamBtn'}>
            Publish stream
          </button>
          <button onClick={handleDeleteStream} className={'deleteStreamBtn'}>
            Discard stream
          </button>
        </div>
      </div>
      <EmptyModal modalIsOpen={modalIsOpen}>
        <CircularProgressbar
          className="progress-bar"
          styles={buildStyles({
            textSize: '16px',
            pathColor: `#050138`,

            textColor: '#050138',
          })}
          strokeWidth={5}
          text={uploadProgress + ' %'}
          value={uploadProgress}
        />
      </EmptyModal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    saveStreamUrl: postSelectors.getSaveStream(state),
  }
}
export default connect(mapStateToProps, {
  setSteam: postOperations.setStream,
})(FinishStream)
