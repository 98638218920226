import React from 'react'
import { Comment } from '../../components'

export default function CommentsList({
  comments,
  currentUserId,
  handleOpenModal,
  isMyProfile,
  togglePinComment,
  stopWords,
}) {
  return (
    <>
      {comments.map((comment) => {
        if (comment) {
          var isMyComment = comment.user.id === currentUserId
          var pinned = comment.pinned === 1
        }
        return (
          comment && (
            <Comment
              name={comment.user.userName}
              text={comment.text}
              key={comment.id}
              pinned={pinned}
              userId={comment.user.id}
              userAvatar={comment?.user?.media?.[0]?.url || ''}
              isMyProfile={isMyProfile}
              isMyComment={isMyComment}
              id={comment.id}
              handleOpenModal={handleOpenModal}
              togglePinComment={togglePinComment}
              stopWords={stopWords}
              admin_pinned={comment.admin_pinned}
            />
          )
        )
      })}
    </>
  )
}
