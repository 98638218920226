import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { getMediaFile } from '../../utils/getMediaFile'
import routes from '../../routes'
import playIcon from '../../assets/img/play.svg'
import deleteIcon from '../../assets/img/delete-icon.svg'
import './albums.scss'
import './albumsMobile.scss'

function AlbumsList({ albums, isMyProfile, handleDeleteModalOpen }) {
  const history = useHistory()

  return (
    <>
      {albums.map((album) => {
        const {
          url,
          type,
          isDefaultBackground,
          customClass,
          previewImage,
        } = getMediaFile({
          ...album?.media?.[0],
          style: album?.media?.[0]?.style,
          preview: album?.preview || '',
        })
        return (
          <div
            className={`profile__album ${
              album.status === 3 ? 'inReview' : type
            } ${customClass}`}
            key={album.id}
            onClick={(e) =>
              !e.target.className.includes('delete') &&
              history.push(routes.singlePost + '/' + album.id)
            }
          >
            <img
              src={type === 'image' ? url : previewImage}
              alt="album"
              className={
                album.category.name == 'Livestreams'
                  ? `profile__albumStreams--${type || 'text'}`
                  : `profile__album--${type || 'text'}`
              }
            />
            {(type === 'audio' || type === 'video') && (
              <img src={playIcon} alt="play" className="profile__album-play" />
            )}
            {isMyProfile && (
              <button
                className="profile__album-delete"
                type="button"
                onClick={() => handleDeleteModalOpen(album.id)}
              >
                <img src={deleteIcon} alt="delete" className="delete-icon" />
              </button>
            )}
            {isDefaultBackground && album.status !== 3 && <p>{album?.title}</p>}
          </div>
        )
      })}
    </>
  )
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, null)(AlbumsList)
