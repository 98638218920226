const isLoggedIn = (state) =>
  state.auth.token && state.auth.token.token && state.auth.token.confirmed

const getEmail = (state) => state.auth.user && state.auth.user.email

const getToken = (state) => state.auth.token && state.auth.token.token

const getFullName = (state) => state.auth.user && state.auth.user.fullName

const getUserName = (state) => state.auth.user && state.auth.user.userName

const getAvatar = (state) => state.auth.avatar

const getError = (state) => state.auth.error

const getId = (state) => state.auth.user && state.auth.user.id

const isConfirmed = (state) => state.auth.user && state.auth.user.confirmed

const rememberMe = (state) => state.auth.token && state.auth.token.rememberMe

const getFollowers = (state) => state.auth.followers

const getUser = (state) => state.auth.user

const getRedirectUrl = (state) => state.auth.prevUrl

const getUserStatus = (state) => state.auth.user && state.auth.user.status

const getUserCreatedDate = (state) =>
  state.auth.user && state.auth.user.created_at

const authSelectors = {
  isLoggedIn,
  getEmail,
  getAvatar,
  getFullName,
  getError,
  isConfirmed,
  rememberMe,
  getId,
  getToken,
  getFollowers,
  getUser,
  getRedirectUrl,
  getUserStatus,
  getUserCreatedDate,
  getUserName,
}

export default authSelectors
