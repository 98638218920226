const getCategory = (state) => state.post.category

const getFile = (state) => state.post.file

const getStyle = (state) => state.post.style

const getTitle = (state) => state.post.title

const getBackground = (state) => state?.post?.background

const getStopWords = (state) =>
  state.post.stopWords && state.post.stopWords.words

const getBackgroundList = (state) => state?.post?.backgroundsList || []

const getStream = (state) => state?.post?.stream || {}

const getSaveStream = (state) => state?.post?.saveStream

const postSelectors = {
  getCategory,
  getFile,
  getStyle,
  getStopWords,
  getTitle,
  getBackground,
  getBackgroundList,
  getStream,
  getSaveStream,
}

export default postSelectors
