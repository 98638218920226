import React, { useState, useRef } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import axios from 'axios'
import createCommentSchema from '../../validationSchemas/createCommentSchema'
import ClipLoader from 'react-spinners/ClipLoader'
import TextareaAutosize from 'react-textarea-autosize'

import { api_url } from '../../config'
import authSelectors from '../../redux/auth/authSelectors'
import postSelectors from '../../redux/post/postSelectors'
import { setToken } from '../../getToken'
import { ModalWithButtons } from '../index'

const AddAComment = ({
  postId,
  onSuccess,
  isLoggedIn,
  currentUser,
  stopWords,
}) => {
  const [commentIsPublishing, setCommentIsPublishing] = useState(false)
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  const [matchedWords, setMatchedWords] = useState([])
  const inputRef = useRef()

  const token = setToken()
  const formik = useFormik({
    initialValues: {
      commentText: '',
    },
    validationSchema: createCommentSchema,
    onSubmit: async (values) => {
      const token = await setToken()
      if (values.commentText.trimStart() && !commentIsPublishing) {
        let temp = 0
        stopWords?.forEach((element) => {
          if (
            values.commentText.toLowerCase().match(element.name.toLowerCase())
          ) {
            setMatchedWords((prevState) => [...prevState, element.name])
            temp++
          }
        })
        if (temp > 0 && !warningModalIsOpen) {
          setWarningModalIsOpen(true)
        } else {
          setWarningModalIsOpen(false)
          setCommentIsPublishing(true)
          let status = temp > 0 ? 3 : 1
          const formdata = new FormData()
          formdata.append('album_id', postId)
          formdata.append('to_id', currentUser.id)
          formdata.append('text', values.commentText)
          formdata.append('status', status)
          axios
            .post(`${api_url}/comment/create`, formdata, {
              headers: { Authorization: token },
            })
            .then((res) => {
              setCommentIsPublishing(false)
              formik.resetForm()
              setMatchedWords([])
              onSuccess(res?.data?.comment)
            })
            .catch((err) => console.log(err))
        }
      }
    },
  })

  return (
    <>
      {currentUser.status !== 2 &&
        currentUser.status !== 4 &&
        isLoggedIn &&
        token && (
          <form className="post__form" onSubmit={formik.handleSubmit}>
            {warningModalIsOpen ? (
              <div
                className="post__form-input"
                style={{
                  height: inputRef.current?.scrollHeight + 'px',
                  whiteSpace: 'break-spaces',
                }}
                dangerouslySetInnerHTML={{
                  __html: formik.values.commentText
                    .split(/(\w+)/g)
                    .map((element) => {
                      let temp = true
                      for (const elem of matchedWords) {
                        temp =
                          elem.trim().toLowerCase() ===
                          element.trim().toLowerCase()

                        if (temp) break
                      }
                      return temp
                        ? `<span class="post__form-input--warning">${element}</span>`
                        : element
                    })
                    .join(''),
                }}
              ></div>
            ) : (
              <TextareaAutosize
                ref={inputRef}
                // maxRows={5}
                style={{
                  height: inputRef.current
                    ? inputRef.current.offsetHeight + 'px'
                    : 49 + 'px',
                }}
                name="commentText"
                className={`post__form-input ${
                  formik.errors.commentText && 'inputError'
                }`}
                placeholder="Add a comment..."
                value={formik.values.commentText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}

            <button
              type="submit"
              className={`post__form-button comments__publish ${
                formik.values.commentText.trimStart() &&
                'post__form-button--active'
              }`}
            >
              {commentIsPublishing ? (
                <ClipLoader color={'#FFFFFF'} loading={true} size={15} />
              ) : (
                'Publish'
              )}
            </button>
            <div className="formNotification">{formik.errors.commentText}</div>
          </form>
        )}
      <ModalWithButtons
        text="Your comment probably contains inappropriate language. Please edit the text, otherwise it won’t be published until Admin reviews it and makes sure the text does not violate Terms of Service."
        textWidth="295"
        modalWidth="378"
        header="Inappropriate language"
        modalIsOpen={warningModalIsOpen}
        onCloseModal={() => setWarningModalIsOpen(false)}
        onConfirm={() => {
          formik.handleSubmit(formik.values)
        }}
        confirmButtonText="Send to moderation"
        cancelButtonText="Edit"
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  currentUser: authSelectors.getUser(state),
  stopWords: postSelectors.getStopWords(state),
})

export default connect(mapStateToProps)(AddAComment)
