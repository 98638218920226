import React, { useState, useEffect, useRef } from 'react'
import '../components/Post/post.scss'
import HeaderStream from '../components/StreamSlider/HeaderStream'
import Description from '../components/StreamSlider/Description'
import TitlePost from '../components/StreamSlider/TitlePost'
import ClipLoader from 'react-spinners/ClipLoader'
import { connect } from 'react-redux'
import authSelectors from '../redux/auth/authSelectors'
import postSelectors from '../redux/post/postSelectors'
import { store, view } from '@risingstack/react-easy-state'
import { api_url } from '../config'
import axios from 'axios'
import routes from '../routes'
import { useHistory } from 'react-router-dom'
import postOperations from '../redux/post/postOperations'
import StreamSlider from '../components/StreamSlider'
import arrow from '../assets/img/arrow-modal.png'
import { ModalWithButtons } from '../components'
import { ModalWindow } from '../components'
import Master from '../components/StreamSlider/Master'
import Viewer from '../components/StreamSlider/Viewer'
const state = store({
  // dataChannelByClientId: [],
  peerConnectionByClientId: {},
})
const SinglePost = view(
  ({
    currentUser,
    stopWords,
    isLoggedIn,
    setSteam,
    currentUserAvatar,
    stream,
    setSaveStream,
  }) => {
    const [isMyProfile, setIsMyProfile] = useState(false)
    const [finishModalIsOpen, setFinishModalIsOpen] = useState(false)
    const [endStreamForViewer, setEndStreamForViewer] = useState(false)
    const [fullScreenToggle, setFullScreenToggle] = useState(true)
    const [isPeerClose, setIsPeerClose] = useState(null)
    const [islocalStream, setIslocalStream] = useState(null)
    const [timer, setTimer] = React.useState(0)
    const history = useHistory()
    const node = useRef()
    useEffect(() => {
      document.body.style.backgroundColor = '#444444'
      setFinishModalIsOpen(false)
      setIsMyProfile(false)
      return () => {
        document.body.style.backgroundColor = '#FFFFFF'
      }
    }, [])

    const handleDeleteModalClose = () => setFinishModalIsOpen(false)
    const handleEndStreamForViewer = () => {
      setEndStreamForViewer(false)
      history.push('/')
      history.go(0)
    }
    const finishedStream = () => {
      setFinishModalIsOpen(true)
      setIsMyProfile(true)
    }
    const handleFinishStream = () => {
      setFinishModalIsOpen(false)
      setIsMyProfile(false)
      setSteam({})
      if (isPeerClose && islocalStream) {
        const tracks = islocalStream.getTracks()
        tracks.forEach(function (track) {
          track.stop()
        })
        isPeerClose.close()
        Object.keys(state.peerConnectionByClientId).forEach((clientId) => {
          state.peerConnectionByClientId[clientId].close()
        })
        state.peerConnectionByClientId = []
      }
      axios.delete(`${api_url}/live/end/${stream.id}`).then(() => {
        history.push(routes.stream + `/${stream.id}/finish`)
      })
    }
    const returnToHome = () => {
      if (stream.isMyStream === true) {
        setFinishModalIsOpen(true)
      } else {
        history.push('/')
        history.go(0)
      }
    }
    // ================================================================

    return (
      <>
        {isLoggedIn && (
          <StreamSlider timer={timer} isFinished={finishedStream} />
        )}
        <div className="container home__container stream__container">
          <div
            className={
              !fullScreenToggle
                ? 'wrapFullScreen home__wrapFullScreen stream__wrap'
                : 'wrap home__wrap stream__wrap'
            }
          >
            {stream ? (
              <>
                <button
                  className={
                    !fullScreenToggle
                      ? 'stream__returnButtonFullScreen'
                      : 'stream__returnButton'
                  }
                  onClick={returnToHome}
                >
                  <img src={arrow} alt="" />
                  <p>Feed</p>
                </button>
                <HeaderStream
                  numberOfViewers={state.peerConnectionByClientId}
                  streamId={stream?.id}
                  userId={stream?.user?.id}
                  userFullName={stream?.user?.fullName}
                  userName={stream?.user?.userName}
                  userAvatar={stream.user?.media?.[0]?.url}
                  isMyProfile={isMyProfile}
                  isLoggedIn={isLoggedIn}
                />
                <div
                  className={
                    !fullScreenToggle
                      ? 'post__wrapFullScreen streamContent'
                      : 'post__wrap streamContent'
                  }
                  ref={node}
                >
                  <div style={{ position: 'relative' }}>
                    <div className={'streamMainImg'}>
                      {stream?.isMyStream === true || !stream.channel_name ? (
                        <Master
                          peerConnectionByClientId={
                            state.peerConnectionByClientId
                          }
                          endStream={setSteam}
                          setIsPeerClose={setIsPeerClose}
                          setIslocalStream={setIslocalStream}
                          setTimer={setTimer}
                          timer={timer}
                          channelARNMaster={stream?.channel_name}
                          streamId={stream?.id}
                          setSaveStream={setSaveStream}
                        />
                      ) : (
                        <Viewer
                          dataChannelByClientId={state.dataChannelByClientId}
                          channelARNViewer={stream?.channel_name}
                          idViewer={currentUser.id}
                          fullScreenToggle={fullScreenToggle}
                          setFullScreenToggle={setFullScreenToggle}
                          streamId={stream?.id}
                          setFinishModalIsOpen={setEndStreamForViewer}
                        />
                      )}
                    </div>
                  </div>
                  <TitlePost postId={stream.id} postTitle={stream.title} />
                  <div className="comments post__comments postStream">
                    <Description
                      text={
                        stream?.description
                          ? stream?.description
                          : 'No description'
                      }
                      isMyProfile={isMyProfile}
                      userId={stream?.user?.id}
                    />
                    <hr />
                    {/* <div className="streamComment">
                      {stream?.isMyStream === false && (
                        <AddComment
                          postId={stream?.id}
                          onSuccess={onAddedComment}
                          isLoggedIn={isLoggedIn}
                        />
                      )}
                    </div> */}
                  </div>
                </div>
                {finishModalIsOpen && (
                  <ModalWithButtons
                    modalIsOpen={finishModalIsOpen}
                    onCloseModal={handleDeleteModalClose}
                    header="Finish stream"
                    text="Are you sure you want to finish this stream?"
                    onConfirm={handleFinishStream}
                  />
                )}
                {endStreamForViewer && (
                  <ModalWindow
                    modalIsOpen={endStreamForViewer}
                    onCloseModal={handleEndStreamForViewer}
                    modalWidth={380}
                    textWidth={295}
                    buttonText="Return to homepage"
                    text="The stream is finished"
                  />
                )}
              </>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '50vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '50px',
                }}
              >
                <ClipLoader color={'#050138'} loading={true} size={70} />
              </div>
            )}
          </div>
        </div>
      </>
    )
  },
)

const mapStateToProps = (state) => ({
  currentUser: authSelectors.getUser(state),
  currentUserAvatar: authSelectors.getAvatar(state),
  isLoggedIn: authSelectors.isLoggedIn(state),
  stopWords: postSelectors.getStopWords(state),
  stream: postSelectors.getStream(state),
})

export default connect(mapStateToProps, {
  setSteam: postOperations.setStream,
  setSaveStream: postOperations.saveStream,
})(SinglePost)

// const Room = (props) => {
//     const userVideo = useRef();
//     const userStream = useRef();
//     const partnerVideo = useRef();
//     const peerRef = useRef();
//     const webSocketRef = useRef();
//     const {state} = useLocation();

//     const openCamera = async () => {
//         const allDevices = await navigator.mediaDevices.enumerateDevices();
//         const cameras = allDevices.filter(
//             (device) => device.kind == "videoinput"
//         );
//         console.log(cameras);

//         const constraints = {
//             audio: true,
//             video: {
//                 deviceId: cameras[0].deviceId,
//             },
//         };

//         try {
//             return await navigator.mediaDevices.getUserMedia(constraints);
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     useEffect(() => {
//         state.live_stream_url.replace('http://', '') && openCamera().then((stream) => {
//           console.log('streamstreamstreamstream', stream)
//             userVideo.current.srcObject = stream;
//             userStream.current = stream;

//             console.log(state.live_stream_url.replace('http://', ''))

//             webSocketRef.current = new WebSocket(
//                 `ws://${state.live_stream_url.replace('http://', '')}`
//             );

//             webSocketRef.current.addEventListener("open", () => {
//                 webSocketRef.current.send(JSON.stringify({ join: true }));
//             });

//             webSocketRef.current.addEventListener("message", async (e) => {
//                 const message = JSON.parse(e.data);

//                 if (message.join) {
//                     callUser();
//                 }

// 				if (message.offer) {
//                     handleOffer(message.offer);
//                 }

//                 if (message.answer) {
//                     console.log("Receiving Answer");
//                     peerRef.current.setRemoteDescription(
//                         new RTCSessionDescription(message.answer)
//                     );
//                 }

//                 if (message.iceCandidate) {
//                     console.log("Receiving and Adding ICE Candidate");
//                     try {
//                         await peerRef.current.addIceCandidate(
//                             message.iceCandidate
//                         );
//                     } catch (err) {
//                         console.log("Error Receiving ICE Candidate", err);
//                     }
//                 }
//             });
//         });
//     }, [state]);

//     const handleOffer = async (offer) => {
//         console.log("Received Offer, Creating Answer");
//         peerRef.current = createPeer();

//         await peerRef.current.setRemoteDescription(
//             new RTCSessionDescription(offer)
//         );

//         userStream.current.getTracks().forEach((track) => {
//             peerRef.current.addTrack(track, userStream.current);
//         });

//         const answer = await peerRef.current.createAnswer();
//         await peerRef.current.setLocalDescription(answer);

//         webSocketRef.current.send(
//             JSON.stringify({ answer: peerRef.current.localDescription })
//         );
//     };

//     const callUser = () => {
//         console.log("Calling Other User");
//         peerRef.current = createPeer();

//         userStream.current.getTracks().forEach((track) => {
//             peerRef.current.addTrack(track, userStream.current);
//         });
//     };

//     const createPeer = () => {
//         console.log("Creating Peer Connection");
//         const peer = new RTCPeerConnection({
//             iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
//         });

//         peer.onnegotiationneeded = handleNegotiationNeeded;
//         peer.onicecandidate = handleIceCandidateEvent;
//         peer.ontrack = handleTrackEvent;

//         return peer;
//     };

//     const handleNegotiationNeeded = async () => {
//         console.log("Creating Offer");

//         try {
//             const myOffer = await peerRef.current.createOffer();
//             await peerRef.current.setLocalDescription(myOffer);

//             webSocketRef.current.send(
//                 JSON.stringify({ offer: peerRef.current.localDescription })
//             );
//         } catch (err) {}
//     };

//     const handleIceCandidateEvent = (e) => {
//         console.log("Found Ice Candidate");
//         if (e.candidate) {
//             console.log(e.candidate);
//             webSocketRef.current.send(
//                 JSON.stringify({ iceCandidate: e.candidate })
//             );
//         }
//     };

//     const handleTrackEvent = (e) => {
//         console.log("Received Tracks");
//         partnerVideo.current.srcObject = e.streams[0];
//     };

//     return (
//         <div>
//             <video autoPlay controls={true} ref={userVideo} muted></video>
//             <video autoPlay controls={true} ref={partnerVideo}></video>
//         </div>
//     );
// };

// export default Room;
