import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { api_url } from "../config";
import routes from "../routes";
import { ModalWindow } from "../components";

export default function ConfirmEmail() {
  const [successMessage, setSuccessMesssage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modal, setModal] = useState({
    text: "Checking...",
    header: "Confirmation",
  });

  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    successMessage === "Token does not exist" &&
      setModal({
        text: "Link is invalid or email is already confirmed.",
        header: "Error",
      });
    successMessage === "Success" &&
      setModal({
        text: "Email has been successfully confirmed.",
        header: "Success",
      });
    setModalIsOpen(true);
  }, [successMessage]);

  const handleCloseModal = () => {
    history.push(routes.logIn);
  };

  useEffect(() => {
    if (token) {
      axios
        .get(`${api_url}/auth/register-confirm/${token}`)
        .then((response) => {
          setSuccessMesssage("Success");
        })
        .catch((err) => {
          setSuccessMesssage("Token does not exist");
        });
    }
  }, [token]);

  return (
    <div style={{ textAlign: "center", height: "60vh" }}>
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        text={modal.text}
        header={modal.header}
        modalIsOpen={modalIsOpen}
        onCloseModal={handleCloseModal}
      />
    </div>
  );
}
