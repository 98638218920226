import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'

import { ShareModal } from '../../components'
import { api_url } from '../../config'
import routes from '../../routes'
import checkQuantity from '../../utils/checkQuantity'

import ShareIcon from '../../assets/img/share.svg'
import LikeActiveIcon from '../../assets/img/like-active.svg'
import LikeIcon from '../../assets/img/like.svg'
import searchButton from '../../assets/img/searchButton.svg'
// import { setToken } from '../../getToken'
import '../../components/Post/post.scss'

const TitlePost = ({
  postId,
  postTitle,
  category,
  categoryId,
  isLike,
  likesCount,
  changeLikeCount,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [like, setLike] = useState(false)
  const history = useHistory()
  const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    setLike(isLike)
  }, [isLike])
  const handleLike = async () => {
    // const token = await setToken()
    if (token) {
      if (like) {
        axios
          .delete(`${api_url}/like/remove/${postId}`, {
            headers: { Authorization: token },
          })
          .then(() => {
            changeLikeCount(like ? likesCount - 1 : likesCount + 1)
            setLike(false)
          })
          .catch((err) => console.log(err))
      } else {
        axios
          .post(
            `${api_url}/like/create`,
            { album_id: postId },
            {
              headers: { Authorization: token },
            },
          )
          .then(() => {
            changeLikeCount(like ? likesCount - 1 : likesCount + 1)
            setLike(true)
          })
          .catch((err) => console.log(err.response.data))
      }
    } else {
      history.push(routes.logIn)
    }
  }

  const handleOpenModal = (e) => {
    setModalIsOpen(true)
    e.stopPropagation()
  }

  const handleCloseModal = (e) => {
    if (
      !e.target.tagName === 'circle' ||
      !e.target.tagName === 'path' ||
      (typeof e.target.className === 'string' &&
        !e.target.className.includes('shareModal'))
    ) {
      setModalIsOpen(false)
    }
  }

  return (
    <div className="post__category" style={{ flexDirection: 'column' }}>
      <p className="post__category__title">{postTitle}</p>
      <div className="post__buttons">
        <div className="post__buttons-like">
          <button onClick={handleLike}>
            <img src={like ? LikeActiveIcon : LikeIcon} alt="like" />
          </button>
          <span>{checkQuantity(likesCount, 'like')}</span>
        </div>
        <button className="post__buttons-share" onClick={handleOpenModal}>
          <img src={ShareIcon} alt="share" className="post__buttons-copy" />
          <span className="post__buttons-share-text">Share</span>
        </button>
        <ShareModal
          link={`${window.location.origin}/post/${postId}`}
          onCloseModal={handleCloseModal}
          modalIsOpen={modalIsOpen}
        />
        <div className="post__category-name">
          <Link
            className="post__category-link"
            to={routes.feed + '/' + categoryId}
          >
            {category}
            <img alt="" src={searchButton} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TitlePost
