import React from "react";
import { Link } from "react-router-dom";
import routes from "../../routes";

export default function Description({
  name,
  isMyProfile,
  userId,
  text
}) {
  return (
    <div className={`comments__block`}>
      <div className="comments__top">
        {userId !== 0 ? (
          <Link
            to={
              !isMyProfile
                ? routes.userProfile + "/" + userId
                : routes.myProfile
            }
            className={`comments__fullName text text--bold text--small streamNameSlider`}
          >
            {name}
          </Link>
        ) : (
          <div className={`comments__fullName text text--bold text--small streamNameSlider`}>
            {name}
          </div>
        )}
      </div>
        <div
          className="comments__text commentsTextStream"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
    </div>
  );
}
