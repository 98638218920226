import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './auth.scss'
import './authMobile.scss'
import routes from '../../routes'
import { ModalWindow } from '../../components'
import authOperations from '../../redux/auth/authOperations'
import { connect } from 'react-redux'
import authSelectors from '../../redux/auth/authSelectors'
import { Authenticator, translations } from '@aws-amplify/ui-react'
import { I18n, Amplify, Auth } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_kKxGRcTBt',
    userPoolWebClientId: '7idjmdd2n3uhfep26kcror20h2',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
})
I18n.putVocabularies(translations)
I18n.setLanguage('en')

function LogIn({ onRememberLogin, authError, clearErrors, getUser }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modal, setModal] = useState({
    header: '',
    text: '',
  })

  useEffect(() => {
    if (authError === 'Unauthorized') {
      setModal({ text: 'Invalid credentials.', header: 'Error' })
      setModalIsOpen(true)
    } else if (authError === 'not confirmed') {
      setModal({
        text: 'Check out your email to confirm registration.',
        header: 'Need confirmation',
      })
      setModalIsOpen(true)
    }
  }, [authError])

  const onCloseModal = () => {
    setModalIsOpen(false)
  }

  I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Log in', // Tab header
  })
  const services = {
    async handleSignIn(formData) {
      let { username, password } = formData
      username = username.toLowerCase()

      return Auth.signIn({
        username,
        password,
      }).then((user) => {
        onRememberLogin({
          token: user.signInUserSession.idToken.jwtToken,
          confirmed: true,
        })
        getUser()
        return user
      })
    },
  }
  return (
    <div className="container auth">
      <div className="wrap">
        <h1 className="header header--one auth__header--one">
          Welcome to Testigram
        </h1>
        <p className="subtitle auth__subtitle">
          Join the #1 testimony-sharing community for Christians, share your
          testimonies, see daily praise reports from 117 countries.
        </p>
        <Authenticator
          loginMechanisms={['email']}
          signInAttributes={['email']}
          hideSignUp
          services={services}
        />
        <h2 className="header header--two auth__header--two">
          Don’t have an account yet?
        </h2>
        <Link to={routes.register} className="button button--outlined">
          Create an Account
        </Link>
      </div>
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={onCloseModal}
        header={modal.header}
        text={modal.text}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: authSelectors.getError(state),
  }
}

export default connect(mapStateToProps, {
  clearErrors: authOperations.clearErrors,
  onRememberLogin: authOperations.rememberMeLogIn,
  getUser: authOperations.getCurrentUser,
})(LogIn)
