import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../../config'
import ReactQuill from 'react-quill'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { ModalWithButtons, ModalWindow } from '../../components'
import { connect } from 'react-redux'
import postSelectors from '../../redux/post/postSelectors'
import Modal from 'react-modal'
import authSelectors from '../../redux/auth/authSelectors'
import postOperations from '../../redux/post/postOperations'
import arrow from '../../assets/img/arrow-modal.png'
import cross from '../../assets/img/cross.png'

import './streamSlider.scss'

const ModalsStream = ({
  isOpenModalStream,
  setIsOpenModalStream,
  stopWords,
  stream,
  currentUser,
  setSteam,
}) => {
  const [values, setValues] = useState({ title: '', description: '' })
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  const [descriptionModal, setDescriptionModal] = useState(false)
  const [descriptionError, setDescriptionError] = useState('')
  const [stopWordsList, setStopWordsList] = useState([])
  const [matchedWords, setMatchedWords] = useState([])
  const inputRef = useRef()
  const textfieldRef = useRef()
  const history = useHistory()
  const [error, setError] = useState({
    title: '',
    description: '',
  })

  useEffect(() => {
    setValues({ title: '', description: '' })
    setError({ title: '', description: '' })
  }, [isOpenModalStream])
  // const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    setStopWordsList(stopWords)
  }, [stopWords])

  const returnToFeed = () => {
    setIsOpenModalStream(false)
    history.push('/')
  }

  const onClick = () => {
    let temp = 0
    stopWordsList?.forEach((element) => {
      if (values.description.toLowerCase().match(element.name.toLowerCase())) {
        setMatchedWords((prevState) => [...prevState, element.name])
        temp++
      }
    })
    if (temp > 0 && !warningModalIsOpen) {
      setWarningModalIsOpen(true)
      return
    }

    const description = values.description.replace(/(<(\/?[^>]+)>)/g, '')

    const title = values.title

    if (!title || !description) {
      setError({
        title: !title ? 'the field is required' : '',
        description: !description ? 'the field is required' : '',
      })
      return
    }

    if (title.length > 70 || description.length > 1500) {
      return
    }
    axios
      .post(`${api_url}/live/create`, values)
      .then((response) => {
        console.log(response?.data?.stream)
        setSteam({ ...response?.data?.stream, isMyStream: true })
        history.push(`/stream/${response?.data?.stream?.id}`, {
          ...response?.data?.stream,
          isMyStream: true,
        })
      })
      .catch((err) => {
        setDescriptionModal(true)
        setDescriptionError(err.response.data.errors.description[0])
      })
  }
  const handleDescModal = () => {
    setDescriptionModal(false)
  }

  if (warningModalIsOpen) {
    return (
      <ModalWithButtons
        text="Your description probably contains inappropriate language. Please edit the text."
        textWidth="295"
        modalWidth="378"
        header="Inappropriate language"
        modalIsOpen={warningModalIsOpen}
        // onCloseModal={() => setWarningModalIsOpen(false)}
        cancelButtonText={null}
        onConfirm={() => setWarningModalIsOpen(false)}
        confirmButtonText="Edit"
      />
    )
  }

  return (
    <>
      <Modal isOpen={isOpenModalStream} className={'StreamModal'}>
        <div className="StreamModal-header">
          <div className="StreamModal-header-content">
            <button className="StreamModal-button" onClick={returnToFeed}>
              <img src={arrow} alt="" />
              <p>Feed</p>
            </button>
            <p className="StreamModal-name">Go live</p>
          </div>
          <button onClick={() => setIsOpenModalStream(false)}>
            <img src={cross} alt="" />
          </button>
        </div>
        <div className="StreamModal-content">
          <div style={{ marginTop: 40 }}>
            <p className="StreamModal-content-label">Stream title</p>
            <input
              type="text"
              placeholder="Enter stream title here"
              className={`createPost__input ${
                error.title ? 'createPost__inputError' : ''
              }`} //  ${error && 'inputError'}
              value={values.title}
              onChange={(e) => {
                setValues({ ...values, title: e.target.value })
                setError({
                  ...error,
                  title: e.target.value?.length > 70 ? 'error' : '',
                })
              }}
            />
            <p
              className={`StreamModal-content-span ${
                error.title ? 'helperError' : ''
              }`}
            >
              {error.title === 'error' || !error.title
                ? 'Maximum description text length is 70 symbols.'
                : error.title}
            </p>
          </div>
          <div style={{ marginTop: 25 }}>
            <p className="StreamModal-content-label">Stream description</p>
            {warningModalIsOpen ? (
              <>
                <div
                  className="StreamModal-content-textField"
                  style={{
                    height: inputRef.current?.scrollHeight + 'px',
                    whiteSpace: 'break-spaces',
                    padding: '12px 15px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: values.description
                      .split(/(\w+)/g)
                      .map((element) => {
                        let temp = true
                        for (const elem of matchedWords) {
                          temp =
                            elem.trim().toLowerCase() ===
                            element.trim().toLowerCase()

                          if (temp) break
                        }
                        if (temp)
                          return `<span class="chooseText__input--warning">${element}</span>`
                        else return element
                      })
                      .join(''),
                  }}
                ></div>
              </>
            ) : (
              <ReactQuill
                theme={'snow'}
                className={`StreamModal-content-textField ${
                  error.description ? 'createPost__inputError' : ''
                }`}
                style={{ fontSize: '16px' }}
                id="postText"
                ref={textfieldRef}
                placeholder="Enter stream description here"
                value={values.description}
                onChange={(content) => {
                  const text = content
                    .replace(/<img .*?>/g, '')
                    .replace(/<a\b[^>]*>(.*?)<\/a>/i, '')
                    .replace('<p><br></p><p><br></p><p><br></p>', '<p><br></p>')

                  setValues({
                    ...values,
                    description: text,
                  })

                  setError({
                    ...error,
                    description: text.length > 1500 ? 'error' : '',
                  })
                }}
                preserveWhitespace={true}
              />
            )}
            <p
              className={`StreamModal-content-span ${
                error.description.length ? 'helperError' : ''
              }`}
            >
              {error.description === 'error' || !error.description
                ? 'Maximum description text length is 150 symbols.'
                : error.description}
            </p>
          </div>
          <p className={`StreamModal-content-description`}>
            <span>Note:</span> you can go live only once a say and no longer
            than for a 5 minutes.
          </p>
          <button className={`StreamModal-content-button`} onClick={onClick}>
            Go live
          </button>
        </div>
      </Modal>
      <ModalWindow
        modalIsOpen={descriptionModal}
        onCloseModal={handleDescModal}
        modalWidth={380}
        textWidth={295}
        text={descriptionError}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: authSelectors.getUser(state),
    isLoggedIn: authSelectors.isLoggedIn(state),
    stopWords: postSelectors.getStopWords(state),
    stream: postSelectors.getStream(state),
  }
}

export default connect(mapStateToProps, {
  setSteam: postOperations.setStream,
})(ModalsStream)
