import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { SelectFile, SelectBackground } from '../../components'
// import plusIcon from '../../assets/img/plus.svg'
import { connect } from 'react-redux'
import postSelectors from '../../redux/post/postSelectors'
import routes from '../../routes'
import postOperations from '../../redux/post/postOperations'
import CreatePost from './CreatePost'
import categoryTitleSchema from '../../validationSchemas/categoryTitleSchema'

function ChooseFile({ category, clearState, addTitle, addedTitle }) {
  const history = useHistory()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalBackgroundIsOpen, setModalBackgroundIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [error, setError] = useState(null)

  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.value = ''
    setTitle('')
    setError(null)
    addTitle('')
    // eslint-disable-next-line
  }, [])

  const closeModal = () => {
    setModalIsOpen(false)
    setModalBackgroundIsOpen(false)
  }

  const handleGoNext = () => {
    categoryTitleSchema
      .validate({ title })
      .then(() => {
        addTitle(title)
        clearState()
        history.push(routes.chooseText)
      })
      .catch((err) => {
        inputRef.current.focus()
        setError(err.errors[0])
      })
  }

  const setErrors = () => {
    inputRef.current.focus()
    setError('Title is required')
  }

  const handleChangeTitle = (e) => {
    setError('')
    setTitle(e.target.value)
    categoryTitleSchema.validate({ title: e.target.value }).catch((err) => {
      setError(err.errors[0])
      console.log('err', err.errors)
    })
  }

  useEffect(() => {
    !category && history.push(routes.chooseCategory)
  }, [category, history])

  return (
    <CreatePost onNextButton={handleGoNext}>
      <form className='createPost__form'>
        <h2 className='header--two createPost__header'>Category</h2>
        <input
          readOnly
          disabled
          type='text'
          className='createPost__input'
          defaultValue={category && category.name}
          style={{ marginBottom: '20px' }}
        />
        <h2 className='header--two createPost__header'>Title</h2>
        <input
          ref={inputRef}
          type='text'
          placeholder='Give new testimony a title...'
          required
          className={`createPost__input ${error && 'inputError'}`}
          value={title}
          onChange={(e) => handleChangeTitle(e)}
        />
        {error && (
          <p
            className='formNotification formNotification__error'
            style={{ marginTop: '10px' }}
          >
            {error}
          </p>
        )}
      </form>
      <div className='createPost__filebox filebox'>
        <button
          className='filebox__button'
          type='button'
          onClick={() => (title ? setModalIsOpen(true) : setErrors())}
        >
          Upload media
        </button>
        <button
          className='filebox__button filebox__button-selectBackground'
          type='button'
          onClick={() => (title ? setModalBackgroundIsOpen(true) : setErrors())}
        >
          Select background
        </button>
      </div>
      <SelectFile
        isOpen={modalIsOpen}
        onClose={closeModal}
        addTitle={() => addTitle(title)}
      />
      <SelectBackground
        title={title}
        isOpen={modalBackgroundIsOpen}
        onClose={closeModal}
        addTitle={() => addTitle(title)}
      />
    </CreatePost>
  )
}

const mapStateToProps = (state) => {
  return {
    category: postSelectors.getCategory(state),
    addedTitle: postSelectors.getTitle(state),
  }
}

export default connect(mapStateToProps, {
  clearState: postOperations.clearState,
  addTitle: postOperations.addTitle,
})(ChooseFile)
