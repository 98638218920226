import React from 'react'
import Ripples from 'react-ripples'

export default function Button({
  className,
  text,
  contained = true,
  type = 'button',
  onClick,
  formId,
  disabled = false,
}) {
  return (
    <Ripples
      color={contained ? 'rgba(255, 255, 255, 0.1)' : 'rgba(98, 0, 238, 0.1)'}
    >
      <button
        type={type}
        className={className}
        onClick={onClick}
        form={formId}
        disabled={disabled}
      >
        {text}
      </button>
    </Ripples>
  )
}
