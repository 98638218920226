import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDropzone } from "react-dropzone";
import "./selectFile.scss";
import "./selectFileMobile.scss";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { connect } from "react-redux";
import postOperations from "../../redux/post/postOperations";
import closeIcon from "../../assets/img/close-modal.svg";
import { max_file_size } from "../../config";
import { useState } from "react";
import { isBrowser } from "react-device-detect";

// let customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     paddingLeft: "0",
//     paddingRight: "0",
//     paddingBottom: "56px",
//     paddingTop: "67px",
//     width: "895px",
//     border: "none",
//     borderRadius: "8px",
//     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
//     overflow: "hidden",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
// };

function nameLengthValidator(file) {
  if (file.size > max_file_size) {
    return {
      code: "file-to-big",
      message: `File cannot be larger than ${max_file_size / 1000 / 1000} Mb`,
    };
  } else if (
    file.type.includes("webm") ||
    file.name.includes(".jfif") ||
    file.name.includes(".svg")
  ) {
    return { code: "unavailible type", message: "Invalid file type" };
  }

  return null;
}

function SelectFile({ isOpen, onClose, addFile, addTitle }) {
  const history = useHistory();
  const [error, setError] = useState(null);

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      maxFiles: 1,
      validator: nameLengthValidator,
      noClick: true,
      accept: "image/*,  video/*,  audio/*",
    });

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      addFile(file);
    });
  }, [acceptedFiles, addFile]);

  useEffect(() => {
    const err = fileRejections.map((file) => {
      return file.errors[0].message;
    });
    setError(err[0]);
  }, [fileRejections]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      addTitle();
      if (acceptedFiles[0].type.includes("image")) {
        history.push(routes.chooseFilter);
        // addFile(acceptedFiles[0].preview);
      } else {
        history.push(routes.chooseText);
      }
    }
    // eslint-disable-next-line
  }, [acceptedFiles, history, addFile]);

  return (
    <Modal
      isOpen={isOpen}
      // style={customStyles}
      className="selectFileWidth"
      onRequestClose={onClose}
      bodyOpenClassName="selectFile__bodyOpen"
    >
      <button onClick={onClose} className="modal__close">
        <img src={closeIcon} alt="close" />
      </button>
      <h1 className="header--one header selectFile__header">
        Add an image/video/audio
      </h1>
      <p className="header--three selectFile__text">
        Select the file you want to upload and press “Next” to continue.
      </p>
      <form
        encType="multipart/form-data"
        {...getRootProps({
          className: "dropzone filebox selectFile__dropzone",
        })}
      >
        <input {...getInputProps()} />
        <button
          type="button"
          onClick={open}
          className="button button--primary selectFile__button"
        >
          Browse
        </button>
        {isBrowser && (
          <p className="header--four text--bold">or drag a file here</p>
        )}
        {error && (
          <p className="text" style={{ marginTop: "10px" }}>
            {error}
          </p>
        )}
      </form>
    </Modal>
  );
}

export default connect(null, { addFile: postOperations.addFile })(SelectFile);
