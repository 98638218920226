import React from "react";
import "./additional.scss";
import "./additionalMobile.scss";

export default function Faq() {
  return (
    <div className="container">
      <div className="wrap">
        <h1 className="header header--one additional__title">FAQ</h1>
        <h2 className="header header--two additional__subtitle">Question 1</h2>
        <p className="text text--normal additional__text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
          expedita est molestias similique tempore debitis amet ex explicabo
          voluptate distinctio suscipit, eos eius mollitia, laudantium soluta
          exercitationem optio excepturi labore.
        </p>
        <p className="text text--normal additional__text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
          expedita est molestias similique tempore debitis amet ex explicabo
          voluptate distinctio suscipit, eos eius mollitia, laudantium soluta
          exercitationem optio excepturi labore.
        </p>
        <p className="text text--normal additional__text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
          expedita est molestias similique tempore debitis amet ex explicabo
          voluptate distinctio suscipit, eos eius mollitia, laudantium soluta
          exercitationem optio excepturi labore.
        </p>
        <h2 className="header header--two additional__subtitle">Question 2</h2>
        <p className="text text--normal additional__text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
          expedita est molestias similique tempore debitis amet ex explicabo
          voluptate distinctio suscipit, eos eius mollitia, laudantium soluta
          exercitationem optio excepturi labore.
        </p>
        <p className="text text--normal additional__text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
          expedita est molestias similique tempore debitis amet ex explicabo
          voluptate distinctio suscipit, eos eius mollitia, laudantium soluta
          exercitationem optio excepturi labore.
        </p>
        <p className="text text--normal additional__text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
          expedita est molestias similique tempore debitis amet ex explicabo
          voluptate distinctio suscipit, eos eius mollitia, laudantium soluta
          exercitationem optio excepturi labore.
        </p>
      </div>
    </div>
  );
}
