import React, { useState, useEffect } from 'react'
import './profile.scss'
import { useParams, useHistory } from 'react-router'
import axios from 'axios'
import { api_url } from '../../config'
import Profile from './Profile'
import routes from '../../routes'
import authSelectors from '../../redux/auth/authSelectors'
import { connect } from 'react-redux'
import { setToken } from '../../getToken'

function UserProfile({ currentUser, currentUserAvatar }) {
  const [albums, setAlbums] = useState(null)
  const [user, setUser] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [postsPage, setPostsPage] = useState(1)
  const [albumsTotal, setAlbumsTotal] = useState(0)

  const { id } = useParams()

  useEffect(() => {
    setAlbums([])
    setUser(null)
    setAvatar(null)
    setAlbumsTotal(0)
    setPostsPage(1)
    setLoading(false)
  }, [id])

  useEffect(() => {
    if (parseInt(currentUser.id) === parseInt(id)) {
      history.push(routes.myProfile)
    }
  }, [currentUser, history, id])

  useEffect(() => {
    const getUserProfileEffect = async () => {
      const token = await setToken()
      axios
        .get(`${api_url}/profile/get?id=${id}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setUser(response.data.user)
        })
        .catch((err) => console.log(err.response))

      axios
        .get(`${api_url}/profile/avatar/${id}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setAvatar(response.data)
        })
        .catch((err) => {
          setAvatar('no avatar')
        })
    }
    getUserProfileEffect()
  }, [id])

  useEffect(() => {
    const getUserIdEffect = async () => {
      setLoading(true)
      const token = await setToken()
      if (token) {
        axios
          .get(`${api_url}/feed/albums?userId=${id}&page=${postsPage}`, {
            headers: { Authorization: token },
          })
          .then((response) => {
            if (albums) {
              setAlbums((prevState) => [
                ...prevState,
                ...response.data.albums.data,
              ])
            } else {
              setAlbums(response.data.albums.data)
              setAlbumsTotal(response.data.albums.total)
            }
            setLoading(false)
            setNextPageUrl(response.data.albums.next_page_url)
          })
          .catch((err) => console.log('error', err))
      }
    }
    getUserIdEffect()
    // eslint-disable-next-line
  }, [postsPage, id])

  // const handleCloseModal = () => {
  //   setModalIsOpen(false);
  // };

  // const handleOpenModal = () => {
  //   setModalIsOpen(true);
  // };
  const loadMorePosts = () => {
    if (nextPageUrl && !loading && albums.length < albumsTotal) {
      setPostsPage((prevState) => prevState + 1)
    }
  }

  return (
    <Profile
      user={user}
      isMyProfile={false}
      albums={albums}
      avatar={avatar}
      loadMorePosts={loadMorePosts}
      loading={loading}
      nextPageUrl={nextPageUrl}
      currentUser={currentUser}
      currentUserAvatar={currentUserAvatar}
      albumsTotal={albumsTotal}
      userId={id}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: authSelectors.getUser(state),
    currentUserAvatar: authSelectors.getAvatar(state),
  }
}

export default connect(mapStateToProps)(UserProfile)
