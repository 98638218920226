import React from "react";
import Modal from "react-modal";
import "./Modal.scss";
import "./ModalMobile.scss";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import Button from "../Button";
import forgotPasswordSchema from "../../validationSchemas/forgotPasswordSchema";

Modal.setAppElement("#root");

export default function SignUpModal({ modalIsOpen, onCloseModal }) {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      history.push({
        pathname: routes.register,
        state: { email: formik.values.email },
      });
      onCloseModal();
    },
  });

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onCloseModal}
      className="changePasswordModalWidth"
      // style={customStyles}
    >
      <div className="signUpModal">
        <h3 className="header header--two signUpModal__header">Join now!</h3>
        <p className="signUpModal__text text text--normal">
          Join now for FREE to enjoy even more testimonies (plus receive "prayer
          bullets" to help you share your own testimonies too)
        </p>
        <form onSubmit={formik.handleSubmit}>
          <div className="formInput-group signUpModal__inputGroup">
            <input
              type="text"
              className={`formInput signUpModal__input ${
                formik.errors.email && "inputError"
              }`}
              placeholder="Your email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              value={formik.values.email}
            />
            <p className="formNotification signUpModal__formNotification">
              {formik.errors.email
                ? formik.errors.email
                : "Enter your valid email adress here."}
            </p>
          </div>
          <Button
            text="Join"
            type="submit"
            className="button button--primary signUpModal__button"
          />
        </form>

        <button
          onClick={onCloseModal}
          className="signUpModal__cancel text text--normal"
        >
          Skip
        </button>
      </div>
    </Modal>
  );
}
