import { createAction } from '@reduxjs/toolkit'

const addFile = createAction('post/addFile')
const addText = createAction('post/addText')
const addCategory = createAction('post/addCategory')
const addTitle = createAction('post/addTitle')
const addStyle = createAction('post/addStyle')
const addDefaultBackground = createAction('post/addDefaultBackground')
const clearState = createAction('post/clearState')
const getStopWords = createAction('post/getStopWords')
const clearTitle = createAction('post/clearTitle')
const clearBackground = createAction('post/clearTitle')
const backgroundsList = createAction('post/backgroundsList')
const stream = createAction('post/stream')
const saveStream = createAction('post/saveStream,')

const postActions = {
  addFile,
  clearTitle,
  addText,
  addCategory,
  addStyle,
  addDefaultBackground,
  clearBackground,
  clearState,
  getStopWords,
  addTitle,
  backgroundsList,
  stream,
  saveStream,
}

export default postActions
