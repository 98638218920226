import React, { useEffect, useState, useRef } from 'react'
import ReactPlayer from 'react-player'
import mutedIcon from '../../assets/img/muted-icon.svg'
import unmutedIcon from '../../assets/img/unmuted-icon.svg'

export default function Video({ isVisible, file, recommended, categoryName }) {
  useEffect(() => {}, [isVisible])
  const [muted, setMuted] = useState(true)
  const handleClick = () => {
    muted ? setMuted(false) : setMuted(true)
  }

  const player = useRef()

  return (
    <div
      className={
        categoryName == 'Livestreams'
          ? `previewStreams previewStreams__video ${
              recommended && 'previewStreams--recommended'
            }`
          : `preview preview__video ${recommended && 'preview--recommended'}`
      }
      onClick={handleClick}
    >
      <ReactPlayer
        playing={isVisible}
        url={file}
        muted={muted}
        loop={true}
        playsinline
        ref={player}
      />

      <img
        alt="speaker"
        className="preview__video-icon"
        src={muted ? mutedIcon : unmutedIcon}
      />
    </div>
  )
}
