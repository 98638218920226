import React, { useState, useEffect, useRef } from 'react'
import { api_url, commentLimit } from '../../config'
import cutText from '../../utils/cutText'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import editIcon from '../../assets/img/edit-icon.svg'
import axios from 'axios'
import chooseTextSchema from '../../validationSchemas/chooseTextSchema'
import { useFormik } from 'formik'
import { ModalWithButtons } from '../../components'
import ReactQuill from 'react-quill'
import Parser from 'html-react-parser'
import getTextFromNode from '../../utils/getTextFromNode'

export default function Description({
  name,
  text,
  isMyProfile,
  userId,
  categoryId,
  postId,
  stopWords,
  status,
}) {
  const [description, setDescription] = useState(text)
  const [textIsShorten, setTextIsShorten] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [matchedWords, setMatchedWords] = useState([])
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  // const [pureText, setPureText] = useState("");
  // const [commentInput, setCommentInput] = useState(text);
  const inputRef = useRef()

  const formik = useFormik({
    initialValues: {
      postText: description,
    },
    enableReinitialize: true,
    validationSchema: chooseTextSchema,
    onSubmit: (values) => {
      if (values.postText !== text && values.postText.trimStart()) {
        let temp = 0
        stopWords?.forEach((element) => {
          if (values.postText.toLowerCase().match(element.name.toLowerCase())) {
            setMatchedWords((prevState) => [...prevState, element.name])
            temp++
          }
        })
        if (temp > 0 && !warningModalIsOpen) {
          setWarningModalIsOpen(true)
        } else {
          setDescription(values.postText.replace(/<p>​​<br><\/p>/gi, ''))
          setIsEditing(false)
          setWarningModalIsOpen(false)
          axios
            .post(`${api_url}/feed/edit-album`, {
              id: postId,
              categoryId: categoryId,
              description: values.postText.replace(/<p>​<br><\/p>/gi, ''),
              status: temp > 0 ? 3 : 1,
            })
            .then((response) => {
              setIsEditing(false)
              console.log(response.data)
            })
            .catch((err) => console.log(err))
        }
      }
    },
  })

  useEffect(() => {
    if (formik.values.postText) {
      let node = Parser(formik.values.postText)
      let pureText =
        typeof node === 'object' && node.length
          ? node.map((el) => getTextFromNode(el)).join(' ')
          : formik.values.postText
      if (pureText.length > commentLimit) {
        setTextIsShorten(true)
      }
    }
  }, [formik.values.postText])

  const toggleEditing = () => {
    if (isEditing) {
      formik.resetForm()
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  return (
    <div className={`comments__block`}>
      <div className="comments__top">
        {userId !== 0 ? (
          <Link
            to={
              !isMyProfile
                ? routes.userProfile + '/' + userId
                : routes.myProfile
            }
            className={`comments__fullName text text--bold text--small`}
          >
            {name}
          </Link>
        ) : (
          <div className={`comments__fullName text text--bold text--small`}>
            {name}
          </div>
        )}
        {isMyProfile && status !== 3 && (
          <div>
            <button className="comments__edit" onClick={toggleEditing}>
              {isEditing ? 'Cancel' : <img src={editIcon} alt="edit" />}
            </button>
          </div>
        )}
      </div>
      {isEditing ? (
        <form className="post__form" onSubmit={formik.handleSubmit}>
          {warningModalIsOpen ? (
            <div
              className="post__form-input"
              style={{
                height: inputRef.current?.scrollHeight + 'px',
                whiteSpace: 'break-spaces',
              }}
              dangerouslySetInnerHTML={{
                __html: formik.values.postText
                  .split(/(\w+)/g)
                  .map((element) => {
                    let temp = true
                    for (const elem of matchedWords) {
                      temp =
                        elem.trim().toLowerCase() ===
                        element.trim().toLowerCase()

                      if (temp) break
                    }
                    if (temp)
                      return `<span class="post__form-input--warning">${element}</span>`
                    else return element
                  })
                  .join(''),
              }}
            ></div>
          ) : (
            <ReactQuill
              ref={inputRef}
              theme={'snow'}
              className="post__editor"
              value={formik.values.postText}
              onChange={(content, delta, source, editor) => {
                formik.setFieldValue(
                  'postText',
                  content
                    .replace(/<img .*?>/g, '')
                    .replace(/<a\b[^>]*>(.*?)<\/a>/i, '')
                    .replace(
                      '<p><br></p><p><br></p><p><br></p>',
                      '<p><br></p>',
                    ),
                  true,
                )
              }}
            />
          )}

          <button
            type="submit"
            className={`post__form-button ${
              formik.values.postText !== text &&
              formik.values.postText.trim() &&
              'post__form-button--active'
            }`}
          >
            Edit
          </button>
          <div className="formNotification">{formik.errors.postText}</div>
        </form>
      ) : (
        // <div
        //   className="comments__text"
        //   dangerouslySetInnerHTML={{
        //     __html: DOMPurify.sanitize(
        //       textIsShorten
        //         ? cutText(formik.values.postText, commentLimit)
        //         : formik.values.postText
        //     ),
        //   }}
        // ></div>
        <div
          className="comments__text"
          dangerouslySetInnerHTML={{
            __html: textIsShorten
              ? cutText(formik.values.postText, commentLimit)
              : formik.values.postText,
          }}
        >
          {/* a */}
        </div>
      )}

      {textIsShorten && !isEditing && (
        <button
          onClick={() => setTextIsShorten(false)}
          className="link link--underlined comments__text-button"
        >
          Read more
        </button>
      )}
      <ModalWithButtons
        text="Your comment probably contains inappropriate language. Please edit the text, otherwise it won’t be published until Admin reviews it and makes sure the text does not violate Terms of Service."
        textWidth="295"
        modalWidth="378"
        header="Inappropriate language"
        modalIsOpen={warningModalIsOpen}
        onCloseModal={() => setWarningModalIsOpen(false)}
        onConfirm={() => {
          formik.handleSubmit(formik.values)
        }}
        confirmButtonText="Send to moderation"
        cancelButtonText="Edit"
      />
    </div>
  )
}
