import React, { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import { AlbumsList, Button, FollowersModal } from '../../components'
import editIcon from '../../assets/img/editProfile.svg'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import './profile.scss'
import './profileMobile.scss'
import axios from 'axios'
import { api_url } from '../../config'
import useInfiniteScroll from '../../utils/useInfiniteScroll'
import ClipLoader from 'react-spinners/ClipLoader'
import warnIcon from '../../assets/img/warn-icon.svg'
import muteIcon from '../../assets/img/mute-icon.svg'
import blockIcon from '../../assets/img/block-icon.svg'
import postProfileActive from '../../assets/img/postProfile.svg'
import pinnedProfileActive from '../../assets/img/pinnedProfile.svg'
import postProfile from '../../assets/img/postProfileActive.svg'
import pinnedProfile from '../../assets/img/pinnedProfileActive.svg'
import { setToken } from '../../getToken'

export default function Profile({
  user,
  currentUser,
  currentUserAvatar,
  avatar,
  albums,
  albumsTotal,
  isMyProfile,
  goToCreatePost,
  handleDeleteModalOpen,
  loadMorePosts,
  nextPageUrl,
  loading,
  userId,
  handleOpenWarningModal,
  activeButton,
  setActiveButton,
}) {
  const [followingModalIsOpen, setFollowingModalIsOpen] = useState(false)
  const [followersModalIsOpen, setFollowersModalIsOpen] = useState(false)
  const [followers, setFollowers] = useState([])
  const [following, setFollowing] = useState([])
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [followersPage, setFollowersPage] = useState(1)
  const [followingPage, setFollowingPage] = useState(1)
  const [followersTotal, setFollowersTotal] = useState(0)
  const [followingTotal, setFollowingTotal] = useState(0)
  const [nextFollowersUrl, setNextFollowersUrl] = useState(null)
  const [nextFollowingUrl, setNextFollowingUrl] = useState(null)
  const [loadingFollows, setLoadingFollows] = useState(false)

  useEffect(() => {
    setFollowersModalIsOpen(false)
    setFollowingModalIsOpen(false)
    setFollowing([])
    setFollowers([])
    setFollowingPage(1)
    setFollowersPage(1)
    setIsSubscribed(false)
    setNextFollowersUrl(null)
    setNextFollowingUrl(null)
    setFollowersTotal(0)
    setFollowingTotal(0)
  }, [user])

  // load more posts when see footer
  const [lastElementRef] = useInfiniteScroll(
    nextPageUrl ? loadMorePosts : () => {},
    loading,
  )
  // following & followers
  const handleCloseFollowingModal = () => {
    setFollowingModalIsOpen(false)
  }

  const handleCloseFollowersModal = () => {
    setFollowersModalIsOpen(false)
  }

  const handleFollow = async (id) => {
    setIsSubscribed(true)
    const token = await setToken()
    axios
      .post(`${api_url}/subscription/create?id=${id}`, {
        headers: { Authorization: token },
      })
      .then(() => getFollowers(user.id, followersPage))
      .catch((err) => console.log(err))
  }

  const handleUnfollow = async (id) => {
    setIsSubscribed(false)
    const token = await setToken()
    axios
      .delete(`${api_url}/subscription/remove/${id}`, {
        headers: { Authorization: token },
      })
      .then(() => getFollowers(user.id, followersPage))
      .catch((err) => console.log(err))
  }

  const loadMoreFollowers = () => {
    if (
      nextFollowersUrl &&
      !loadingFollows &&
      followers.length < followersTotal
    ) {
      setFollowersPage((prevState) => prevState + 1)
    }
  }

  const loadMoreFollowings = () => {
    if (
      nextFollowingUrl &&
      !loadingFollows &&
      following.length < followingTotal
    ) {
      setFollowingPage((prevState) => prevState + 1)
    }
  }

  const getFollowers = async (id, page) => {
    const token = await setToken()
    axios
      .get(`${api_url}/profile/followers/${id}?page=${page}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        if (followers.length > 0) {
          setFollowers((prevState) => [
            ...prevState,
            ...response.data.followers.data,
          ])
        } else {
          setFollowers(response.data.followers.data)
        }
        setLoadingFollows(false)
        setFollowersTotal(response.data.followers.total)
        setNextFollowersUrl(response.data.followers.next_page_url)
      })
      .catch((err) => console.log(err.response.data))
  }

  const getFollowing = async (id, page) => {
    const token = await setToken()
    axios
      .get(`${api_url}/profile/following/${id}?page=${page}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        if (following.length > 0) {
          setFollowing((prevState) => [
            ...prevState,
            ...response.data.followers.data,
          ])
        } else {
          setFollowing(response.data.followers.data)
        }
        setLoadingFollows(false)
        setFollowingTotal(response.data.followers.total)
        setNextFollowingUrl(response.data.followers.next_page_url)
      })
      .catch((err) => console.log(err))
  }

  // load followers
  useEffect(() => {
    setLoadingFollows(true)
    if (user) {
      setIsSubscribed(user.is_subscribed)
      getFollowers(user.id, followersPage)
      getFollowing(user.id, followingPage)
    }
    // eslint-disable-next-line
  }, [user, userId, followingPage, followersPage])

  const handleOpenFollowers = () => {
    if (followers && followers.length > 0) {
      setFollowersModalIsOpen(true)
    }
  }

  const handleOpenFollowing = () => {
    if (following && following.length > 0) {
      setFollowingModalIsOpen(true)
    }
  }

  const handleRemoveFollowing = async (id) => {
    const token = await setToken()
    axios
      .delete(`${api_url}/subscription/remove/${id}`, {
        headers: { Authorization: token },
      })
      .then(() => {
        setFollowing((prevState) =>
          prevState.filter((elem) => elem.user.id !== id),
        )
        setFollowingTotal(followingTotal - 1)
      })
      .catch((err) => console.log(err))
  }

  const handleRemoveFollower = async (id) => {
    const token = await setToken()
    axios
      .delete(`${api_url}/subscription/remove-follower/${id}`, {
        headers: { Authorization: token },
      })
      .then(() => {
        setFollowers((prevState) =>
          prevState.filter((elem) => elem.follower.id !== id),
        )
        setFollowersTotal(followersTotal - 1)
      })
      .catch((err) => console.log(err))
  }

  const getContent = () => {
    if (albums?.length) {
      return (
        <>
          {isMyProfile && activeButton === 'albums' && user.status !== 4 && (
            <button className="profile__defaultPost" onClick={goToCreatePost}>
              Create a new testimony
            </button>
          )}
          <AlbumsList
            albums={albums}
            isMyProfile={activeButton === 'albums' ? isMyProfile : false}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </>
      )
    } else if (!loading) {
      return activeButton === 'albums'
        ? isMyProfile && user.status !== 2 && user.status !== 4 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <Button
                text="Create a testimony"
                onClick={goToCreatePost}
                className="button button--primary"
              />
            </div>
          )
        : activeButton === 'pinned'
        ? 'No posts'
        : null
    }
  }

  return (
    <div className="profile">
      <div className="profile__container">
        <div className="profile__wrap">
          {user && albums ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
                className="mobileDirectionColumn"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '120px',
                  }}
                >
                  <Avatar
                    // size={150}
                    className="profileAvatar"
                    name={user.fullName}
                    round="50%"
                    maxInitials={2}
                    color="#050138"
                    src={avatar}
                  />
                </div>
                <div className="profile__user">
                  {user.status !== 1 && (
                    <button
                      className="profile__user-status"
                      onClick={handleOpenWarningModal}
                    >
                      {user.status === 2 && <img src={blockIcon} alt="block" />}
                      {user.status === 3 && <img src={warnIcon} alt="warn" />}
                      {user.status === 4 && <img src={muteIcon} alt="mute" />}
                    </button>
                  )}
                  <div className="profile__user-name">
                    <h3 className="header header--two">{user.userName}</h3>
                    {isMyProfile ? (
                      <Link
                        to={routes.editProfile}
                        className="link link--small profile__editButton"
                      >
                        <img src={editIcon} alt="edit" />
                        <span>Edit profile</span>
                      </Link>
                    ) : isSubscribed ? (
                      <Button
                        text="Unfollow"
                        className="button button--outlined"
                        contained={false}
                        onClick={() => handleUnfollow(user.id)}
                      />
                    ) : (
                      user.status !== 2 &&
                      user.status !== 4 && (
                        <Button
                          text="Follow"
                          className="button button--outlined"
                          contained={false}
                          onClick={() => handleFollow(user.id)}
                        />
                      )
                    )}
                  </div>
                  <div className="profile__user-info">
                    <span className="text--bold">
                      {albumsTotal} Testimonies
                    </span>
                    <button
                      className="profile__user-info-button"
                      onClick={handleOpenFollowers}
                    >
                      <span className="text--bold">
                        {followersTotal} Followers
                      </span>
                    </button>
                    <button
                      className="profile__user-info-button"
                      onClick={handleOpenFollowing}
                    >
                      <span className="text--bold">
                        {followingTotal} Following
                      </span>
                    </button>
                    {/* <h5 className="text text--normal text--bold profile__user-fullName">
                      {user.fullName}
                    </h5> */}
                    <p className="text text--normal profile__user-bio">
                      {user.bio}
                    </p>
                  </div>
                </div>
              </div>
              <div className="profile__buttonBlock">
                {/* <h5 className='text--normal text--bold'>My posts</h5>
                <h5 className='text--normal text--bold'>Pinned</h5> */}
                <button
                  onClick={() => setActiveButton('albums')}
                  className={`profile__buttonBlock__button ${
                    activeButton === 'albums'
                      ? 'profile__buttonBlock__button-active'
                      : ''
                  }`}
                  style={!isMyProfile ? { width: '100%' } : {}}
                >
                  <img
                    alt=""
                    src={
                      activeButton === 'albums'
                        ? postProfileActive
                        : postProfile
                    }
                  />
                  {isMyProfile ? 'My posts' : 'Posts'}
                </button>
                <button
                  onClick={() => setActiveButton('pinned')}
                  className={`profile__buttonBlock__button ${
                    activeButton === 'pinned'
                      ? 'profile__buttonBlock__button-active'
                      : ''
                  }`}
                  style={{ display: isMyProfile ? 'block' : 'none' }}
                >
                  <img
                    alt=""
                    src={
                      activeButton === 'pinned'
                        ? pinnedProfileActive
                        : pinnedProfile
                    }
                  />
                  Pinned
                </button>
              </div>
              <div className="profile__posts">{getContent()}</div>
              <ClipLoader color={'#050138'} loading={loading} size={75} />
              <span
                className="profile__lastElement"
                ref={lastElementRef}
              ></span>
            </>
          ) : (
            <div style={{ margin: 'auto' }}>
              <ClipLoader color={'#050138'} loading={true} size={75} />
            </div>
          )}
        </div>
      </div>
      <FollowersModal
        followers={following}
        header={'Following'}
        modalIsOpen={followingModalIsOpen}
        onCloseModal={handleCloseFollowingModal}
        isMyProfile={isMyProfile}
        removeText={'Unfollow'}
        buttonEvent={handleRemoveFollowing}
        loadingFollows={loadingFollows}
        nextPageUrl={nextFollowingUrl}
        loadMoreFollows={loadMoreFollowings}
      />
      <FollowersModal
        followers={followers}
        header={'Followers'}
        modalIsOpen={followersModalIsOpen}
        onCloseModal={handleCloseFollowersModal}
        isMyProfile={isMyProfile}
        removeText={'Remove'}
        buttonEvent={handleRemoveFollower}
        loadingFollows={loadingFollows}
        nextPageUrl={nextFollowersUrl}
        loadMoreFollows={loadMoreFollowers}
      />
    </div>
  )
}
