import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import postSelectors from "../../redux/post/postSelectors";
import routes from "../../routes";
import { useHistory } from "react-router-dom";
import postOperations from "../../redux/post/postOperations";
import CreatePost from "./CreatePost";

function ChooseFilter({ file, category, addStyle, title }) {
  const history = useHistory();

  const [activeStyle, setActiveStyle] = useState("normal");

  const handleActiveStyle = (e) => {
    setActiveStyle(e.target.dataset.style);
  };

  useEffect(() => {
    setActiveStyle("normal");
  }, []);

  const handleGoNext = () => {
    addStyle(activeStyle);
    history.push(routes.chooseText);
  };

  useEffect(() => {
    !file && history.push(routes.chooseCategory);
  }, [file, history]);
  return (
    <CreatePost onNextButton={handleGoNext}>
      {category && (
        <>
          <form className="createPost__form">
            <h2 className="header--two createPost__header">Category</h2>
            <input
              disabled
              type="text"
              className="createPost__input"
              value={category.name}
              style={{ marginBottom: "20px" }}
            />
            <h2 className="header--two createPost__header">Title</h2>
            <input
              disabled
              type="text"
              className="createPost__input"
              value={title}
              pa
            />
          </form>
          <p className="header--three chooseFilter__subtitle">
            Select a filter to apply or skip the step and proceed to the
            desciption.
          </p>
          <div className={`chooseFilter__preview ${activeStyle}`}>
            <img
              src={file && file.preview}
              alt="chosen"
              className="chooseFilter__image"
            />
          </div>
          <h3 className="header header--three">Filter</h3>
          <div className="filters chooseFilter__filters">
            <button
              className={`filters__thumbnail normal ${
                activeStyle === "normal" && "filters__thumbnail--active"
              }`}
              data-style="normal"
              onClick={handleActiveStyle}
            >
              <img src={file && file.preview} alt="thumbnail" />
            </button>
            <button
              className={`filters__thumbnail coolBlue ${
                activeStyle === "coolBlue" && "filters__thumbnail--active"
              }`}
              data-style="coolBlue"
              onClick={handleActiveStyle}
            >
              <img src={file && file.preview} alt="thumbnail" />
            </button>
            <button
              className={`filters__thumbnail coolWarm ${
                activeStyle === "coolWarm" && "filters__thumbnail--active"
              }`}
              data-style="coolWarm"
              onClick={handleActiveStyle}
            >
              <img src={file && file.preview} alt="thumbnail" />
            </button>
          </div>
        </>
      )}
    </CreatePost>
  );
}

const mapStateToProps = (state) => {
  return {
    file: postSelectors.getFile(state),
    category: postSelectors.getCategory(state),
    title: postSelectors.getTitle(state),
  };
};

export default connect(mapStateToProps, { addStyle: postOperations.addStyle })(
  ChooseFilter
);
